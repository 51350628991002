import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, NavButton, Screen, Text } from '../../../components';

const { defaultStates } = require('../../../utils/defaults');

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
  }

  goBack = () => {
    this.props.history.push('/');
  };

  body() {
    return (
      <>
        <Row align="center">
          <Col>
            <Text titleHeadApp text={'DESCULPE, ESTA PÁGINA NÃO ESTÁ DISPONÍVEL.'} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Text text={'A página que você tentou acessar está indisponível ou não existe.'} />
          </Col>
        </Row>
        <Row align="center">
          <Col lg={6} md={12} className={'d-flex justify-content-center justify-content-lg-end'}>
            <Button text={'retornar à página anterior'} onClick={this.goBack} />
          </Col>
          <Col lg={6} md={12} className={'d-flex justify-content-center justify-content-lg-start'}>
            <NavButton sec text={'ir para a página inicial'} to={'/'} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

Error.propTypes = {
  history: PropTypes.object,
};

export default Error;
