import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WhiteLine, BrownLine } from './styles';
import { Col, Row } from 'react-bootstrap';

export class Line extends Component {
  render() {
    // types
    const { brown } = this.props;

    if (brown) {
      return (
        <Row>
          <Col>
            <BrownLine>
              <hr />
            </BrownLine>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          <WhiteLine>
            <hr />
          </WhiteLine>
        </Col>
      </Row>
    );
  }
}

Line.propTypes = {
  brown: PropTypes.bool,
};

export default Line;
