import styled from 'styled-components';
import { bootstrapMin } from '../../../utils/devices';

export const Clause = styled.h5`
  font-size: 26px;
  line-height: 36px;
  text-align: left;

  @media ${bootstrapMin.xs} {
    font-size: 20px;
    text-transform: uppercase;
  }
`;

export const Paragraph = styled.h6`
  font-size: 26px;
  text-align: left;
  text-transform: uppercase;

  @media ${bootstrapMin.xs} {
    font-size: 20px;
    text-transform: uppercase;
  }
`;

export const SubParagraph = styled.p`
  font-size: 20px;
  line-height: 30px;
  text-align: left;

  @media ${bootstrapMin.xs} {
    font-size: 14px;

    &:not(:first-of-type) {
      margin-top: 45px;
    }
  }
`;
