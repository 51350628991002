import styled from 'styled-components';
import { Col as BCol } from 'react-bootstrap';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 35px 0;
  margin: 60px 0 0;
  justify-content: center;
  background-color: ${colors.white};

  > div {
    width: 100%;
    max-width: 1160px;
    border-top: 2px solid ${colors.primaryThree};
    padding: 0 0 45px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
      padding: 4px 0;
    }
  }

  @media ${bootstrapMax.lg} {
    padding: 0 35px 0;
    margin: 20px 0 0;

    > div {
      padding: 0 0 35px;
    }
  }
`;

export const Col = styled(BCol)`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.nav`
  margin: 54px 0 26px;

  div {
    width: 220px;
    height: 55px;
    background-image: url(${({ img }) => img});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media ${bootstrapMax.lg} {
    margin: 37px 0 5px;

    div {
      width: 190px;
      height: 47px;
    }
  }
`;
