import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

// Hamburger CSS
export const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > div {
    width: 40px;
    height: 27px;
    background-image: url(${({ img }) => img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media ${bootstrapMax.lg} {
    > div {
      width: 28px;
      height: 19px;
    }
  }
`;

// Navbar CSS
export const Nav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 106px;
  padding: 33px 35px;
  box-shadow: 0px 3px 20px #3f4b6614;
  position: fixed;
  z-index: 50;
  background-color: ${colors.white};
  @media ${bootstrapMax.lg} {
    box-shadow: unset;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 1160px;
  z-index: 50;
  background-color: ${colors.white};

  a {
    text-decoration: none;
  }
`;

export const Logo = styled.a`
  flex: 1;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: start;

  div {
    width: 160px;
    height: 40px;
    background-image: url(${({ img }) => img});
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  @media ${bootstrapMax.lg} {
    width: 129px;
    height: 32px;
    justify-content: center;

    div {
      width: 129px;
      height: 32px;
      margin: 0;
    }
  }
`;

// Links CSS
export const Ul = styled.div`
  flex-flow: column nowrap;
  background-color: ${colors.primaryOne}F0;
  position: fixed;
  transform: ${({ open }) => (open ? 'translateX(0)' : `translateX(100%)`)};
  top: 0;
  right: 0;
  height: 100%;
  width: 418px;
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
  justify-content: normal;
  font-size: 16px;
  list-style-type: none;
  padding: 0 9px 0 0;

  a {
    text-transform: none;
    text-decoration: none;
  }

  div {
    &:hover {
      color: ${colors.secondaryThree};
    }
  }

  @media ${bootstrapMax.lg} {
    transform: ${({ open }) => (open ? 'translateX(0)' : `translateX(-100%)`)};
    left: 0;
    width: 307px;
    font-size: 10px;
    padding: 0 3px 0 0;
  }
`;

export const ScrollList = styled.div`
  overflow-y: auto;
  height: inherit;
  padding-bottom: 100px;
`;

export const LogoMenu = styled.img`
  width: 189px;

  @media ${bootstrapMax.lg} {
    width: 161px;
  }
`;

const MenuDefault = styled.div`
  width: 100%;
  padding: 35px 45px;
  color: ${colors.white};

  @media ${bootstrapMax.lg} {
    padding: 25px 35px;
  }
`;

export const MenuHeader = styled(MenuDefault)`
  margin: 10px 0 0;
`;

export const MenuItem = styled(MenuDefault)`
  border-bottom: 1px solid ${colors.secondaryOne};
  align-items: center;
  justify-content: flex-start;
`;

export const Icon = styled.div`
  min-width: 20px;
  max-width: 21px;
  width: 100%;
  height: 29px;
  margin: 0 16px 0 0;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;

  @media ${bootstrapMax.lg} {
    max-width: 33px;
    margin: 0 19px 0 0;
  }
`;

export const Close = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;

  @media ${bootstrapMax.lg} {
    width: 15px;
    height: 15px;
  }
`;

export const MenuFooter = styled(MenuDefault)`
  flex-direction: column;
  padding: 19px 35px;

  > a:last-child {
    margin: 15px 0 0;
  }
`;
