import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  DownloadButton,
  SearchButton,
  DownloadButtonApp,
  SearchIcon,
  SearchAdmin,
  SearchIconAdmin,
  Simple,
  BackText,
  LinkButton,
  BackButton,
} from './styles';

import close_blue from './../../assets/icons/close_blue-min.png';
import search_icon from './../../assets/icons/search_white@2x-min.png';
import download_white from './../../assets/icons/download_white@2x-min.png';
import arrow_left from './../../assets/icons/np_back_30451_000000@2x.png';

class Button extends Component {
  render() {
    // props
    const { text, onClick, to } = this.props;

    //types
    const { back, backApp, close, search, downloadApp, searchAdmin, danger } = this.props;

    if (back)
      return (
        <LinkButton onClick={onClick}>
          <img width={23} src={arrow_left} />
          <BackText>Voltar</BackText>
        </LinkButton>
      );

    if (backApp)
      return (
        <BackButton to={to} onClick={onClick}>
          <img width={23} src={arrow_left} />
        </BackButton>
      );

    if (close)
      return (
        <CloseButton onClick={onClick}>
          <div>
            <img src={close_blue} />
          </div>
        </CloseButton>
      );

    if (downloadApp)
      return (
        <DownloadButton onClick={onClick}>
          <DownloadButtonApp src={download_white} />
        </DownloadButton>
      );

    if (search)
      return (
        <SearchButton onClick={onClick}>
          <SearchIcon src={search_icon} />
        </SearchButton>
      );

    if (searchAdmin)
      return (
        <SearchAdmin onClick={onClick}>
          <SearchIconAdmin src={search_icon} />
          {text}
        </SearchAdmin>
      );

    return (
      <Simple danger={danger} onClick={onClick}>
        {text}
      </Simple>
    );
  }
}

Button.propTypes = {
  // props
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,

  // types
  back: PropTypes.bool,
  backApp: PropTypes.bool,
  close: PropTypes.bool,
  search: PropTypes.bool,
  downloadApp: PropTypes.bool,
  searchAdmin: PropTypes.bool,
  danger: PropTypes.bool,
};

export default Button;
