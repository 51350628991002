const { axiosDefault, handleErrors } = require('../utils/defaults');

const versionService = {
  get: async (os, version) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/version?os=${os}&version=${version}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default versionService;
