import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, Table } from '../../../components';
import { Row, Col } from 'react-bootstrap';

import partnersService from '../../../services/partners';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class PartnersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnersList: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.fillTable = this.fillTable.bind(this);
  }

  async componentDidMount() {
    await this.fillTable();
  }

  async fillTable() {
    this.setState({ loadingState: true });

    const response = await partnersService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data.map((i) => {
        i.partner = i.partner ? i.partner.name : '';
        return i;
      });

      this.setState({ partnersList: data });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const onCellClick = ({ id }) => this.props.history.push(`/admin/parceiros/${id}`);

    const columns = [
      { headerName: 'ID', field: 'id', hide: true, flex: 0.05 },
      { headerName: 'Nome', field: 'name', flex: 0.5 },
      { headerName: 'Data de criação', field: 'createdAt', flex: 0.5 },
    ];

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Parceiros'} />
          </Col>
        </Row>
        <Row align="right">
          <Col>
            <Button
              underlinePlus
              text={'adicionar'}
              onClick={() => this.props.history.push('/admin/parceiros/novo')}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3">
            <Table onCellClick={onCellClick} data={this.state.partnersList} columns={columns} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        body={this.body}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

PartnersList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default PartnersList;
