import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text } from '../../../components';
import { Clause } from './styles';

const { defaultStates } = require('../../../utils/defaults');

class TermsUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
  }

  body() {
    return (
      <>
        <Row align="center" className={'mt-5'}>
          <Col>
            <Text heading1 title={{ one: 'termos', two: 'de uso' }} />
          </Col>
        </Row>

        <Row align="center" className={'mt-sm-5 mt-4'}>
          <Col className={'d-flex justify-content-end'}>
            <Clause>Data de atualização: 31/03/2022</Clause>
          </Col>
        </Row>

        <Row align="center" className={'mt-sm-5 mt-4'}>
          <Col>{<Clause>Termos de Uso e Política de Privacidade</Clause>}</Col>
        </Row>
        <Row align="center" className={'mt-sm-3 mt-2'}>
          <Col sm={12}>
            <Text
              paragraph2
              text={
                'A página e o aplicativo <b><i>Plataforma Circular Cotton Move</i></b> pertencem e integram a plataforma de ' +
                'gestão circular de cadeia de suprimentos têxteis, e são operados por <b>COTTON MOVE SERVIÇOS, PESQUISA E DESENVOLVIMENTO EIRELI</b>, ' +
                'sociedade empresária inscrita no CNPJ sob nº 08.775.782/0001-42, doravante denominada COTTON MOVE.'
              }
            />
            <Text
              paragraph2
              text={
                '<b><i>Plataforma Circular Cotton Move</i></b> tem a finalidade de divulgar informações sobre produtos com ' +
                'atributos circulares e iniciativas relacionadas à economia circular, incluindo pontos para descarte ' +
                'de roupas usadas feitas de algodão, as quais serão reintroduzidas no mercado, transformadas em ' +
                'novos produtos ou receberão destinação ambientalmente adequada dentro da legislação brasileira.'
              }
            />
            <Text
              paragraph2
              text={
                'Ao acessar e ao visitar as páginas de <b><i>Plataforma Circular Cotton Move</i></b> você declara concordância às ' +
                'regras estabelecidas aqui no presente Termo de Uso e Política de Privacidade. É importante que você de ' +
                'tempos em tempos confira se houve alteração em suas condições.'
              }
            />
            <Text
              paragraph2
              text={
                'COTTON MOVE reserva-se o direito de modificar ou atualizar as condições do Termo de Uso e Política ' +
                'de Privacidade sem aviso prévio e se você visitar ou utilizar os serviços será compreendido que concorda ' +
                'com as novas condições.'
              }
            />
            <Text
              paragraph2
              text={
                'É importante que você leia, compreenda e concorde antes de prosseguir na navegação, devendo ' +
                'encaminhar qualquer dúvida para  <a href="mailto:comunicação@cottonmove.com.br" target="_blank">comunicação@cottonmove.com.br</a>.'
              }
            />
            <Text
              paragraph2
              text={
                '<b><i>Plataforma Circular Cotton Move</i></b> possui dois serviços: a divulgação dos pontos de ' +
                'descarte de roupas usadas feitas de algodão - cadastrados na plataforma de gestão circular de cadeia ' +
                'de suprimentos têxteis - e o blog com informação e iniciativas relacionadas à economia circular.'
              }
            />
            <Text
              paragraph2
              text={
                'Os pontos de coleta de roupas usadas de algodão são apresentados em um mapa, utilizando o API ' +
                '(interface de programação de aplicações) do Google Maps. O recurso de localização, que coleta onde ' +
                'você está e informa ao Google, só é ativado se você der o seu consentimento através do navegador/app ' +
                '<b><i>Plataforma Circular Cotton Move</i></b>. Mesmo com a ativação, sua localização não será coletada ou ' +
                'armazenada. Sem o seu consentimento o recurso do mapa continuará funcionando, porém sem identificar ' +
                'onde você está e, por consequência, sem indicar os pontos de descarte de roupas usadas de algodão ' +
                'mais próximos.'
              }
            />
            <Text
              paragraph2
              text={
                'O blog possui conteúdo próprio da COTTON MOVE, assim como compartilha e reproduz notícias sobre ' +
                'produtos com atributos circulares e iniciativas dos parceiros comerciais, que integram a plataforma de ' +
                'gestão circular de cadeia de suprimentos têxteis.'
              }
            />
            <Text
              paragraph2
              text={
                'As páginas de <b><i>Plataforma Circular Cotton Move</i></b> podem monitorar as suas ações dentro do site através da utilização de Pixel e TAG, ferramentas gerenciadas por Meta e por Google respectivamente, e que não podem ser desativadas.'
              }
            />
            <Text
              paragraph2
              text={
                'A Política de Privacidade em <b><i>Plataforma Circular Cotton Move</i></b> é somente coletar dados que são ' +
                'essenciais para as funções que você escolher: geolocalização para identificar os pontos de descarte de ' +
                'roupas usadas de algodão próximos a você, o e-mail que você nos fornecer para receber a newsletter do ' +
                'blog e eventualmente como você navega dentro de <b><i>Plataforma Circular Cotton Move</i></b>, o que permitirá ' +
                'melhorar a sua experiência de navegação.'
              }
            />
            <Text
              paragraph2
              text={
                'Não compartilharemos suas informações com nossos parceiros comerciais e desconsidere qualquer e-' +
                'mail de COTTON MOVE que você venha a receber. Em caso de dúvida, crítica ou sugestão, entre em contato ' +
                'com o e-mail <a href="mailto:comunicação@cottonmove.com.br" target="_blank">comunicação@cottonmove.com.br</a>.'
              }
            />
            <Text
              paragraph2
              text={
                'Eventualmente nas páginas de <b><i>Plataforma Circular Cotton Move</i></b> pode haver divulgações publicitárias ' +
                'de seus parceiros comerciais integrantes da plataforma de gestão circular de cadeia de suprimentos ' +
                'têxteis, se você clicar em um link ou botão que lhe direcione para fora do ambiente de <b><i>Plataforma ' +
                'Circular Cotton Move</i></b>, observe as regras e a política de privacidade deste site que você será ' +
                'direcionado(a). COTTON MOVE não possui qualquer gestão sobre as páginas de seus parceiros comerciais.'
              }
            />
            <Text
              paragraph2
              text={
                'Todos os conteúdos divulgados em <b><i>Plataforma Circular Cotton Move</i></b> são previamente verificados, ' +
                'porém COTTON MOVE não se responsabiliza pela veracidade das informações prestadas por terceiros ' +
                'tanto em relação aos endereços dos pontos de descarte de roupas usadas de algodão quanto às notícias ' +
                'e mensagens promocionais de parceiros comerciais, reservando-se o direito de editar e apagar qualquer ' +
                'postagem feita no blog sem que para isso precise apresentar esclarecimento.'
              }
            />
            <Text
              paragraph2
              text={
                'COTTON MOVE também reserva-se o direito de, sem aviso prévio, alterar ou ampliar os serviços, retirar ' +
                'do ar quaisquer recursos dos serviços que são oferecidos, bem como criar limites e preços para os ' +
                'serviços, podendo rescindir ou suspender, permanentemente ou temporariamente, o acesso aos serviços ' +
                'sem aviso prévio ou qualquer responsabilidade.'
              }
            />
            <Text
              paragraph2
              text={
                'Você pode compartilhar, distribuir, copiar, reproduzir, transmitir, exibir publicamente, realizar publicamente, ' +
                'publicar, adaptar, editar ou criar trabalhos derivados das informações que estão em <b><i>Plataforma Circular ' +
                'Cotton Move</i></b> desde que faça gratuitamente e cite <a href="https://www.plataformacircular.app/" target="_blank">plataformacircular.app</a> como fonte. Esta autorização ' +
                'não deve ser interpretada como licença ou cessão de direitos de propriedade intelectual nem ser motivo ' +
                'para exigir responsabilidade de COTTON MOVE por erros e imprecisões de conteúdo.'
              }
            />
            <Text
              paragraph2
              text={
                'Todas as informações e serviços disponibilizados em <b><i>Plataforma Circular Cotton Move</i></b> são de ' +
                'propriedade exclusiva de COTTON MOVE e você não pode comercializar sem prévia autorização.'
              }
            />
            <Text
              paragraph2
              text={
                'Qualquer demanda, perda, responsabilidade, reclamação ou despesa, incluindo honorários advocatícios, ' +
                'que você der causa em decorrência do uso indevido das informações de <b><i>Plataforma Circular Cotton ' +
                'Move</i></b>, deverão ser indenizadas por você.'
              }
            />
            <Text
              paragraph2
              text={
                'Na máxima extensão permitida pela lei brasileira, em nenhuma circunstância COTTON MOVE será ' +
                'responsável por quaisquer danos indiretos, punitivos, incidentais, especiais, consequenciais ou ' +
                'exemplares, incluindo, sem limitação, danos por lucros cessantes, boa vontade, uso de dados ou outras ' +
                'perdas intangíveis, decorrentes ou relacionadas ao uso ou incapacidade de usar plenamente os serviços.'
              }
            />
            <Text
              paragraph2
              text={
                'O suporte a clientes e a empresas interessadas na divulgação em <b><i>Plataforma Circular Cotton Move</i></b> é ' +
                'realizado através do e-mail comunicação@cottonmove.com.br.'
              }
            />
            <Text
              paragraph2
              text={
                'Aplica-se a este Termo de Uso e Política de Privacidade a lei brasileira e a cidade de São Paulo como ' +
                'jurisdição.'
              }
            />
          </Col>
        </Row>

        {/*<Row align="center" className={'mb-2 mt-3 mt-sm-0'}>*/}
        {/*  <Col>*/}
        {/*    <Paragraph>*/}
        {/*      <b>1.</b> */}
        {/*    </Paragraph>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*<Row align="center" className={'mb-2'}>*/}
        {/*  <Col>*/}
        {/*    <SubParagraph>*/}
        {/*      <b>1.1.</b> .*/}
        {/*    </SubParagraph>*/}
        {/*    <SubParagraph>*/}
        {/*      <b>1.2.</b> */}
        {/*    </SubParagraph>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

export default TermsUse;
