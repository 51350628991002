import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const TextCard = styled.p`
  line-height: 20px;
  color: ${colors.white};
  font-size: 16px;
  ${({ bold }) => bold && 'font-weight: bold;'};
`;

export const LinkCard = styled.a`
  line-height: 20px;
  color: ${colors.white};
  font-size: 14px;
  transition: all 0.2s ease-in-out 0.1s;

  &:hover {
    color: ${colors.primaryTwo};
    text-decoration: none;
  }
`;
