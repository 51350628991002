import styled from 'styled-components';
import { bootstrapMax } from '../../utils/devices';
import { colors } from '../../utils/colors';
import arrow from '../../assets/icons/arrow_right_white-min@2x.png';

export const CardContainer = styled.div`
  position: relative;
  height: 460px;
  padding: 250px 18px 38px;
  border-radius: 24px;
  background-color: ${colors.primaryOne};
  margin: 0 0 ${({ less }) => (less ? '0 !important;' : '80px')};
  width: 302px;

  &:nth-of-type(3n + 1) {
    margin: 0 5.5% 80px 0;
  }

  &:nth-of-type(3n + 2) {
    margin: 0 5.4% 80px;
  }

  &:nth-of-type(3n + 3) {
    margin: 0 0 80px 5.5%;
  }

  @media ${bootstrapMax.xl} {
    &:nth-of-type(3n + 1) {
      margin-right: 0.6%;
    }

    &:nth-of-type(3n + 2) {
      margin-right: 0.6%;
      margin-left: 0.6%;
    }

    &:nth-of-type(3n + 3) {
      margin-left: 0.6%;
    }
  }

  @media ${bootstrapMax.lg} {
    &:nth-of-type(3n + 1) {
      margin-right: unset;
    }

    &:nth-of-type(3n + 2) {
      margin-right: unset;
      margin-left: unset;
    }

    &:nth-of-type(3n + 3) {
      margin-left: unset;
    }
  }

  @media ${bootstrapMax.md} {
    &:nth-of-type(2n + 1) {
      margin-right: unset;
    }
  }

  @media ${bootstrapMax.sm} {
    margin: 0 0 ${({ less }) => (less ? 0 : 50)}px !important;
  }
`;

export const Img = styled.img`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;
  `
      : ''}

  ${({ stayIn }) =>
    stayIn
      ? `
        max-width: inherit;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
    `
      : `
      height: 60px;
      max-width: 100%;
    `}
`;

export const ImgCard = styled.div`
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  max-height: 240px;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const Icon = styled.div`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  max-height: 22px;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  width: 202px;
  height: 41px;
  border: 1px solid ${colors.secondaryTwo};
  color: ${colors.secondaryTwo};
  border-radius: 24px;
  position: absolute;
  bottom: 38px;
  left: 50px;
  transition: all 0.2s ease-in-out 0.1s;

  @media (max-width: 374px) {
    left: 25px;
    width: 80%;
  }

  &:hover {
    color: ${colors.primaryTwo};
    border: 1px solid ${colors.primaryTwo};
  }
`;

export const Text = styled.span`
  color: ${colors.secondaryTwo};
  font-size: ${({ desc }) => (desc ? 14 : 20)}px;
  line-height: ${({ desc }) => (desc ? 20 : 30)}px;
  min-height: ${({ desc }) => (desc ? 'unset' : '60px')};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px !important;
`;
