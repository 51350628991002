import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CarouselComponent, NavButton, Screen, Text, Card } from '../../../components';
import { typePlatform } from '../../../enums';
import newsService from '../../../services/news';
import partnersService from '../../../services/partners';
import homeService from '../../../services/home';
// import { FirebaseAnalytics } from '../../../utils/firebase';

import { StyledRow, ImgBig, Img, GrayCircle, InsideText, MiddleColumnDiv } from './styles';
import arrows from '../../../assets/icons/home/arrows@2x.png';
import bag from '../../../assets/icons/home/bag@2x.png';
import truck from '../../../assets/icons/home/truck@2x.png';
import cotton from '../../../assets/icons/home/cotton@2x.png';
import hand from '../../../assets/icons/home/hand@2x.png';
import industry from '../../../assets/icons/home/industry@2x.png';
import mobile from '../../../assets/icons/home/mobile@2x.png';
import PropTypes from 'prop-types';
const { defaultStates, defaultModalOnHide, baseURL } = require('../../../utils/defaults');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      partners: [],
      news: [],
      ourImpacts: [],
      innerWidth: window.innerWidth,
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.openModal = this.openModal.bind(this);
    this.renderOurImpacts = this.renderOurImpacts.bind(this);
  }

  async updateSize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  async componentDidMount() {
    // await FirebaseAnalytics.setScreenName({
    //   screenName: 'Home',
    //   nameOverride: 'HomeScreen',
    // });
    await Promise.all([await this.getPartners(), await this.getNews(), await this.getOurImpacts()]);
  }

  async getNews() {
    const responseNews = await newsService.user.getList({ limit: 3 });
    if (responseNews.ok && responseNews.data) {
      this.setState({
        news: responseNews.data,
      });
    }
  }

  async getPartners() {
    const response = await partnersService.user.getList();
    if (response.ok && response.data) {
      this.setState({
        partners: response.data,
      });
    }
  }

  async getOurImpacts() {
    const responseOurImpacts = await homeService.user.list();
    if (responseOurImpacts.ok && responseOurImpacts.data) {
      this.setState({
        ourImpacts: responseOurImpacts.data,
      });
    } else {
      const modalState = {
        title: 'Oops!',
        text: responseOurImpacts.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  renderOurImpacts() {
    const { ourImpacts } = this.state;
    let index = 0;
    let rows = [];

    const renderImpact = () => {
      const cols = [];
      for (let j = 0; j < 2; j++, index++) {
        if (ourImpacts[index]) {
          const { value, text } = ourImpacts[index];
          cols.push(
            <Col md={{ span: 4, offset: j + 1 }} align={'left'}>
              <Text heading1 home title={{ one: value }} />
              <Text home text={text} />
            </Col>,
          );
        }
      }
      return cols;
    };

    for (; index < ourImpacts.length; ) {
      rows.push(<Row key={index}>{renderImpact()}</Row>);
    }
    return rows;
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  openModal(type, image, imageSize) {
    const modalState = {
      home: true,
      title: typePlatform[type].title,
      text: typePlatform[type].description,
      toggle: true,
      image: image,
      imageSize: imageSize,
      onHide: this.modalOnHide,
    };
    this.setState({ modalState });
  }

  body() {
    const { news, partners } = this.state;
    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 650, itemsToShow: 3, itemsToScroll: 2 },
    ];
    const stayInBreakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 600, itemsToShow: 2, itemsToScroll: 2 },
      { width: 910, itemsToShow: 3, itemsToScroll: 2 },
    ];

    const newsItems = news.map((n) => (
      <Card
        key={n.id}
        id={n.id}
        {...this.props}
        title={n.titleComplete}
        description={n.describe}
        image={n.file && n.file.id ? `${baseURL()}/api/file/${n.file.id}/${n.file.slug}` : ''}
        less
      />
    ));

    return (
      <>
        <Row align="center" className={'align-items-center mt-5 mt-md-0'}>
          <Col md={12} lg={{ span: 4, offset: 1 }}>
            <Text heading1 title={{ one: 'plataforma', two: 'circular' }} />
          </Col>
          <Col md={12} lg={7} className={'pt-4 no-gutters'}>
            <Row className={'justify-content-center'} style={{ minWidth: 315, maxWidth: 400 }}>
              <Col className={'d-flex flex-column'} style={{ justifyContent: 'space-evenly' }}>
                <div>
                  <Img
                    src={industry}
                    position={'right'}
                    onClick={() => this.openModal('industry', industry)}
                  />
                  <Text labelHome text={typePlatform['industry'].title} />
                </div>
                <div>
                  <Img
                    src={cotton}
                    position={'right'}
                    onClick={() => this.openModal('productor', cotton)}
                  />{' '}
                  <Text labelHome text={typePlatform['productor'].title} />
                </div>
              </Col>
              <Col className={'d-flex flex-column align-items-center p-0 px-lg-2'}>
                <div>
                  <Img
                    src={bag}
                    position={'center'}
                    onClick={() => this.openModal('store', bag, 50)}
                    minor
                  />
                  <Text labelHome text={typePlatform['store'].title} />
                </div>
                <MiddleColumnDiv>
                  <GrayCircle>
                    <InsideText className={'m-0 bold'}>Descarte Aqui</InsideText>
                  </GrayCircle>
                  <ImgBig img={arrows} onClick={() => this.props.history.push('/descarte-aqui')}>
                    <div />
                  </ImgBig>
                </MiddleColumnDiv>
                <div>
                  <Img
                    src={truck}
                    position={'center'}
                    onClick={() => this.openModal('manufacture', truck)}
                  />
                  <Text labelHome text={typePlatform['manufacture'].title} />
                </div>
              </Col>
              <Col className={'d-flex flex-column'} style={{ justifyContent: 'space-evenly' }}>
                <div>
                  <Img
                    src={mobile}
                    position={'left'}
                    onClick={() => this.openModal('user', mobile, 50)}
                    minor
                  />
                  <Text labelHome text={typePlatform['user'].title} />
                </div>
                <div>
                  <Img src={hand} position={'left'} onClick={() => this.openModal('point', hand)} />
                  <Text labelHome text={typePlatform['point'].title} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={'mt-3 mt-lg-5 py-5'}>
          <Col>
            <div style={{ position: 'relative' }}>
              <StyledRow />
              <div
                className={
                  'px-0 px-lg-5 d-flex justify-content-center align-items-center position-relative'
                }>
                <div style={{ maxWidth: 964 }} className={'py-5 my-2 w-100'}>
                  <Text
                    paragraph4
                    text={
                      'Na Plataforma Circular Cotton Move seu descarte permite criar novos produtos, ' +
                      'com impacto social e ambiental positivos.'
                    }
                  />
                  <Text titleWhite text={'FAÇA PARTE!'} />
                  <Text
                    paragraph4
                    text={
                      'Descarte sua roupa de algodão em fim de ciclo de uso em um dos ' +
                      'pontos de nossos parceiros.'
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col align={'center'} className={'mb-5 mt-lg-5'}>
            <Text heading3 title={{ one: 'Nosso', two: 'impacto' }} />
          </Col>
        </Row>
        <Row style={{ placeContent: 'center' }} className={'mt-lg-5'}>
          <Col md={10}>{this.renderOurImpacts()}</Col>
        </Row>
        {partners.length ? (
          <>
            <Row>
              <Col align={'center'} className={'mb-5 mt-n3 mt-lg-4'}>
                <NavButton sec to={'/quem-somos'} text={'saiba mais'} />
              </Col>
            </Row>

            <Row>
              <Col align={'center'} className={'mt-3 mt-lg-5'}>
                <Text heading3 title={{ one: 'Nossos', two: 'parceiros' }} />
              </Col>
            </Row>
            <Row className={'mb-lg-5'}>
              <CarouselComponent
                enableAutoPlay
                itemsPerPage={3}
                disableArrowsOnEnd
                showArrows
                withLink
                breakPoints={breakPoints}
                items={partners.map((i) => ({
                  img: `${baseURL()}/api/file/${i.file.id}/${i.file.slug}`,
                  link: i.link,
                }))}
              />
            </Row>
          </>
        ) : (
          ''
        )}

        {newsItems.length ? (
          <>
            <Row>
              <Col align={'center'} className={'mt-5'}>
                <Text heading3 title={{ one: 'Fique por', two: 'dentro' }} />
              </Col>
            </Row>
            <Row className={'mt-4 pt-3 mb-5 pb-4 pb-lg-5'}>
              <CarouselComponent breakPoints={stayInBreakPoints} items={newsItems} />
            </Row>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        body={this.body}
        modalState={modalState}
        alertScreenState={alertScreenState}
        loadingState={loadingState}
      />
    );
  }
}

Home.propTypes = {
  //props
  history: PropTypes.object,
};
export default Home;
