import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Text = styled.p`
  font-size: 20px;
  line-height: 34px;
  margin: 10px 0 45px;

  ${({ stayIn }) =>
    stayIn
      ? `
    color: ${colors.secondaryTwo};
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    `
      : ''};

  @media ${bootstrapMax.lg} {
    ${({ home, stayIn }) =>
      stayIn && !home
        ? ''
        : !home
        ? 'margin: 25px 0 10px;'
        : 'margin: 0 0 55px 0; text-align: center'};

    ${({ home }) => (!home ? 'margin: unset i' : '0 0 55px 0; text-align: center')};
    line-height: 30px;
  }
`;

export const TextLink = styled.a`
  color: ${({ footer }) => (footer ? colors.primaryOne : colors.white)} !important;
  text-decoration: underline !important;
  font-size: 16px !important;
  margin: 10px 0;

  ${({ footer }) => (!footer ? `@media ${bootstrapMax.lg} { font-size: 14px !important; }` : '')}
`;

export const TextMenu = styled.span`
  color: ${colors.white} !important;
  font-size: 16px !important;
  margin: 10px 0;
`;

export const TitleApp = styled.span`
  font-size: 24px;
  text-transform: uppercase;

  @media ${bootstrapMax.lg} {
    font-size: 16px;
  }
`;

const defaultParagraph = styled.p`
  text-align: left;

  a {
    color: ${colors.primaryOne};
  }
`;

export const Paragraph2 = styled(defaultParagraph)`
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 35px;
  ${({ center }) => (center ? 'text-align: center;' : '')}

  @media ${bootstrapMax.lg} {
    margin: 0 0 25px;
  }

  @media ${bootstrapMax.sm} {
    text-align: left;
  }
`;

export const Paragraph3 = styled(defaultParagraph)`
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 35px;
  ${({ pre }) => (pre ? 'white-space: pre-wrap;' : '')}
  @media ${bootstrapMax.lg} {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 25px;
  }
`;

export const Paragraph4 = styled.p`
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 35px;
  color: ${colors.white};

  @media ${bootstrapMax.lg} {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 25px;
  }
`;

export const TitleHeading = styled.h2`
  font-size: 38px;
  line-height: 54px;
  text-transform: uppercase;

  @media ${bootstrapMax.lg} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const TitleSearch = styled.span`
  font-size: 38px;
  line-height: 54px;
  text-align: center;

  span {
    text-transform: uppercase;
  }

  @media ${bootstrapMax.lg} {
    font-size: 32px;
    line-height: 36px;
  }
`;

const defaultHeading = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;

export const Heading1 = styled(defaultHeading)`
  align-items: start;
  flex-direction: column;

  h1:first-child {
    font-size: 46px;
    line-height: 18px;
    border-bottom: 12px solid ${colors.primaryTwo};
    ${({ home }) => (home ? 'font-weight: 600 !important;' : '')}
    ${({ home }) => (home ? 'text-transform: none;' : '')}
    ${({ home }) => (home ? 'margin-bottom: 20px;' : '')}
  }

  h1:last-child {
    font-size: 58px;
    line-height: 50px;
  }

  @media ${bootstrapMax.lg} {
    align-items: center;

    h1:first-child {
      font-size: ${({ home }) => (home ? 40 : 26)}px;
      line-height: ${({ home }) => (home ? 17 : 8)}px;
      margin-top: 3px;
      border-bottom: 9px solid ${colors.primaryTwo};
    }

    h1:last-child {
      font-size: 38px;
      margin-top: -13px;
    }
  }

  @media (max-width: 376px) {
    h1:last-child {
      font-size: 32px;
      margin-top: -15px;
    }
  }
`;

export const Heading2 = styled(defaultHeading)`
  align-items: center;
  flex-direction: column;
  h1:first-child {
    font-size: 30px;
    line-height: 24px;
  }

  h1:last-child {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${bootstrapMax.lg} {
    h1:first-child {
      font-size: 20px;
      line-height: 14px;
    }

    h1:last-child {
      font-size: 32px;
      line-height: 36px;
    }
  }
`;

export const Heading3 = styled(defaultHeading)`
  flex-direction: column;

  h1:first-child {
    font-size: 30px;
    line-height: 24px;
  }

  h1:last-child {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${bootstrapMax.lg} {
    h1:first-child {
      font-size: 20px;
      ${({ oneLine }) => (oneLine ? '' : 'line-height: 14px;')}
    }

    h1:last-child {
      font-size: 32px;
      line-height: 36px;
    }
  }

  h1 {
    white-space: break-spaces;
    word-wrap: break-word;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  text-transform: uppercase;

  ${({ stayIn }) =>
    stayIn
      ? `
    color: ${colors.secondaryTwo};
    font-size: 14px;
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    `
      : ''};
`;

export const LabelAccordion = styled.span`
  font-size: ${({ mini, faq }) => (mini ? 16 : faq ? 26 : 20)}px;
  text-transform: uppercase;

  @media ${bootstrapMax.sm} {
    font-size: ${({ mini }) => (mini ? 16 : 14)}px;
  }
`;

export const TitlePlatformCard = styled.span`
  font-size: 20px;
  margin-bottom: 12px;
`;

export const LabelContact = styled.span`
  font-size: 20px;
  line-height: 30px;

  @media ${bootstrapMax.lg} {
    font-size: 14px;
  }
`;
export const TitleWhite = styled(Paragraph4)`
  font-size: 32px;
  text-align: left;
  margin-bottom: 10px;
  color: ${colors.white};

  @media ${bootstrapMax.lg} {
    font-size: 20px;
  }
`;
export const LabelHome = styled.span`
  display: block;
  font-size: 14px;
  max-width: 110px;
  height: 21px;
  line-height: 1rem;
  margin-top: 5px;
  text-transform: lowercase;
`;

export const ModalTitle = styled.span`
  font-size: 32px;
  text-transform: uppercase;
  color: ${colors.primaryOne};
`;
export const ModalContent = styled.p`
  color: ${colors.primaryOne};
  font-size: 20px;
  line-height: 30px;

  @media ${bootstrapMax.lg} {
    font-size: 16px;
    text-align: left;
    line-height: 20px;
  }
`;
