import styled from 'styled-components';
import { bootstrapMax } from '../../../utils/devices';

export const Img = styled.div`
  width: 290px;
  height: 290px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media ${bootstrapMax.lg} {
    width: 260px;
    height: 260px;
  }
`;

export const Icon = styled.div`
  min-width: 57px;
  min-height: 55px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  margin: 0 25px 0 50px;

  @media ${bootstrapMax.lg} {
    min-width: 40px;
    min-height: 40px;
    margin: 0 13px 0 0;
  }
`;

export const Span = styled.div`
  font-size: ${({ noFound }) => (noFound ? 20 : 16)}px;
  line-height: ${({ noFound }) => (noFound ? 34 : 35)}px;
  margin: 10px 0 0;
  ${({ noFound }) => (noFound ? '' : 'opacity: 0.8;')}

  @media ${bootstrapMax.lg} {
    text-align: center;
    ${({ noFound }) => (noFound ? 'font-size: 16px; line-height: 26px;' : '}')}
  }
`;
