import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

export const Flux = styled.div`
  width: 104px;
  height: 102px;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${bootstrapMax.lg} {
    width: 92px;
    height: 90px;
  }
`;

export const Frame = styled.div`
  background-color: ${colors.secondaryThree};
  padding: 55px 42px 30px;
  border-radius: 46px;

  @media ${bootstrapMin.xs} {
    object-fit: cover;
    width: 100vw;
    margin-left: -35px;
    padding: 35px;
  }
`;

export const Div904 = styled.div`
  max-width: 904px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  @media ${bootstrapMax.lg} {
    justify-content: center;
  }
`;

export const Principle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 385px;
  font-size: 20px;
  font-weight: bold;

  div {
    width: 85px;
    height: 80px;
    margin: 45px 50px 45px 30px;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  @media ${bootstrapMax.sm} {
    div {
      width: 68px;
      height: 61px;
    }
  }

  @media ${bootstrapMax.sm} {
    div {
      margin: 20px 35px 20px 0;
    }
  }
`;
