import styled from 'styled-components';
import { colors } from '../../utils/colors';
import search_icon from '../../assets/icons/search_gray-min.png';
import { bootstrapMax, device } from '../../utils/devices';

export const Input = styled.input`
  height: 40px;
  padding: 0 10px;
  border-style: solid;
  border-width: 1px;
  margin-top: 5px;
  :focus {
    outline: 0;
  }
  color: ${colors.black};

  ::placeholder {
    color: ${colors.lightGrey};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.lightGrey};
`;

export const InputDisabled = styled(Input)`
  background: transparent;
  height: 34px;
  border-style: solid;
  border-color: ${colors.white};
  color: ${colors.white};
`;

export const InputTransparent = styled(Input)`
  background: transparent;
  height: 34px;
  border-style: solid;
  border-color: ${colors.white};
  color: ${colors.white};
`;

export const InputBrown = styled(Input)`
  border-width: 1.5px;
  border-color: ${colors.primary};
`;

export const BigInputBrown = styled(Input)`
  border-width: 1.5px;
  border-color: ${colors.primary};
  height: 55px;
`;

export const InputSearch = styled(InputBrown)`
  padding-left: 35px;
`;

export const InputCloseImg = styled.img`
  width: 18px;
  display: flex;
  position: absolute;
  top: 15px;
  right: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export const InputSearchImg = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 21px;
  left: 16px;

  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;
`;

export const InputSearchBlack = styled(Input)`
  border-width: 1.5px;
  background-color: ${colors.almostBlack};
  color: ${colors.white};
  border: unset !important;
  padding-left: 37px;
  padding-right: 37px;
`;

export const InputSearchWhite = styled(Input)`
  padding-left: 47px;
  padding-right: 37px;
  font-size: 14px;
  line-height: 16px;
  max-width: 392px;
  width: 100%;
  height: 48px;
  border: 1px solid ${colors.primaryOne};
  border-radius: 10px;
  white-space: nowrap;
`;

export const InputMargin = styled(InputBrown)`
  margin-bottom: 45px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Search = styled.div`
  position: relative;
  max-width: 392px;
  width: 100%;
`;

export const InputBlack = styled(Input)`
  border-width: 1.5px;
  background-color: ${colors.almostBlack};
  color: ${colors.white};
  border: unset !important;
`;

export const InputBlackPassword = styled(Input)`
  height: 40px;
  padding: 0 10px;
  border-style: solid;
  border-width: 1px;
  margin: 5px 0 5px 0;
  :focus {
    outline: 0;
  }
  background-color: ${colors.almostBlack};
  color: ${colors.white};
  border: unset !important;
`;
