import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Context } from '../../context/authContext';
import { CustomModal } from '../../components/Loading/styles';
import { NavbarAdminApp, NavbarApp, FooterApp, CookieConsent } from '../../components';

const Div = styled.div`
  min-height: 80vh;
`;

const CustomRoute = ({ isPrivate, ...rest }) => {
  const { auth, loading, checkLogin } = useContext(Context);

  if (loading) return <CustomModal />;
  if (isPrivate) checkLogin();
  if (isPrivate && !auth) return (window.location.href = '/admin/login');

  const showAppNavbar = () =>
    !window.location.pathname.includes('login') && !rest.path.includes('/admin');
  const showAdminNavbar = () =>
    auth && !window.location.pathname.includes('login') && rest.path.includes('/admin');
  const showAppFooter = () => showAppNavbar();
  const back = () => rest.path.includes('/fique-por-dentro/:id');

  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;

  return (
    <>
      {showAppNavbar() && <NavbarApp back={back()} />}
      {showAdminNavbar() && <NavbarAdminApp />}
      <Div>
        <Route exact={rest.exact} path={rest.path} component={rest.component} />
      </Div>
      {process.env.REACT_APP_WEB !== 'false' && <CookieConsent />}
      {showAppFooter() && <FooterApp />}
    </>
  );
};

CustomRoute.propTypes = { isPrivate: PropTypes.bool };

export default CustomRoute;
