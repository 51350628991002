import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const DropDown = styled.div`
  border-color: ${colors.primary};
  border-width: 4px;
  height: 100%;
`;

export const DropDownButton = styled(DropDown)`
  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const DropDownMarginBottom = styled(DropDown)`
  margin-bottom: 50px;
`;

export const DropTime = styled.div`
  width: 50px;
  height: 100px;
`;
