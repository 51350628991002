import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonComponent, Label } from './styles';

class RadioButton extends Component {
  render() {
    // props
    const { label, checked, onClick } = this.props;

    return (
      <RadioButtonComponent onClick={onClick}>
        <div className="container">
          {label ? <Label>{label}</Label> : ''}
          <input type="radio" onChange={onClick} checked={checked}></input>
          <span className="checkmark"></span>
        </div>
      </RadioButtonComponent>
    );
  }
}

RadioButton.propTypes = {
  // props
  label: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RadioButton;
