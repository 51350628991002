import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const CustomFormControl = styled(Form.Control)`
  border-color: ${colors.primary};
  width: 100%;
  resize: none;
  outline: none;
  padding: 10px;
  ::placeholder {
    color: ${colors.lightGrey};
  }
`;

export const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;
