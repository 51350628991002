import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax } from '../../../utils/devices';

export const StyledRow = styled.div`
  position: absolute;
  background-color: ${colors.primaryOne};
  border-radius: 46px 46px 0 0;
  width: 100%;
  height: 100%;

  @media (max-width: 1366px) {
    width: 100vw;
    margin-left: -50vw;
    left: 49.99%;
  }
`;

export const Img = styled.div`
  align-self: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 72px;
  height: 72px;
  ${({ src, position, minor }) =>
    src
      ? `
  background-image: url(${src});
  background-repeat: no-repeat;
  background-position: ${position};
  border: 1px solid ${colors.primaryOne};
  border-radius: 50%;
  padding: 30px;
  background-size: ${minor ? 36 : 54}px;
  background-position: center;
`
      : ''}

  &:hover {
    border-color: ${colors.primaryTwo};
  }

  @media ${bootstrapMax.lg} {
    width: 60px;
    height: 60px;
    ${({ src, minor }) => (src ? ` background-size: ${minor ? 30 : 45}px;` : '')}
  }
`;

export const ImgBig = styled.div`
  width: 252px;
  height: 252px;
  margin-bottom: 15px;
  left: -30%;
  cursor: pointer;
  position: absolute;

  animation: rotation 4s infinite linear;
  animation-play-state: paused;

  div {
    width: 100%;
    height: 100%;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    ${process.env.REACT_APP_WEB !== 'true'
      ? 'animation: rotation 3s infinite linear; '
      : 'animation: rotation 5s infinite linear; '}
  }

  &:hover {
    animation-play-state: running;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media ${bootstrapMax.lg} {
    width: 210px;
    height: 210px;
  }
`;

export const InsideText = styled.p`
  max-width: 80px;
  font-size: 16px;
  text-transform: uppercase;
  color: ${colors.primaryOne};
`;

export const GrayCircle = styled.div`
  background-color: #ececec;
  border-radius: 50%;
  background-size: 50px;
  position: absolute;
  width: 156px;
  height: 156px;
  float: initial;
  top: 20%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${bootstrapMax.lg} {
    width: 130px;
    height: 130px;
  }
`;

export const MiddleColumn = styled.div`
  width: 156px;

  @media ${bootstrapMax.lg} {
    width: 130px;
  }
`;

export const MiddleColumnDiv = styled.div`
  width: 156px;
  height: 252px;
  position: relative;

  @media ${bootstrapMax.lg} {
    width: 130px;
    height: 210px;
  }
`;
