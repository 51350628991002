import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const BrownLine = styled.div`
  > hr {
    border-width: 1px;
    border-color: ${colors.primary};
  }
`;

export const WhiteLine = styled.div`
  > hr {
    border-width: 1px;
    border-color: ${colors.white};
  }
`;
