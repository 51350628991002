const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const partnersService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/partners/admin/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/partners/admin`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getTypes: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/partners/admin/types`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (partner) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/partners/admin`, dataFormParse(partner));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (partner) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(
          `/api/partners/admin/${partner.id}`,
          dataFormParse(partner),
        );
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/partners/admin/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/partners/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/partners`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default partnersService;
