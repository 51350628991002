import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text, Input, Card } from '../../../components';
import { Span } from './styles';

import newsService from '../../../services/news';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class StayIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      search: '',
      searched: '',
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    const response = await newsService.user.getList({ search: this.state.search });

    if (response.ok && response.data) {
      this.setState({
        news: response.data,
      });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  handleChange(search) {
    this.setState({ search });
  }

  async handleSearch() {
    this.setState({ loadingState: true, searched: this.state.search });
    const response = await newsService.user.getList({ search: this.state.search });

    if (response && response.ok && response.data) this.setState({ news: response.data });

    this.setState({ loadingState: false });
  }

  news = (news) => (
    <Col
      className={`no-gutters d-flex flex-row flex-wrap justify-content-center justify-content-md-between justify-content-lg-start`}>
      {news.map(({ id, titleComplete, describe, file }) => (
        <Card
          key={id}
          id={id}
          title={titleComplete}
          description={describe}
          image={file && file.id ? `${baseURL()}/api/file/${file.id}/${file.slug}` : ''}
          {...this.props}
        />
      ))}
    </Col>
  );

  body() {
    const { news, searched } = this.state;

    return (
      <>
        <Row className={'mt-5 mt-lg-5'}>
          <Col>
            <Text heading1 title={{ one: 'fique por', two: 'dentro' }} />
          </Col>
        </Row>
        <Row className={'mt-3 mt-lg-4 pt-lg-1 pb-4 pb-lg-5'}>
          <Col className={'d-flex justify-content-center justify-content-lg-start'}>
            <Input
              searchWhite
              value={this.state.search}
              label={'Buscar'}
              placeholder={'Buscar'}
              maxLength={100}
              onChange={(e) => this.handleChange(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && this.handleSearch()}
            />
          </Col>
        </Row>

        {searched ? (
          <Row>
            <Col xs={12} sm={12} className={'d-flex mt-2 justify-content-center'}>
              <Text titleSearch text={`RESULTADO DA BUSCA PARA `} searched={searched} />
            </Col>

            <Col className={'d-flex justify-content-center'}>
              {news.length ? (
                <Span>{`Aproximadamente ${news.length} resultado${
                  news.length > 1 ? 's' : ''
                }`}</Span>
              ) : (
                <Span noFound>{`Não encontramos nenhum resultado para "${searched}".`}</Span>
              )}
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row className={'mt-4 pt-3 mb-n3 mb-lg-0'}>{this.news(news)}</Row>
      </>
    );
  }

  render() {
    const { loadingState, modalState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} modalState={modalState} />;
  }
}

export default StayIn;
