import styled from 'styled-components';
import { bootstrapMax } from '../../utils/devices';

export const Social = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65px;

  ${({ footer }) => (!footer ? `@media ${bootstrapMax.lg} { width: 55px; }` : '')}
`;
