import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import CustomRoute from './CustomRoute';
import { AuthProvider } from '../../context/authContext';

import Home from '../app/Home';
import AboutUs from '../app/AboutUs';
import Contact from '../app/Contact';
import Error from '../app/Error';
import Discard from '../app/Discard';
import News from '../app/News';
import Economy from '../app/Economy';
import Platform from '../app/Platform';
import StayIn from '../app/StayIn';
import TermsUse from '../app/TermsUse';
import Faq from '../app/Faq';
import Traceability from '../app/Traceability';

import AdminLogin from '../admin/Login';
import AdminLogout from '../admin/Logout';
import AdminHome from '../admin/Home';
import AdminNews from '../admin/News';
import AdminNewsList from '../admin/News/list';
import AdminPartners from '../admin/Partners';
import AdminPartnersList from '../admin/Partners/list';
import DiscardPoints from '../admin/DiscardPoints';
import DiscardPointsList from '../admin/DiscardPoints/list';

class Router extends Component {
  render() {
    return (
      <AuthProvider>
        <Switch>
          <CustomRoute exact path="/" component={Home} />
          <CustomRoute exact path="/quem-somos" component={AboutUs} />
          <CustomRoute exact path="/descarte-aqui" component={Discard} />
          <CustomRoute exact path="/fique-por-dentro" component={StayIn} />
          <CustomRoute exact path="/fique-por-dentro/:id" component={News} />
          <CustomRoute exact path="/economia-circular" component={Economy} />
          <CustomRoute exact path="/plataforma" component={Platform} />
          <CustomRoute exact path="/termos-uso" component={TermsUse} />
          <CustomRoute exact path="/faq" component={Faq} />
          <CustomRoute exact path="/contato" component={Contact} />
          <CustomRoute exact path="/rastreabilidade" component={Traceability} />

          <CustomRoute exact path="/admin/login" component={AdminLogin} />
          <CustomRoute exact path="/admin/logout" component={AdminLogout} />
          <CustomRoute isPrivate exact path="/admin" component={AdminNewsList} />
          <CustomRoute isPrivate exact path="/admin/fique-por-dentro" component={AdminNewsList} />
          <CustomRoute isPrivate exact path="/admin/home" component={AdminHome} />
          <CustomRoute isPrivate exact path="/admin/fique-por-dentro/:id" component={AdminNews} />
          <CustomRoute isPrivate exact path="/admin/parceiros" component={AdminPartnersList} />
          <CustomRoute isPrivate exact path="/admin/parceiros/:id" component={AdminPartners} />
          <CustomRoute
            isPrivate
            exact
            path="/admin/pontos-de-descarte"
            component={DiscardPointsList}
          />
          <CustomRoute
            isPrivate
            exact
            path="/admin/pontos-de-descarte/:id"
            component={DiscardPoints}
          />

          <CustomRoute path="/*" component={Error} />
        </Switch>
      </AuthProvider>
    );
  }
}

export default Router;
