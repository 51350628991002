import React, { Component } from 'react';
import { Nav, Ul, Logo } from './styles';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logos/logo-white.png';
class NavbarAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
    };
  }

  render() {
    const hideMenuItems = window.location.pathname.includes('politica-de-privacidade');

    return (
      <>
        <Nav>
          <Logo src={logo} alt="logo" />
        </Nav>
        {!hideMenuItems ? (
          <Ul>
            <NavLink to="/admin/home" activeStyle={{}}>
              <li>Home</li>
            </NavLink>
            <NavLink to="/admin/pontos-de-descarta" activeStyle={{}}>
              <li>Pontos de Descarte</li>
            </NavLink>
            <NavLink to="/admin/fique-por-dentro" activeStyle={{}}>
              <li>Fique por dentro</li>
            </NavLink>
            <NavLink to="/admin/parceiros" activeStyle={{}}>
              <li>Parceiros</li>
            </NavLink>
            <NavLink to="/admin/logout" activeStyle={{}}>
              <li>Sair</li>
            </NavLink>
          </Ul>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default NavbarAdmin;
