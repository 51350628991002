import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax, device } from '../../utils/devices';

const defaultButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GT Walsheim Pro Bold', serif !important;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  width: 202px;
  height: 41px;
  border: 1px solid ${({ sec }) => (sec ? colors.primaryThree : colors.primaryOne)};
  color: ${({ sec }) => (sec ? colors.primaryThree : colors.primaryOne)};
  border-radius: 24px;
  white-space: nowrap;
  text-decoration: none;
`;

export const Simple = styled(defaultButton)`
  font-size: 18px;
  width: 303px;
  height: 48px;
  margin: 12px 0;
  background-color: ${({ danger }) => (danger ? '#ff1b1bed' : 'transparent')};
  color: ${({ danger }) => (danger ? colors.white : colors.primaryOne)};

  @media ${bootstrapMax.lg} {
    width: 100%;
    max-width: 303px;
  }
`;

export const styleButton = styled.button`
  // text-transform: uppercase;
  display: flex;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const SimpleButton = styled(styleButton)`
  padding: 15px 30px;
  font-size: 24px;
  line-height: 28px;
`;

export const LinkButton = styled(styleButton)`
  font-size: 14px;
  line-height: 15px;

  @media ${bootstrapMax.sm} {
    display: none;
  }
`;

export const BackButton = styled(NavLink)`
  font-size: 14px;
  line-height: 15px;
`;

export const CloseButton = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;`
      : ''}
  margin: 10px;
`;

export const BackButtonIcon = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;`
      : ''}
  margin: 0 10px
`;

export const DownloadButton = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;
        `
      : ''}
`;

export const SearchButton = styled(styleButton)`
  ${({ img }) =>
    img
      ? `
        background-image: url(${img});
        background-repeat: no-repeat;
        background-size: cover;`
      : ''};
`;

export const DownloadButtonApp = styled.img`
  width: 20px;
`;

export const SearchIcon = styled.img`
  width: 20px;
`;

export const SearchIconAdmin = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const SearchAdmin = styled(SimpleButton)`
  color: ${colors.white};
  padding: 10px;
  height: 40px;
  font-size: 20px;
  line-height: 20px;
  width: 100%;

  @media ${device.mobileL} {
    margin-top: 25px;
  }
`;

export const BackText = styled.div`
  text-decoration: underline;
  margin-left: 20px;
  font-size: 18px;
`;
