import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxComponent, Label, LabelApp } from './styles';
import { Col } from 'react-bootstrap';
class Checkbox extends Component {
  handleMulti(id) {
    const { checkedList } = this.props;
    const checked = id === checkedList.find((i) => i === id);
    if (checked) {
      const index = checkedList.indexOf(id);
      if (index > -1) {
        checkedList.splice(index, 1);
      }
    } else {
      checkedList.push(id);
    }
    return checkedList;
  }

  render() {
    // props
    const { label, checked, onClick, multi, list, checkedList, disable, app } = this.props;

    if (multi)
      return list.map((item) => {
        const selected = item.id === checkedList.find((i) => i === item.id);
        return (
          <Col xs={6} md={list.length < 4 ? 6 : 4} lg={list.length < 4 ? 6 : 3} key={item.id}>
            <CheckboxComponent
              onClick={() => !disable && !!onClick && onClick(this.handleMulti(item.id))}>
              <div className="container">
                {app ? <LabelApp>{item.label}</LabelApp> : <Label>{item.label}</Label>}
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={() => (!disable && !!onClick ? onClick(item.id, selected) : undefined)}
                />
                <span className="checkmark" />
              </div>
            </CheckboxComponent>
          </Col>
        );
      });

    return (
      <>
        <CheckboxComponent className="mb-2" onClick={!disable && !!onClick ? onClick : undefined}>
          <div className="container">
            {app ? <LabelApp>{label}</LabelApp> : <Label>{label}</Label>}
            <input
              type="checkbox"
              checked={checked}
              onChange={!disable && !!onClick ? onClick : () => {}}
            />
            <span className="checkmark" />
          </div>
        </CheckboxComponent>
      </>
    );
  }
}

Checkbox.propTypes = {
  //props
  label: PropTypes.string,
  checked: PropTypes.bool,
  multi: PropTypes.bool,
  disable: PropTypes.bool,
  list: PropTypes.array,
  checkedList: PropTypes.array,
  onClick: PropTypes.func || PropTypes.bool,
  app: PropTypes.bool,
};

export default Checkbox;
