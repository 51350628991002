import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const styleButton = styled.button`
  display: flex;
  cursor: pointer;
  padding: 15px 40px;
  background-color: transparent;
  border: 1px solid ${colors.primary};
  font-size: 16px;
  line-height: 16px;
  color: ${colors.secondary};
`;

export const Button = styled(styleButton)`
  width: ${({ img }) => (img ? 125 : 200)}px;
  height: 125px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .img-container {
    display: flex;
    align-items: start;

    img:first-child {
      width: 15px;
    }

    &::after {
      content: '';
      width: 15px;
      height: 15px;
    }

    img:last-child {
      width: 30px;
      margin-top: 15px;
    }
  }

  > input[type='file'] {
    display: none;
  }
`;

export const SquareDotted = styled.button`
  border: 2px dashed ${colors.primary};
  width: 100%;
  height: 45px;
  background: unset;
  color: ${colors.secondary};
  font-size: 16px;

  > input[type='file'] {
    display: none;
  }
`;
