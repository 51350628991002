import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  // AlertScreenContainer,
  Container,
  ContainerBody,
  ContainerBodyLogin,
  ContainerFooter,
  ContainerFooterLogin,
  ContainerHeader,
  ContainerLogin,
} from './styles';
import { Modal, AlertScreen, Loading, Button } from '../index';

class Screen extends Component {
  render() {
    // props
    const { back, onClickBack, body, footer, modalState, loadingState } = this.props;

    //types
    const { appPage, login, alertScreenState } = this.props;

    if (login) {
      return (
        <ContainerLogin>
          <Modal {...modalState} />
          <Loading loadingState={loadingState} />
          <ContainerHeader />
          <ContainerBodyLogin>{body ? body() : ''}</ContainerBodyLogin>
          <ContainerFooterLogin>{footer ? footer() : ''}</ContainerFooterLogin>
        </ContainerLogin>
      );
    }

    if (alertScreenState && alertScreenState.open) {
      return (
        <Container>
          <ContainerHeader />
          <ContainerBody>
            <AlertScreen {...alertScreenState} />
          </ContainerBody>
          <ContainerFooter />
        </Container>
      );
    }

    if (appPage) {
      return (
        <Container>
          <ContainerHeader />
          <Loading loadingState={loadingState} />
          <Modal {...modalState} />
          <ContainerBody>{body && body()}</ContainerBody>
          <ContainerFooter>{footer && footer()}</ContainerFooter>
        </Container>
      );
    }

    return (
      <Container>
        <ContainerHeader />
        <Loading loadingState={loadingState} />
        <Modal {...modalState} />
        <ContainerBody>{body()}</ContainerBody>
        <ContainerFooter>{footer ? footer() : ''}</ContainerFooter>
      </Container>
    );
  }
}

Screen.propTypes = {
  // props
  body: PropTypes.func.isRequired,
  footer: PropTypes.func,
  back: PropTypes.bool,
  modalState: PropTypes.object,
  loadingState: PropTypes.bool,
  noData: PropTypes.bool,
  onClickBack: PropTypes.func,

  // types
  alertScreenState: PropTypes.object,
  appPage: PropTypes.bool,
  login: PropTypes.bool,
  backgroundLogo: PropTypes.bool,
};

export default Screen;
