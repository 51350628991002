import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const defaultButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GT Walsheim Pro Bold', serif !important;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  width: 202px;
  height: 41px;
  border: 1px solid ${({ sec }) => (sec ? colors.primaryThree : colors.primaryOne)};
  color: ${({ sec }) => (sec ? colors.primaryThree : colors.primaryOne)};
  border-radius: 24px;
  white-space: nowrap;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out 0.1s;

  &:hover {
    color: ${({ sec }) => (sec ? colors.primaryTwo : colors.primaryOne)}!important;
    border: 1px solid ${({ sec }) => (sec ? colors.primaryTwo : colors.primaryOne)};
  }
`;

export const Simple = styled(defaultButton)`
  font-size: 18px;
  width: 303px;
  height: 48px;
  margin: 12px 0;

  @media ${bootstrapMax.lg} {
    width: 100%;
    max-width: 303px;
  }
`;

export const Header = styled(defaultButton)`
  &:hover {
    color: ${colors.primaryTwo}!important;
    border: 1px solid ${colors.primaryTwo};
  }
`;
