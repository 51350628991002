import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const FileCard = styled.div`
  border: 0.5px solid ${colors.primary};
  background: ${colors.secondaryTransparent};
  padding: 5px;

  .file-title {
    font-size: 16px;
    color: ${colors.primary};
    overflow-wrap: break-word;
    min-height: 30px;
  }

  .file-name {
    font-size: 12px;
    color: ${colors.secondary};
    overflow-wrap: break-word;
  }
`;
