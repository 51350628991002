import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Simple } from './styles';

class NavButton extends Component {
  render() {
    // props
    const { text, to } = this.props;

    //types
    const { sec, header } = this.props;

    if (header)
      return (
        <Header href={to}>
          <span>{text}</span>
        </Header>
      );
    if (sec)
      return (
        <Simple sec href={to}>
          <span>{text}</span>
        </Simple>
      );
    return (
      <Simple href={to}>
        <span>{text}</span>
      </Simple>
    );
  }
}

NavButton.propTypes = {
  // props
  to: PropTypes.string,
  text: PropTypes.string,

  // types
  sec: PropTypes.bool,
  header: PropTypes.bool,
};

export default NavButton;
