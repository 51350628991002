import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Label as LabelComponent,
  LabelHeader,
  LabelMarginBottom,
  LabelMenu,
  WhiteLabelComponent,
} from './styles';

class Label extends Component {
  render() {
    // props
    const { text } = this.props;

    //types
    const { marginBottom, whiteLabel, header, menu } = this.props;

    if (header) {
      return <LabelHeader>{text}</LabelHeader>;
    }

    if (menu) {
      return <LabelMenu>{text}</LabelMenu>;
    }

    if (marginBottom) {
      return <LabelMarginBottom>{text}</LabelMarginBottom>;
    }

    if (whiteLabel) {
      return <WhiteLabelComponent>{text}</WhiteLabelComponent>;
    }

    return (
      <>
        <LabelComponent>{text}</LabelComponent>
      </>
    );
  }
}

Label.propTypes = {
  // props
  text: PropTypes.string,

  // types
  header: PropTypes.bool,
  menu: PropTypes.bool,
  marginBottom: PropTypes.bool,
  whiteLabel: PropTypes.bool,
};

export default Label;
