import React, { Component } from 'react';
import { Card, CardApp, Tag, TagApp, AppIcon } from './styles';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Text, Button } from '../index.js';
import pdf_icon from '../../assets/icons/pdf_blue-min.png';
import pdf_icon_white from '../../assets/icons/pdf_white@2x-min.png';

class ActivyHistoryItem extends Component {
  render() {
    // props
    const { title, text, companyName, tagText, onClickDownload, app } = this.props;

    if (app)
      return (
        <CardApp onClick={onClickDownload}>
          <Row>
            <Col>
              <Text noMargin text={companyName ? companyName : ''} />
            </Col>
          </Row>
          <Row>
            <Col xs={2} sm={2} md={2}>
              <AppIcon src={pdf_icon_white} alt="" />
            </Col>
            <Col xs={10} sm={10} md={10}>
              <Text cardTitleApp text={title} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text noMarginApp text={text} />
            </Col>
          </Row>
          <Row>
            <Col xs={10} sm={10} md={10}>
              {tagText ? <TagApp>{tagText}</TagApp> : ''}
            </Col>
            <Col className="p-1" xs={2} sm={2} md={2}>
              <Button downloadApp />
            </Col>
          </Row>
        </CardApp>
      );

    return (
      <Card onClick={onClickDownload}>
        <Row>
          <Col>
            <Text noMargin text={companyName ? companyName : ''} />
          </Col>
        </Row>
        <Row>
          <Col xs={2} sm={2} md={2}>
            <img src={pdf_icon} alt="" />
          </Col>
          <Col xs={10} sm={10} md={10}>
            <Text cardTitle text={title} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text noMargin text={text} />
          </Col>
        </Row>
        <Row>
          <Col xs={10} sm={10} md={10}>
            {tagText ? <Tag>{tagText}</Tag> : ''}
          </Col>
          <Col className="p-1" xs={2} sm={2} md={2}>
            <Button download />
          </Col>
        </Row>
      </Card>
    );
  }
}

ActivyHistoryItem.propTypes = {
  //props
  title: PropTypes.string,
  text: PropTypes.string,
  companyName: PropTypes.string,
  tagText: PropTypes.string,
  download: PropTypes.string,
  onClick: PropTypes.func,
  onClickDownload: PropTypes.func,
  app: PropTypes.bool,
};

export default ActivyHistoryItem;
