import React, { useContext, useEffect, useState } from 'react';
import { Button, Screen, Text, Input } from '../../../components';
import { Row, Col } from 'react-bootstrap';
import { Context } from '../../../context/authContext';

import logoImage from '../../../assets/logos/logo@2x.png';

const Login = () => {
  const [user, setUser] = useState({ email: '', password: '' });
  const [state, setState] = useState({
    loadingState: false,
    modalState: { title: 'Erro', text: '', toggle: false, onHide: null },
  });

  const { handleLogin } = useContext(Context);

  const handleChange = (id, value) => {
    setUser({ ...user, [id]: value });
  };

  const handleSubmit = async () => {
    setState({ ...state, loadingState: true });
    const response = await handleLogin(user);

    if (response.ok) {
      window.location.href = '/admin';
    } else {
      state.modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: () => {
          state.modalState.toggle = false;
          setState({ state });
        },
      };
    }
    setState({ state });
    setState({ ...state, loadingState: false });
  };

  const body = () => {
    return (
      <>
        <Row>
          <Col align={'center'}>
            <img src={logoImage} srcSet={logoImage} width={'100%'} alt={'Cotton Move'} />
            <Col>
              <Text text={'Bem Vindo'} />
            </Col>
            <Col sm={10}>
              <Input
                type="email"
                value={user.email}
                placeholder={'E-mail'}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </Col>
            <Col sm={10}>
              <Input
                type="password"
                value={user.password}
                placeholder={'Senha'}
                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                onChange={(e) => handleChange('password', e.target.value)}
              />
            </Col>
          </Col>
        </Row>
      </>
    );
  };

  const footer = () => {
    return (
      <Row>
        <Button underline text={'Entrar'} onClick={handleSubmit} />
      </Row>
    );
  };

  return (
    <Screen
      login
      body={body}
      footer={footer}
      modalState={state.modalState}
      alertScreenState={state.alertScreenState}
      loadingState={state.loadingState}
    />
  );
};

export default Login;
