import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMin } from '../../utils/devices';

export const PreviewImg = styled.div`
  width: 125px;
  height: 125px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${colors.ligthBrown};
`;
export const PreviewDoc = styled.div`
  width: 70px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const CustomImg = styled.img`
  max-width: 110px;
  max-height: 110px;
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${colors.primary};
  border-radius: 50%;
  position: absolute;
  top: -16px;
  right: -16px;

  background-color: ${colors.white} !important;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 26px;
`;

export const LogoImg = styled.img`
  width: 100%;
  max-width: 360px;
  margin-bottom: 50px;
`;

export const PinImg = styled.img`
  width: 100%;
  max-width: 16px;
`;

export const Circle = styled.div`
  width: 55px;
  height: 55px;
  border-color: ${({ selected }) =>
    selected ? colors.primaryTwo : colors.secondaryTwo} !important;
  border-width: ${({ selected }) => (selected ? '2px' : '1px')} !important;
  border: 1px solid;
  border-radius: 50%;
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;

  @media ${bootstrapMin.xs} {
    width: 100vw;
    height: 275px;
    margin-left: -35px;
    object-fit: cover;
  }
`;
