export const colors = {
  primaryOne: '#1B2634',
  primaryTwo: '#CBD42F',
  primaryThree: '#7490B7',
  secondaryOne: '#3F4B66',
  secondaryTwo: '#DFE5EE',
  secondaryThree: '#EFF5FE',
  white: '#FFFFFF',
  black: '#000000',

  primaryTransparent: '#FDF3EF',
  secondaryTransparent: '#F1F1F1',
  primaryDark: '#501F0A',
  primaryDarKTransparent: '#4D4D4D',
  almostBlack: '#141313',
  greyDark: '#363636',
  almostTransparant: 'rgba(255,255,255,0.5)',
  lightGrey: '#cccccc',
  ligthBrown: 'rgba(181, 104, 71, 0.3)',
};
