import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import { baseURL } from '../../utils/defaults';

const AppUrlListener = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (event) => {
      const slug = event.url.replace(baseURL(), '').replace('app://cottonmove', '');

      if (slug) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
