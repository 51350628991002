import './App.css';
import React, { useState, useEffect } from 'react';
import AppUrlListener from './components/AppUrelListener';
import Router from './pages/router/Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from './components/index';
import versionService from './services/version';
import { StatusBar, Style } from '@capacitor/status-bar';

import { Device } from '@capacitor/device';
import { App as appCap } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { PushNotifications } from '@capacitor/push-notifications';
import { FirebaseAnalytics } from './utils/firebase';

function App() {
  const [state, setState] = useState({
    modalState: { title: 'Oops!', text: '', toggle: false },
  });

  useEffect(async () => {
    await appConfig();
  }, []);

  const OneSignalInit = () => {
    window['plugins'].OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_APP_ID);
    window['plugins'].OneSignal.setNotificationOpenedHandler((jsonData) => {
      console.info('notificationOpenedCallback======>> ' + JSON.stringify(jsonData));
    });
    window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.info('User accepted notifications======>> ' + accepted);
    });
  };

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === 'granted') {
      OneSignalInit();
    }
  };

  const appConfig = async () => {
    const devInfo = await Device.getInfo();

    if (
      devInfo.platform !== 'web' &&
      (devInfo.operatingSystem === 'android' || devInfo.operatingSystem === 'ios')
    ) {
      Network.addListener('networkStatusChange', (status) => {
        checkNetwork(status);
      });
      await registerNotifications();

      const appInfo = await appCap.getInfo();
      await checkVersion(devInfo, appInfo);
      if (devInfo.operatingSystem === 'ios') await StatusBar.setStyle({ style: Style.Light });
    } else if (devInfo.platform === 'web') {
      window.OneSignal = window.OneSignal || [];
      if (!window.OneSignal.config || (window.OneSignal.config && !window.OneSignal.config.appId))
        window.OneSignal.push(() => {
          window.OneSignal.init({
            appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
            notifyButton: {
              enable: true,
            },
          });
        });

      await FirebaseAnalytics.initializeFirebase({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    }

    await FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  };

  const checkNetwork = async (status) => {
    if (!status.connected) {
      state.modalState = {
        title: 'Atenção',
        text: 'Sem conexão com a internet, por favor verifique.',
        toggle: true,
      };
      setState({ ...state });
    } else {
      state.modalState = {
        toggle: false,
      };
      setState({ ...state });
      await appConfig();
    }
  };

  const checkVersion = async (devInfo, appInfo) => {
    const { data } = await versionService.get(devInfo.operatingSystem, appInfo.version);

    if (data && !data.updated) {
      state.modalState = {
        title: 'Atenção',
        text: 'Essa versão do aplicativo encontra-se desatualizada, por favor atualize na loja.',
        toggle: true,
        msg: 'IR PARA LOJA',
        versionOutdated: true,
        url:
          devInfo.operatingSystem === 'android'
            ? 'https://play.google.com/store/apps/details?id=io.ionic.cottonmove'
            : 'https://apps.apple.com/br/app/cottonmove/id1603901986?l=pt-br',
      };
      setState({ ...state });
    }
  };

  return (
    <main>
      <AppUrlListener />
      <Modal {...state.modalState} />
      <Router />
    </main>
  );
}

export default App;
