import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from '../index';
import {
  Nav,
  StyledBurger,
  Ul,
  ScrollList,
  Logo,
  LogoMenu,
  Close,
  MenuItem,
  MenuHeader,
  Header,
} from './styles';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { defaultStates } from '../../utils/defaults';

import hamburger from '../../assets/icons/menu/menu-icon.png';
import logo from '../../assets/logos/logo@2x.png';
import logoWhite from '../../assets/logos/logo-white@2x.png';
import close from '../../assets/icons/menu/menu-close-icon@2x.png';

class NavbarAdminApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      alerts: null,
      ...defaultStates(),
    };
  }

  componentDidMount() {}

  render() {
    const { back } = this.props;

    return (
      <>
        <Nav>
          <Header className={'header'}>
            <Row className={'align-items-center'}>
              <Col
                xs={{ span: 6, order: 2 }}
                sm={{ span: 6, order: 2 }}
                lg={{ span: 4, order: 1 }}
                className={
                  'd-flex justify-content-center justify-content-lg-start justify-content-xl-start'
                }>
                <Logo img={logo} href={'/'}>
                  <div />
                </Logo>
              </Col>

              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/home'}>
                  <Label header text={'Home'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/fique-por-dentro'}>
                  <Label header text={'Fique por dentro'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 3 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/pontos-de-descarte'}>
                  <Label header text={'Pontos de Descarte'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 1, order: 3 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/parceiros'}>
                  <Label header text={'Parceiros'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 1, order: 3 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/admin/logout'}>
                  <Label header text={'Sair'} />
                </NavLink>
              </Col>

              <Col
                xs={{ span: 3, order: 1 }}
                sm={{ span: 3, order: 1 }}
                lg={{ span: 1, offset: 0, order: 5 }}
                className={`d-${
                  back ? 'none' : 'flex'
                } d-sm-flex justify-content-start justify-content-lg-end justify-content-xl-end`}>
                <StyledBurger
                  className="d-flex d-xl-none d-lg-none"
                  open={this.state.navbar}
                  img={hamburger}
                  onClick={() => {
                    this.setState({ navbar: !this.state.navbar });
                  }}>
                  <div />
                </StyledBurger>
              </Col>
            </Row>
          </Header>
        </Nav>

        <Ul open={this.state.navbar}>
          <MenuHeader key={0} className="d-flex align-items-center justify-content-between">
            <LogoMenu src={logoWhite} alt="cotton move logotipo" />
            <Close img={close} onClick={() => this.setState({ navbar: false })} />
          </MenuHeader>
          <ScrollList>
            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={1}
              to="/admin/home">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Home'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={2}
              to="/admin/fique-por-dentro">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Fique por dentro'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={3}
              to="/admin/pontos-de-descarte">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Pontos de coleta'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={4}
              to="/admin/parceiros">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Parceiros'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={5}
              to="/admin/logout">
              <MenuItem className="d-flex align-items-center">
                <Label menu text={'Sair'} />
              </MenuItem>
            </NavLink>
          </ScrollList>
        </Ul>
      </>
    );
  }
}

NavbarAdminApp.propTypes = { back: PropTypes.bool };

export default NavbarAdminApp;
