import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { DropDown as DropDownComponent, DropDownButton, DropDownMarginBottom } from './styles';
import { colors } from '../../utils/colors';
import { Label, Button } from '../index';
import { device } from '../../utils/devices';

class DropDown extends Component {
  render() {
    // props
    const { placeholder, label, onChange, value, onClick, selected } = this.props;
    let { options } = this.props;
    // types
    const { disable, includeAll, deleteButton, filter, marginBottom, black } = this.props;

    const optionAll = { id: 'all', name: 'Todos' };

    if (includeAll) {
      options = [optionAll, ...options];
    }

    if (options[0] && !filter) {
      if (!options[0].value || !options[0].label) {
        options.map((item) => {
          item.label = item.name;
          item.value = item.id;
        });
      }
    }

    if (filter) {
      const data = options.length > 1 ? options[1] : options[0];

      if (data) {
        data.options.map((item, i) => {
          item.label = item.name;
          item.value = item.id;
        });
      }
    }

    const optionsExample = [
      { value: 1, label: 'item 1' },
      { value: 2, label: 'item 2' },
      { value: 3, label: 'item 3' },
    ];

    const optionsExample2 = [
      {
        label: 'Group 1',
        options: [
          { label: 'option 1', value: 'value_1' },
          { label: 'option 2', value: 'value_2' },
          { label: 'option 1', value: 'value_1' },
          { label: 'option 2', value: 'value_2' },
        ],
      },
      {
        label: 'Group 2',
        options: [
          { label: 'option 1', value: 'value_1' },
          { label: 'option 2', value: 'value_2' },
          { label: 'option 1', value: 'value_1' },
          { label: 'option 2', value: 'value_2' },
        ],
      },
    ];

    const selectSyles = {
      control: (defaultStyles, state) => ({
        ...defaultStyles,
        borderRadius: 0,
        borderWidth: 1.5,
        width: '100%',
        borderColor: state.isSelected ? colors.primary : colors.primary,
        color: state.isSelected ? colors.primary : colors.primary,
        boxShadow: `unset`,
        '&:hover': {
          borderColor: colors.primary,
          boxShadow: `0 0 0 1px ${colors.primary}`,
        },
      }),
      container: (defaultStyles) => ({
        ...defaultStyles,
        minWidth: 100,
        width: '100%',
        margin: '5px 0',
        textAlign: 'center',
      }),
      indicatorSeparator: (defaultStyles) => ({
        ...defaultStyles,
        width: '0px',
      }),
      dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        color: colors.primary,
        padding: 0,
      }),
      placeholder: () => (defaultStyles) => ({
        ...defaultStyles,
        color: colors.lightGrey,
      }),
      menu: (provided) => ({ ...provided, zIndex: 100, borderRadius: 0 }),
      option: (defaultStyles, state) => ({
        ...defaultStyles,
        backgroundColor: state.isSelected ? `${colors.primaryThree}` : 'transparent',
      }),
    };

    const filterStyles = {
      control: (defaultStyles, state) => ({
        ...defaultStyles,
        borderRadius: 0,
        borderWidth: 0,
        width: screen.width > 320 ? 90 : 70,
        backgroundColor: 'transparent',
        borderColor: state.isSelected ? colors.primary : colors.primary,
        color: colors.secondary,
        boxShadow: `unset`,
        '&:hover': {
          borderColor: colors.primary,
          boxShadow: 'unset',
        },
      }),
      container: (defaultStyles) => ({
        ...defaultStyles,
        minWidth: 100,
        width: '100%',
        margin: 'unset',
        textAlign: 'left',
        fontSize: 15,
        backgroundColor: 'transparent',
      }),
      indicatorContainer: (defaultStyles) => ({
        ...defaultStyles,
      }),
      indicatorSeparator: () => {},

      indicatorsContainer: (defaultStyles) => ({
        ...defaultStyles,
        height: 'unset',
        'div:first-child': {
          paddingLeft: 0,
        },
      }),

      dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        color: colors.gray,
        padding: 0,
      }),
      valueContainer: (defaultStyles) => ({
        ...defaultStyles,
        padding: 0,
        fontSize: 16,
        color: colors.white,
      }),
      placeholder: () => (defaultStyles) => ({
        ...defaultStyles,
        color: colors.white,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 100,
        borderRadius: 0,
        width: '200px',
        marginLeft: 10,
        color: colors.white,
        backgroundColor: `${colors.greyDark}`,
      }),
      groupHeading: (defaultStyles) => ({
        ...defaultStyles,
        color: colors.secondary,
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: 16,
      }),
      option: (defaultStyles, state) => ({
        ...defaultStyles,
        backgroundColor: state.isSelected ? colors.almostTransparant : 'transparent',
      }),
    };
    const ValueContainer = ({ children, ...props }) => {
      let [values, input] = children;
      return (
        <components.ValueContainer {...props} id="ValueContainer">
          {input}
          {values[0] ? 'Limpar' : 'Filtrar'}
        </components.ValueContainer>
      );
    };

    const blackStyles = {
      control: (defaultStyles) => ({
        ...defaultStyles,
        borderRadius: 0,
        borderWidth: 1.5,
        width: '100%',
        border: 'unset',
        color: colors.white,
        backgroundColor: colors.greyDark,
        boxShadow: 'unset',
        '&:hover': {
          border: 'unset',
          boxShadow: `0 0 0 1px ${colors.primary}`,
        },
      }),
      container: (defaultStyles) => ({
        ...defaultStyles,
        minWidth: 100,
        width: '100%',
        margin: '5px 0',
        textAlign: 'center',
      }),
      indicatorSeparator: (defaultStyles) => ({
        ...defaultStyles,
        width: '0px',
      }),
      dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        color: colors.secondary,
        padding: 0,
      }),
      placeholder: () => (defaultStyles) => ({
        ...defaultStyles,
        color: colors.white,
      }),

      menu: (provided) => ({
        ...provided,
        zIndex: 100,
        borderRadius: 0,
        color: colors.white,
        backgroundColor: `${colors.greyDark}`,
      }),
      option: (defaultStyles, state) => ({
        ...defaultStyles,
        backgroundColor: state.isSelected ? colors.almostTransparant : 'transparent',
        color: colors.white,
      }),
      singleValue: () => (defaultStyles) => ({
        ...defaultStyles,
        color: colors.white,
      }),
    };

    if (deleteButton)
      return (
        <DropDownButton>
          {label && <Label text={label} />}
          <div>
            <Select
              isDisabled={disable}
              styles={selectSyles}
              placeholder={placeholder}
              options={options || optionsExample}
              value={value ? options.find((i) => i.value === value) : ''}
              noOptionsMessage={() => `Nenhum dado encontrado!`}
              onChange={(e) => onChange(e.value)}
            />
            <Button onClick={onClick} close />
          </div>
        </DropDownButton>
      );

    if (filter)
      return (
        <Select
          isMulti
          components={{ ValueContainer }}
          isDisabled={disable}
          value={selected}
          options={options || optionsExample2}
          noOptionsMessage={() => `Nenhum dado encontrado!`}
          onChange={onChange}
          styles={filterStyles}
          isSearchable={false}
          hideSelectedOptions={false}
        />
      );

    if (marginBottom)
      return (
        <DropDownMarginBottom>
          {label && <Label text={label} />}
          <Select
            isDisabled={disable}
            styles={selectSyles}
            placeholder={placeholder}
            options={options || optionsExample}
            value={value && options.find((i) => i.value === value)}
            noOptionsMessage={() => `Nenhum dado encontrado!`}
            onChange={(e) => onChange(e.value)}
          />
        </DropDownMarginBottom>
      );

    if (black)
      return (
        <DropDownComponent>
          {label && <Label text={label} />}
          <Select
            isDisabled={disable}
            styles={blackStyles}
            placeholder={placeholder}
            options={options || optionsExample}
            value={value && options.find((i) => i.value === value)}
            noOptionsMessage={() => `Nenhum dado encontrado!`}
            onChange={(e) => onChange(e.value)}
          />
        </DropDownComponent>
      );

    return (
      <DropDownComponent>
        {label && <Label text={label} />}
        <Select
          isDisabled={disable}
          styles={selectSyles}
          placeholder={placeholder}
          options={options || optionsExample}
          value={value && options.find((i) => i.value === value)}
          noOptionsMessage={() => `Nenhum dado encontrado!`}
          onChange={(e) => onChange(e.value)}
        />
      </DropDownComponent>
    );
  }
}

DropDown.propTypes = {
  //props
  text: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  defaultOption: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.array,

  //types
  includeAll: PropTypes.bool,
  disable: PropTypes.bool,
  dropTime: PropTypes.bool,
  deleteButton: PropTypes.bool,
  filter: PropTypes.bool,
  company: PropTypes.bool,
  marginBottom: PropTypes.bool,
  black: PropTypes.bool,
};

export default DropDown;
