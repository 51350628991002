import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const AlertBadge = styled.div`
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;
