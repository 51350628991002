import React, { Component } from 'react';
import { Screen, Text, Image } from '../../../components';
import MapComponent from '../../../components/Map';
import { Row, Col } from 'react-bootstrap';
import discardPointsService from '../../../services/discardPoint';

const { defaultStates, defaultModalOnHide } = require('../../../utils/defaults');

class DiscardPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discardPoints: [],
      allDiscardPoints: [],
      selected: null,
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.body = this.body.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    const response = await discardPointsService.user.get();
    if (response.ok && response.data && response.data.discardPoints) {
      const data = response.data.discardPoints;
      this.setState({ allDiscardPoints: data, discardPoints: data });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  changeFilter(type) {
    const { allDiscardPoints, selected } = this.state;

    if (selected === type) {
      this.setState({ selected: null, discardPoints: allDiscardPoints });
    } else {
      this.setState({
        selected: type,
        discardPoints: allDiscardPoints.filter((x) => x.type === type),
      });
    }
  }

  body() {
    const { allDiscardPoints, discardPoints, selected } = this.state;

    return (
      <>
        <Row align="center" className={'mb-sm-4 mb-1 mt-5 mt-md-0'}>
          <Col>
            <Text heading2 title={{ one: 'Descarte', two: 'Aqui' }} />
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 2, offset: 3 }}
            md={{ span: 3, offset: 1 }}
            sm={{ span: 4 }}
            xs={{ span: 4 }}
            align="center">
            <Image
              pin
              selected={selected === 'discard'}
              label={'PONTO DE DESCARTE'}
              onClick={() => this.changeFilter('discard')}
            />
          </Col>
          <Col lg={2} md={4} sm={4} xs={4} align="center">
            <Image
              pin
              selected={selected === 'newProducts'}
              label={'NOVOS PRODUTOS'}
              onClick={() => this.changeFilter('newProducts')}
            />
          </Col>
          <Col lg={2} md={3} xs={4} align="center">
            <Image
              pin
              selected={selected === 'discardNNewProducts'}
              label={'PONTO DE DESCARTE E NOVOS PRODUTOS'}
              onClick={() => this.changeFilter('discardNNewProducts')}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            {allDiscardPoints.length ? (
              <MapComponent points={discardPoints} hasFilter={selected != null} />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState, modalState } = this.state;

    return <Screen modalState={modalState} body={this.body} loadingState={loadingState} />;
  }
}

export default DiscardPoints;
