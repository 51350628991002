import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Pattern = styled.div`
  width: 100%;
  height: 100%;
  min-width: 23px;
  min-height: 23px;
  padding: 13px 0 9px;
  cursos: pointer;

  div {
    min-width: 23px;
    min-height: 23px;
    background-image: url(${({ img }) => img});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${({ footer }) =>
    !footer
      ? `@media ${bootstrapMax.lg} {
    min-width: 20px;
    min-height: 20px;

    div {
      min-width: 19px;
      min-height: 19px;
    }
  }`
      : ''}
`;

export const Contact = styled.a`
  font-size: ${({ menu, footer }) => (menu ? 14 : footer ? 16 : 20)}px;
  text-decoration: none !important;
  color: ${({ menu }) => (menu ? colors.white : colors.primaryOne)} !important;

  @media ${bootstrapMax.lg} {
    font-size: 14px;
  }
`;
