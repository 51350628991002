import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const ItemList = styled(ListGroup.Item)`
  border: none;
  & :hover {
    cursor: pointer;
`;

export const ItemTitle = styled.p`
  color: ${({ white }) => (white ? colors.white : colors.primary)};
  font-size: 16px;
  margin-bottom: 0;
`;

export const ItemText = styled.p`
  color: ${({ white }) => (white ? colors.white : colors.secondary)};
  font-size: 14px;
`;
