import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Contact, Pattern } from './styles';

import insta from './../../assets/icons/social_media/instagram@2x.png';
import instaWhite from './../../assets/icons/social_media/instagram-white@2x.png';
import linkedn from './../../assets/icons/social_media/linkedin@2x.png';
import linkednWhite from './../../assets/icons/social_media/linkedin-white@2x.png';

const instagramHref = 'https://www.instagram.com/cotton.move/';
const linkedinHref = 'https://www.linkedin.com/company/cotton-move/?viewAsMember=true';

class SocialMedia extends Component {
  render() {
    //types
    const { instagram, linkedin, white, footer, menu } = this.props;

    if (linkedin)
      return (
        <a href={linkedinHref} target={'_blank'} className={'social'} rel="noreferrer">
          <Pattern img={white ? linkednWhite : linkedn} footer={footer} className={'social'}>
            <div className={'social'} />
          </Pattern>
        </a>
      );
    else if (instagram)
      return (
        <a href={instagramHref} target={'_blank'} className={'social'} rel="noreferrer">
          <Pattern img={white ? instaWhite : insta} footer={footer} className={'social'}>
            <div className={'social'} />
          </Pattern>
        </a>
      );
    else
      return (
        <Contact
          menu={menu}
          footer={footer}
          href={'mailto:plataforma@cottonmove.com.br'}
          target={'_blank'}
          rel="noreferrer">
          <span>plataforma@cottonmove.com.br</span>
        </Contact>
      );
  }
}

SocialMedia.propTypes = {
  // types
  white: PropTypes.bool,
  menu: PropTypes.bool,
  footer: PropTypes.bool,
  instagram: PropTypes.bool,
  linkedin: PropTypes.bool,
};

export default SocialMedia;
