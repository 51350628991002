import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import {
  Text as TextComponent,
  TextLink,
  TitleApp,
  TitleHeading,
  Heading1,
  Heading2,
  Heading3,
  Label,
  Paragraph2,
  Paragraph3,
  LabelAccordion,
  Paragraph4,
  TitlePlatformCard,
  LabelContact,
  TitleWhite,
  LabelHome,
  TitleSearch,
  ModalTitle,
  ModalContent,
  TextMenu,
} from './styles';

class Text extends Component {
  render() {
    // props
    const { title, text, href, searched, pre } = this.props;

    //types
    const {
      link,
      titleApp,
      linkApp,
      menu,
      titleHeadApp,
      titleSearch,
      heading1,
      heading2,
      heading3,
      paragraph2,
      paragraph2center,
      paragraph3,
      paragraph4,
      footer,
      label,
      labelAcordeon,
      labelAcordeonMini,
      labelAcordeonFaq,
      contact,
      titlePlatformCard,
      titleWhite,
      home,
      labelHome,
      modalTitle,
      modalContent,
    } = this.props;

    if (titleHeadApp) {
      return <TitleHeading className={'bold'}>{text}</TitleHeading>;
    }

    if (titleSearch) {
      return (
        <TitleSearch className={'bold'}>
          <span>{text}</span>
          {`"${searched}"`}
        </TitleSearch>
      );
    }

    if (heading1) {
      return (
        <Heading1 home={home}>
          <h1 className={!title.two && !home ? 'bold' : ''}>{title.one}</h1>
          <h1 className={'bold'}>{title.two}</h1>
        </Heading1>
      );
    }

    if (heading2) {
      return (
        <Heading2>
          <h1>{title.one}</h1>
          <h1 className={'bold'}>{title.two}</h1>
        </Heading2>
      );
    }

    if (heading3) {
      return (
        <Heading3 oneLine={!title.two}>
          <h1>{title.one}</h1>
          <h1 className={'bold'}>{title.two}</h1>
        </Heading3>
      );
    }

    if (titleApp) return <TitleApp className={'bold'}>{text}</TitleApp>;

    if (paragraph2)
      return <Paragraph2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />;

    if (paragraph2center)
      return <Paragraph2 center dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />;

    if (paragraph3)
      return (
        <Paragraph3 pre={pre} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
      );

    if (paragraph4)
      return <Paragraph4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />;

    if (menu) {
      return <TextMenu>{text}</TextMenu>;
    }

    if (linkApp) {
      return (
        <TextLink footer={footer} app href={href} target="_blank">
          {text}
        </TextLink>
      );
    }

    if (link)
      return (
        <TextLink footer={footer} href={href} target="_blank">
          {text}
        </TextLink>
      );

    if (label) return <Label className={'bold'}>{text}</Label>;
    if (labelHome) return <LabelHome>{text}</LabelHome>;

    if (labelAcordeon) return <LabelAccordion className={'bold'}>{text}</LabelAccordion>;

    if (labelAcordeonMini)
      return (
        <LabelAccordion mini className={'bold'}>
          {text}
        </LabelAccordion>
      );

    if (labelAcordeonFaq) return <LabelAccordion faq>{text}</LabelAccordion>;

    if (titlePlatformCard) return <TitlePlatformCard className={'bold'}>{text}</TitlePlatformCard>;

    if (contact) return <LabelContact>{text}</LabelContact>;

    if (titleWhite)
      return (
        <TitleWhite
          className={'bold'}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    if (modalTitle) {
      const newText = text && text.split(' ');
      if (newText && newText.length > 1) {
        const one = `${newText[0]} ${newText.length > 2 ? newText[1] : ''}`;
        const two = `${newText.length === 2 ? newText[1] : newText[2]}`;
        return (
          <Heading2>
            <h1>{one}</h1>
            <h1 className={'bold'}>{two}</h1>
          </Heading2>
        );
      }
      return (
        <ModalTitle
          className={'bold'}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    }
    if (modalContent) {
      return (
        <ModalContent
          className={'mt-4 text-left'}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      );
    }

    return <TextComponent home={home}>{text}</TextComponent>;
  }
}

Text.propTypes = {
  // props
  text: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.any,
  searched: PropTypes.string,
  pre: PropTypes.bool,

  // types
  menu: PropTypes.bool,
  footer: PropTypes.bool,
  heading1: PropTypes.bool,
  heading2: PropTypes.bool,
  heading3: PropTypes.bool,
  paragraph2: PropTypes.bool,
  paragraph2center: PropTypes.bool,
  paragraph3: PropTypes.bool,
  paragraph4: PropTypes.bool,
  link: PropTypes.bool,
  titleApp: PropTypes.bool,
  linkApp: PropTypes.bool,
  titleHeadApp: PropTypes.bool,
  titleSearch: PropTypes.bool,
  label: PropTypes.bool,
  labelHome: PropTypes.bool,
  labelAcordeon: PropTypes.bool,
  labelAcordeonMini: PropTypes.bool,
  labelAcordeonFaq: PropTypes.bool,
  titlePlatformCard: PropTypes.bool,
  contact: PropTypes.bool,
  titleWhite: PropTypes.bool,
  home: PropTypes.bool,
  modalTitle: PropTypes.bool,
  modalContent: PropTypes.bool,
};

export default Text;
