import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input, Table } from '../../../components';
import { Row, Col } from 'react-bootstrap';

import newsService from '../../../services/news';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
      ids: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
    this.fillTable = this.fillTable.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
  }

  async componentDidMount() {
    await this.fillTable();
  }

  async fillTable(error = true) {
    this.setState({ loadingState: true });

    const response = await newsService.admin.getList();
    if (response.ok && response.data) {
      const data = response.data.map((i) => {
        i.news = i.news ? i.news.title : '';
        i.isActive = i.isActive ? 'Ativo' : 'Rascunho';
        return i;
      });

      this.setState({ newsList: data });
    } else {
      if (error) {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleNotification() {
    const response = await newsService.admin.notification(this.state.ids);

    const modalState = {
      toggle: true,
      onHide: this.modalOnHide,
    };
    if (response.ok) {
      modalState.title = 'Notificação Enviada';
      modalState.text = 'Notificação disparada com Sucesso';
    } else {
      modalState.title = 'Erro';
      modalState.text = response.message;
    }

    this.setState({ modalState });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
    this.fillTable(false);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  body() {
    const onCellClick = ({ id }) => this.props.history.push(`/admin/fique-por-dentro/${id}`);

    const onSelectionModelChange = (ids) => {
      this.setState({ ids });
    };

    const columns = [
      { headerName: 'ID', field: 'id', hide: true, flex: 0.05 },
      { headerName: 'Título', field: 'titleComplete', flex: 0.5 },
      { headerName: 'Data de criação', field: 'createdAt', flex: 0.2, align: 'center' },
      { headerName: 'Status', field: 'isActive', flex: 0.2 },
    ];

    return (
      <>
        <Row align={'center'}>
          <Col>
            <Text title text={'Notícias'} />
          </Col>
        </Row>
        <Row align="right">
          <Col>
            <Button
              underlinePlus
              text={'adicionar'}
              onClick={() => this.props.history.push('/admin/fique-por-dentro/novo')}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3">
            <Table
              onCellClick={onCellClick}
              data={this.state.newsList}
              columns={columns}
              checkboxSelection
              onSelectionModelChange={onSelectionModelChange}
              isRowSelectable={({ row: { notified } }) => !notified}
            />
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          <Col>
            {this.state.ids.length ? (
              <Button text={'Notificar'} onClick={() => this.handleNotification(this.state.ids)} />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        body={this.body}
        footer={this.footer}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

NewsList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default NewsList;
