import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Screen,
  Text,
  Image as Banner,
  Accordion,
  AccordionPartner,
  CarouselComponent,
} from '../../../components';
const { defaultStates, defaultModalOnHide } = require('../../../utils/defaults');
import { Div302, Icon, Img, Objectives } from './styles';

import cottonGreen from '../../../assets/imgs/cotton-green@2x.png';
import production from '../../../assets/imgs/aboutUs/aboutus-production@2x.png';
import prodIcon from '../../../assets/imgs/aboutUs/aboutUs-production-icon@2x.png';
import process from '../../../assets/imgs/aboutUs/aboutUs-process-icon@2x.png';
import country from '../../../assets/imgs/aboutUs/aboutUs-country-icon@2x.png';
import impact from '../../../assets/imgs/aboutUs/aboutUs-impact-icon@2x.png';
import abvtex from '../../../assets/imgs/aboutUs/aboutUs-abvtex@2x.png';
import abr from '../../../assets/imgs/aboutUs/aboutUs-abr@2x.png';
import rcs from '../../../assets/imgs/aboutUs/aboutUs-rcs@2x.png';
import objEqual from '../../../assets/imgs/aboutUs/aboutUs-equally@2x.png';
import objWater from '../../../assets/imgs/aboutUs/aboutUs-water@2x.png';
import objEconomy from '../../../assets/imgs/aboutUs/aboutUs-growth-economy@2x.png';
import objIndustry from '../../../assets/imgs/aboutUs/aboutUs-industry@2x.png';
import objConsumption from '../../../assets/imgs/aboutUs/aboutUs-consumption@2x.png';
import partnersService from '../../../services/partners';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [
        {
          icon: prodIcon,
          title: 'produção',
          desc:
            'Desenvolvemos produtos em escala, fazendo uso de descartes de pré e pós-consumo do ciclo produtivo ' +
            'do algodão. Utilizamos materiais e processos certificados, e seguimos as tendências mundiais em ' +
            'sustentabilidade no setor têxtil.',
        },
        {
          icon: process,
          title: 'processo',
          desc:
            'Utilizando a logística reversa e tecnologia em reciclagem, oferecemos soluções para os descartes ' +
            'da indústria, varejo e consumidor, reinserindo-os no ciclo produtivo. Esse processo agrega valor a ' +
            'algo que seria descartado, e também é conhecido como Upcycling.',
        },
        {
          icon: country,
          title: 'nosso país',
          desc:
            'Para se ter uma ideia, anualmente são gerados no país mais de 170.000 toneladas de descarte têxtil, ' +
            'somente na etapa produtiva (estimativa Associação Brasileira da Indústria Têxtil - Abit).<br><br> ' +
            'Considerando os desafios em um país de dimensão continental como o Brasil, montamos uma estrutura de ' +
            'logística e manufatura reversa com nossos parceiros, e criamos soluções aos processos de captação de ' +
            'resíduos, até que sejam recuperados.',
        },
        {
          icon: impact,
          title: 'o impacto',
          desc:
            'O reaproveitamento de fiapos, retalhos de confecção e peças em fim de ciclo de uso gera economias de ' +
            'água, energia, insumos e outros recursos naturais utilizados desde a produção agrícola até os ' +
            'processos industriais têxteis.',
        },
      ],
      objectives: [objEqual, objWater, objEconomy, objIndustry, objConsumption],
      partners: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.getPartners = this.getPartners.bind(this);
    this.body = this.body.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    await this.getPartners();
  }

  async getPartners() {
    const response = await partnersService.admin.getTypes();
    if (response.ok && response.data && response.data.types) {
      this.setState({
        partners: response.data.types,
      });
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
  }

  topics = (topics) =>
    topics.map(({ icon, title, desc }, index) => (
      <div key={index}>
        <Row className={'mt-lg-2 mt-4 d-block d-lg-flex'}>
          <Col className={'d-flex align-items-center'}>
            <Icon img={icon} />
            <Text titleApp text={title} />
          </Col>
        </Row>
        <Row>
          <Col className={'d-flex align-items-center'}>
            <Icon img={''} />
            <Text paragraph3 text={desc} />
          </Col>
        </Row>
      </div>
    ));

  body() {
    const { partners } = this.state;

    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 650, itemsToShow: 3, itemsToScroll: 2 },
      { width: 750, itemsToShow: 4, itemsToScroll: 2 },
      { width: 950, itemsToShow: 5 },
    ];

    return (
      <>
        <Row align="center" className={'mb-5 mt-5 mt-md-0'}>
          <Col
            xl={{ span: 6, order: 1 }}
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
            className={
              'd-flex no-gutters justify-content-center justify-content-lg-end pt-lg-5 pt-4'
            }>
            <Text heading1 title={{ one: 'somos a', two: 'cotton move' }} />
          </Col>
          <Col
            lg={{ span: 5, order: 2, offset: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
            className={'d-flex no-gutters justify-content-center justify-content-lg-start'}>
            <Img img={cottonGreen} />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Text
              paragraph2
              text={
                'Desenvolvemos produtos têxteis circulares a partir da reciclagem. Acreditamos na Economia Circular, ' +
                'no consumo consciente e no modo de produção ético.'
              }
            />
            <Text
              paragraph2
              text={
                'Surgimos em 2018 dentro dos conceitos de reciclagem e circularidade. Temos a missão de reduzir o ' +
                'reflexo negativo da produção têxtil e de confecção no meio ambiente, reutilizando e recuperando ' +
                'matéria prima e recursos naturais em nosso processo de fabricação.'
              }
            />
            <Text
              paragraph2
              text={
                'Acreditamos que é possível transformar a moda através do Design Circular, valorizando as fibras do ' +
                'algodão e respeitando as conformidades socioambientais.'
              }
            />
          </Col>
        </Row>

        <Row align="center" className={'mt-5'}>
          <Col className={'d-flex justify-content-center'}>
            <Text heading2 title={{ one: 'modo de', two: 'produção' }} />
          </Col>
        </Row>
        <Row align="center" className={'py-4'}>
          <Col>
            <Banner img={production} alt={'imagem tapete com logo da cotton move'} />
          </Col>
        </Row>
        <Row align="center" className={'mb-lg-5 mb-3'}>
          <Col>
            <Text
              paragraph2
              text={
                'Somos pioneiros na criação de <strong>produtos com atributos circulares</strong>, com ênfase no jeans, ' +
                'em parceria a empresas da indústria e do comércio de moda.'
              }
            />
          </Col>
        </Row>
        {this.topics(this.state.topics)}

        <Row align="center" className={'mt-5'}>
          <Col className={'d-flex mt-lg-5 mt-1 justify-content-center'}>
            <Text heading2 title={{ one: 'nossos', two: 'objetivos' }} />
          </Col>
        </Row>
        <Row align="center" className={'mb-lg-5'}>
          <Col className={'px-4'}>
            <Objectives>
              <CarouselComponent breakPoints={breakPoints} items={this.state.objectives} />
            </Objectives>
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Objectives>
              <Text
                paragraph3
                text={
                  'Os resultados reduzem os descartes em aterros sanitários, aterros clandestinos e a queima de resíduos, ' +
                  'e vão de encontro aos Objetivos de Desenvolvimento Sustentável (ODS) da ONU, acima listados.'
                }
              />
            </Objectives>
          </Col>
        </Row>

        <Row align="center" className={'mt-5'}>
          <Col className={'d-flex mt-lg-5 mt-3 justify-content-center'}>
            <Text heading2 title={{ one: 'nossas', two: 'certificações' }} />
          </Col>
        </Row>
        <Row align="center" className={'mt-lg-5 mt-4'}>
          <Col
            className={
              'd-flex flex-wrap justify-content-lg-around justify-content-center align-items-start'
            }>
            <Div302 img={abvtex} dimension={[144, 144, 129, 129]}>
              <div className={'icon'}>
                <div />
              </div>
              <Accordion
                mini
                id={'cert1'}
                summary={<Text labelAcordeonMini text={'abvtex gold'} />}
                details={
                  <Text
                    paragraph3
                    text={
                      'O programa brasileiro de certificação Abvtex oferece serviços de auditoria para ' +
                      'que regras de compliance sejam cumpridas no ambiente de trabalho de fornecedores e subcontratados.<br><br>' +
                      'As empresas participantes, como a Cotton Move, assumem o compromisso de auditar e monitorar 100% de sua ' +
                      'cadeia, e somente adquirir produtos de fornecedores aprovados pelo programa.<br><br>' +
                      'O nível Ouro da certificação contempla empresas com atendimento dos requisitos ambientais e de ' +
                      'governança/compliance observados em auditoria, e performance superior aos requisitos de responsabilidade ' +
                      'social básicos, exigidos pelo programa.'
                    }
                  />
                }
              />
            </Div302>
            <Div302 img={'abr'} dimension={[215, 95, 193, 85]}>
              <div className={'icon'}>
                <div />
              </div>
              <Accordion
                mini
                id={'cert2'}
                summary={<Text labelAcordeonMini text={'abr e bci'} />}
                details={
                  <Text
                    paragraph3
                    text={
                      'A Better Cotton Initiative é uma organização suíça que atua para melhorar a produção mundial do ' +
                      'algodão de acordo com padrões de responsabilidade socioambiental no campo.<br><br>' +
                      'O programa trabalha na conscientização e orientação do cotonicultor e demais agentes da cadeia ' +
                      'produtiva, priorizando as relações trabalhistas justas, o uso racional de agroquímicos e ' +
                      'recursos hídricos, e a rastreabilidade do algodão para o mercado.<br><br>' +
                      'O programa Algodão Brasileiro Responsável (ABR) se uniu ao BCI em 2013, para facilitar o acesso ' +
                      'da produção nacional ao mercado global alinhado com práticas de sustentabilidade.<br>' +
                      'O selo ABR incorpora os Critérios Mínimos de Produção (CMP) da BCI, que são de conformidade obrigatória.'
                    }
                  />
                }
              />
            </Div302>
            <Div302 img={rcs} dimension={[156, 156, 140, 140]}>
              <div className={'icon'}>
                <div />
              </div>
              <Accordion
                mini
                id={'cert3'}
                summary={<Text labelAcordeonMini text={'rcs'} />}
                details={
                  <Text
                    paragraph3
                    text={
                      'A Recycled Claim Standard (RCS) é uma certificação usada como padrão de cadeia de custódia para ' +
                      'rastrear a cadeia de suprimentos de matérias-primas recicladas.<br><br>' +
                      'Os produtos certificados pela RCS possuem conteúdo reciclado que foi verificado independentemente ' +
                      'em cada etapa da cadeia de suprimentos, desde a recicladora até o produto acabado.<br><br>' +
                      'Através dessa verificação, a RCS informa a presença e a quantidade de material reciclado em um ' +
                      'produto final. A RCS usa os requisitos da cadeia de custódia do Content Claim Standard.'
                    }
                  />
                }
              />
            </Div302>
          </Col>
        </Row>

        {partners.some((p) => p.partners && p.partners.length) ? (
          <>
            <Row align="center" className={'mt-5'}>
              <Col className={'d-flex my-lg-5 mt-5 mb-4 justify-content-center'}>
                <Text heading2 title={{ one: 'nossos', two: 'parceiros' }} />
              </Col>
            </Row>

            <Row align="center" className={'mb-4 mb-lg-5 pb-lg-3'}>
              <Col className={'no-gutters'}>
                <AccordionPartner groups={partners} />
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  render() {
    const { loadingState, modalState } = this.state;

    return <Screen modalState={modalState} body={this.body} loadingState={loadingState} />;
  }
}

export default AboutUs;
