const { dataFormParse } = require('../utils/dataFormParse');
const { axiosDefault, handleErrors } = require('../utils/defaults');

const newsService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/news/admin/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/news/admin`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (news) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/news/admin`, dataFormParse(news));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (news) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(`/api/news/admin/${news.id}`, dataFormParse(news));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/news/admin/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    notification: async (ids) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.put(`/api/news/admin`, JSON.stringify(ids));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },

  user: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/news/${id}`);

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async ({ limit = '', search = '', random = '' }) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(
          `/api/news?limit=${limit}&title=${search}&random=${random}`,
        );

        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default newsService;
