import { colors } from '../../utils/colors';
import blend from '../../assets/backgrounds/blend-cookie@2x.png';

export const style = {
  backgroundColor: `${colors.primaryTwo}00`,
  backgroundImage: `url(${blend})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundBlendMode: 'darken',
  color: colors.primaryOne,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '28px 53px',
};

export const contentStyle = {
  flex: 'unset',
  maxWidth: 704,
  margin: 0,
  textAlign: 'center',
  fontSize: 16,
  lineHeight: '20px',
};

export const buttonStyle = {
  width: 134,
  height: 33,
  color: colors.white,
  backgroundColor: colors.primaryOne,
  fontSize: 12,
  textTransform: 'uppercase',
  borderRadius: 24,
  margin: '10px 0 2px',
  outline: 'none!important',
};
