import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax } from '../../../utils/devices';

const ImgDefault = styled.div`
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;x
`;

export const Img = styled(ImgDefault)`
 /* width: 392px;
  height: 149px;*/
  width: 784px;
  height: 298px;
  background-position: center;

  @media ${bootstrapMax.sm} {
    width: 243px;
    height: 92px;
  }
`;

export const Icon = styled(ImgDefault)`
  width: 88px;
  height: ${({ mini }) => (mini ? 64 : 76)}px;
  margin: 20px 40px 0;
  background-position: left center;

  @media (max-width: 374px) {
    width: 58px;
    height: 46px;
    margin: 20px 30px 0;
  }
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${bootstrapMax.md} {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const DivCol = styled.div`
  max-width: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${bootstrapMax.md} {
    justify-content: center;
  }
`;

export const Div290 = styled.div`
  width: 310px;
  min-height: 150px;
  height: 320px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  @media (max-width: 374px) {
    width: 100%;
    height: 250px;
  }
`;

export const DivText = styled.div`
  width: 100%;
  min-height: 140px;
  z-index: 1;
  padding: 20px 0 20px 40px;
  font-size: 18px;
  line-height: 28px;
  text-align: left;

  @media ${bootstrapMax.md} {
    padding: 20px 40px;
  }

  @media ${bootstrapMax.sm} {
    padding: 20px 35px;
  }

  @media (max-width: 374px) {
    padding: 15px 30px;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const DivL1 = styled.div`
  width: 185px;
  height: 275px;
  position: absolute;
  bottom: 0;
  border-left: 2px dashed ${colors.primaryTwo};
  border-bottom: 2px dashed ${colors.primaryTwo};
  z-index: 0;

  @media ${bootstrapMax.md} {
    width: 100%;
  }

  @media (max-width: 374px) {
    height: 220px;
  }
`;

export const DivL2 = styled.div`
  width: 165px;
  height: 100%;
  position: absolute;
  border-left: 2px dashed ${colors.primaryTwo};
  z-index: 0;

  @media ${bootstrapMax.md} {
    height: 100%;
    border-left: 2px dashed ${colors.primaryTwo};
  }
`;

export const DivL3 = styled.div`
  width: 123px;
  height: 100%;
  position: absolute;
  right: 0;
  border-top: 2px dashed ${colors.primaryTwo};
  border-right: 2px dashed ${colors.primaryTwo};
  border-bottom: 2px dashed ${colors.primaryTwo};
  z-index: 0;

  @media ${bootstrapMax.md} {
    width: 100%;
    height: 100%;
  }
`;

export const DivL4 = styled.div`
  width: 185px;
  height: 100%;
  position: absolute;
  right: -62px;
  border-right: 2px dashed ${colors.primaryTwo};
  border-bottom: 2px dashed ${colors.primaryTwo};
  z-index: 0;

  @media ${bootstrapMax.md} {
    width: 100%;
    height: 100%;
    right: unset;
    border-right: unset;
    border-left: 2px dashed ${colors.primaryTwo};
    border-bottom: 2px dashed ${colors.primaryTwo};
  }
`;

export const DivL0 = styled.div`
  position: absolute;
  z-index: 0;

  @media ${bootstrapMax.md} {
    width: 100%;
    height: ${({ last }) => (last ? '275px' : '100%')};
    top: 0;
    right: 0;
    border-right: 2px dashed ${colors.primaryTwo};
    ${({ last }) => (!last ? `border-bottom: 2px dashed ${colors.primaryTwo};` : '')}
  }
`;
