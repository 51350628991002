import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax, bootstrapMin } from '../../../utils/devices';

const IconDefault = styled.div`
  min-width: 57px;
  min-height: 55px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;

  @media ${bootstrapMax.lg} {
    min-width: 40px;
    min-height: 40px;
  }
`;

export const Icon = styled(IconDefault)`
  margin-bottom: 20px;

  @media ${bootstrapMax.lg} {
    margin: 0 13px 20px 0;
  }
`;

export const IconFrame = styled(IconDefault)`
  background-position: center;
  height: 128px;

  @media ${bootstrapMax.lg} {
    height: 106px;
  }
`;

export const Frame = styled.div`
  background-color: ${colors.primaryOne};
  padding: 46px 62px;
  border-radius: 46px;

  @media ${bootstrapMin.xs} {
    width: 100vw;
    margin-left: -35px;
    padding: 30px 36px;
  }
`;
