import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { withScriptjs } from 'react-google-maps';
import { defaultStates } from '../../utils/defaults';
import { getCurrentPosition } from '../../utils/location';
import { Input, Flex } from '../Input/style';
import { Label } from '../index';
const { compose, lifecycle, withState, withHandlers } = require('recompose');

class SearchMap extends Component {
  constructor(props) {
    super(props);
    this.state = { address: props ? props.value : '', ...defaultStates() };
  }

  getMyLocation = async () => {
    let location = null;
    let latitude = null;
    let longitude = null;

    if (this.state.app) {
      await getCurrentPosition(this);
    } else if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation;

      const setLocation = (lat, lng) => {
        this.setLocation(lat, lng);
      };

      if (location) {
        location.getCurrentPosition(function (position) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
          setLocation(latitude, longitude);
        });
      }
    }
  };

  setLocation(lat, lng, zoom = 12) {
    this.setState({ lat, lng, zoom });
  }

  async componentDidMount() {
    await this.getMyLocation();
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    const { label, value, onChange, onSelect, onClick } = this.props;

    const PlacesWithStandaloneSearchBox = compose(
      withState('value', 'onValueChange', value),
      withHandlers(() => {
        return {
          onValueChanged:
            ({ onValueChange }) =>
            (e) => {
              onChange(e);
              onValueChange(e.target.value);
            },
        };
      }),
      lifecycle({
        componentDidMount() {
          const refs = {};
          this.setState({
            places: [],
            onSearchBoxMounted: (ref) => {
              refs.searchBox = ref;
            },
            onPlacesChanged: () => {
              const places = refs.searchBox.getPlaces();
              this.setState({
                places,
              });
              const {
                place_id,
                formatted_address,
                geometry: { location },
              } = places[0];
              onSelect({ place_id, formatted_address, geometry: { location } });
            },
          });
        },
      }),
      withScriptjs,
    )((props) => {
      return (
        <div data-standalone-searchbox="">
          <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={(e) => {
              props.onPlacesChanged(e);
              props.onValueChanged({ target: { value: this.props.value } });
            }}>
            <Flex>
              <Label text={label} />
              <Input
                type="text"
                placeholder={'Endereço'}
                onChange={(e) => {
                  props.onValueChanged(e);
                }}
                onBlur={onClick}
                value={props.value}
              />
            </Flex>
          </StandaloneSearchBox>
        </div>
      );
    });

    const key = process.env.REACT_APP_GOOGLE;

    return (
      <PlacesWithStandaloneSearchBox
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places&region=BR`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `443px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }
}

SearchMap.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
};

export default SearchMap;
