import React, { Component } from 'react';
import { Social, SocialMedia, Text } from '../index';
import { Col, Footer, Logo } from './styles';
import { Row } from 'react-bootstrap';
import { defaultStates } from '../../utils/defaults';

import logo from '../../assets/logos/logo@2x.png';

class FooterApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      alerts: null,
      ...defaultStates(),
    };
  }

  componentDidMount() {}

  render() {
    const { app } = this.state;
    // const app = 1;

    return (
      <Footer>
        <div>
          <Row>
            <Col>
              <Logo to={'/'} app={app} img={logo}>
                <div />
              </Logo>
            </Col>
          </Row>
          <Row>
            <Col className={'d-none d-lg-flex d-xl-flex'}>
              <Text linkApp href={'/termos-uso'} footer text={'Termos de uso'} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SocialMedia footer />
            </Col>
          </Row>
          <Row>
            <Col>
              <Social footer>
                <SocialMedia footer linkedin />
                <SocialMedia footer instagram />
              </Social>
            </Col>
          </Row>
        </div>
      </Footer>
    );
  }
}

export default FooterApp;
