const { axiosDefault, handleErrors } = require('../utils/defaults');
const { dataFormParse } = require('../utils/dataFormParse');

const fileService = {
  upload: async (images) => {
    try {
      const axios = axiosDefault('file');

      const { data } = await axios.post(`/api/file`, dataFormParse(images));
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default fileService;
