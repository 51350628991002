import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Card, CarouselComponent, Screen, Text } from '../../../components';
import newsService from '../../../services/news';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { baseURL } from '../../../utils/defaults';
import { Content } from './styles';

const { defaultStates, defaultModalOnHide } = require('../../../utils/defaults');

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: {
        title: '',
        title2: '',
        describe: '',
        content: '',
        files: [],
      },
      list: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  async componentDidMount() {
    await this.loadNews();
  }

  async componentDidUpdate() {
    const { id } = this.props.match.params;
    const { loadingState } = this.state;
    const { id: newsId } = this.state.news;

    if (!loadingState && newsId && newsId !== Number(id)) {
      await this.loadNews();
    }
  }

  async loadNews() {
    this.setState({ loadingState: true });

    const { id } = this.props.match.params;

    const response = await newsService.user.get(id);
    if (response.ok && response.data && response.data) {
      this.setState({
        news: response.data,
      });
    } else if (response.ok) {
      this.props.history.push('/');
    } else {
      const modalState = {
        title: 'Oops!',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    const list = await newsService.user.getList({ limit: 3, random: true });
    if (list.ok && list.data && list.data) {
      this.setState({
        list: list.data,
      });
    }

    this.setState({ loadingState: false });
  }

  body() {
    const { news, list } = this.state;

    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 600, itemsToShow: 2, itemsToScroll: 2 },
      { width: 910, itemsToShow: 3, itemsToScroll: 2 },
    ];

    const partnerItems = list.map((n) => (
      <Card
        key={n.id}
        id={n.id}
        {...this.props}
        title={n.titleComplete}
        description={n.describe}
        image={n.file && n.file.id ? `${baseURL()}/api/file/${n.file.id}/${n.file.slug}` : ''}
        less
      />
    ));

    return (
      <>
        <Row className={'mt-5'}>
          <Col>
            <Text heading3 title={{ one: news.title, two: news.title2 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Content dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.content) }} />
          </Col>
        </Row>
        <Row className={'d-none d-sm-flex mt-5'}>
          <Col>
            <Button back onClick={() => this.props.history.goBack()} />
          </Col>
        </Row>

        <Row className={'mt-4'}>
          <Col align={'center'} className={'mt-4 mt-lg-5'}>
            <Text heading3 title={{ one: 'Mais', two: 'postagens' }} />
          </Col>
        </Row>
        <Row className={'mb-5 pb-1 pb-md-5 mt-3 mt-lg-5'}>
          <CarouselComponent breakPoints={breakPoints} items={partnerItems} />
        </Row>
      </>
    );
  }

  render() {
    const { loadingState, modalState } = this.state;

    return <Screen back body={this.body} loadingState={loadingState} modalState={modalState} />;
  }
}

News.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default News;
