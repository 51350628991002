const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const width = {
  xs: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const bootstrapMin = {
  xs: `(max-width: ${width.xs}px)`,
  sm: `(min-width: ${width.sm}px)`,
  md: `(min-width: ${width.md}px)`,
  lg: `(min-width: ${width.lg}px)`,
  xl: `(min-width: ${width.xl}px)`,
  xxl: `(min-width: ${width.xxl}px)`,
};

export const bootstrapMax = {
  xs: `(max-width: ${width.xs}px)`,
  sm: `(max-width: ${width.sm}px)`,
  md: `(max-width: ${width.md}px)`,
  lg: `(max-width: ${width.lg}px)`,
  xl: `(max-width: ${width.xl}px)`,
  xxl: `(min-width: ${width.xxl}px)`,
};
