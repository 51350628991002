import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { ItemTitle, ItemText, ItemList } from './styles';
import { RadioButton } from '../index';

class ListItemComponent extends Component {
  render() {
    // props
    const { text, subText, checked, onClick } = this.props;

    if (process.env.REACT_APP_WEB === 'false') {
      return (
        <ItemList onClick={onClick} className="pb-0 pt-0">
          <Row>
            <Col xs={10}>
              <ItemTitle white className="text-left">
                {text}
              </ItemTitle>
            </Col>
            <Col xs={2}>
              <RadioButton checked={checked} />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <ItemText white className="text-left">
                {subText}
              </ItemText>
            </Col>
            <Col md={2}></Col>
          </Row>
        </ItemList>
      );
    }

    return (
      <ItemList onClick={onClick} className="pb-0 pt-0">
        <Row>
          <Col xs={10}>
            <ItemTitle className="text-left">{text}</ItemTitle>
          </Col>
          <Col xs={2}>
            <RadioButton checked={checked} />
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <ItemText className="text-left">{subText}</ItemText>
          </Col>
          <Col md={2}></Col>
        </Row>
      </ItemList>
    );
  }
}

ListItemComponent.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
};

export default ListItemComponent;
