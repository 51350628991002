import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Screen, Text, Input } from '../../../components';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

import homeService from '../../../services/home';

const { defaultStates, defaultAlertScreenOnClick } = require('../../../utils/defaults');

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ourImpacts: [],
      ...defaultStates(),
    };

    this.modalOnHide = this.modalOnHide.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.body = this.body.bind(this);
    this.fillFields = this.fillFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addOurImpact = this.addOurImpact.bind(this);
    this.rmOurImpact = this.rmOurImpact.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    await this.fillFields();
  }

  async fillFields() {
    this.setState({ loadingState: true });

    const { data } = await homeService.admin.list();

    this.setState({ ...this.state, ourImpacts: data });

    this.setState({ loadingState: false });
  }

  modalOnHide() {
    const { modalState } = this.state;
    modalState.toggle = false;
    this.setState(modalState);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  handleChange(id, type, value) {
    let { ourImpacts } = this.state;

    const newOurImpatcs = ourImpacts.map((ourImpact) => {
      if (ourImpact.id === id) {
        ourImpact[type] = value;
        return ourImpact;
      }
      return ourImpact;
    });

    this.setState({ ...this.state, ourImpacts: newOurImpatcs });
  }

  addOurImpact = () => {
    const { ourImpacts } = this.state;

    ourImpacts.push({
      new: true,
      id: uuid(),
      value: '',
      text: '',
    });

    this.setState({ ...this.state, ourImpacts });
  };

  rmOurImpact = (id) => {
    const { ourImpacts } = this.state;

    const newArray = ourImpacts
      .filter((ourImpact) => {
        if (ourImpact.id === id && ourImpact.new) {
          return false;
        } else {
          return ourImpact;
        }
      })
      .map((ourImpact) => {
        if (ourImpact.id === id) {
          ourImpact.deleted = true;
          return ourImpact;
        }
        return ourImpact;
      });

    this.setState({ ...this.state, ourImpacts: newArray });
  };

  submit = async () => {
    this.setState({ loadingState: true });

    const { ok, data, message } = await homeService.admin.save(this.state.ourImpacts);

    if (ok && data) {
      const modalState = {
        title: 'Informação',
        text: 'Salvo com sucesso!',
        toggle: true,
        onHide: () => {
          const { modalState } = this.state;
          modalState.toggle = false;
          this.setState(modalState);
          document.location.reload();
        },
      };
      this.setState({ modalState });
    } else {
      const modalState = {
        title: 'Erro',
        text: message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }
    this.setState({ loadingState: false });
  };

  body() {
    const { ourImpacts } = this.state;

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Nosso Impacto'} />
          </Col>
        </Row>
        <Row className={'justify-content-center justify-content-md-end'}>
          <Col xs={10} md={4}>
            <Button text={'Salvar'} onClick={this.submit} />
          </Col>
        </Row>
        {ourImpacts
          .filter((ourImpact) => !ourImpact.deleted)
          .map(({ id, value, text }) => (
            <Row key={id}>
              <Col sm={12} xs={12} md={2}>
                <Input
                  value={value}
                  label={'Valor:'}
                  placeholder={'Valor'}
                  maxLength={11}
                  onChange={(e) => this.handleChange(id, 'value', e.target.value)}
                />
              </Col>
              <Col sm={12} xs={12} md={9}>
                <Input
                  value={text}
                  label={'Texto:'}
                  placeholder={'Texto'}
                  maxLength={100}
                  onChange={(e) => this.handleChange(id, 'text', e.target.value)}
                />
              </Col>
              <Col className={'mt-auto mx-auto'} xs={2} md={1}>
                <Button
                  close
                  onClick={() => {
                    this.rmOurImpact(id);
                  }}
                />
              </Col>
            </Row>
          ))}
        <Row className={'justify-content-center'}>
          <Col xs={10} md={4}>
            <Button text={'Adicionar linha'} onClick={this.addOurImpact} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <Screen
        body={this.body}
        modalState={modalState}
        loadingState={loadingState}
        alertScreenState={alertScreenState}
      />
    );
  }
}

NewsList.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default NewsList;
