import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import partnersService from '../../../services/partners';
import {
  Screen,
  Text,
  Input,
  TextArea,
  ButtonUpload,
  Image,
  Label,
  Button,
  Checkbox,
  DropDown,
} from '../../../components';
import Alert from '@mui/material/Alert';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
  baseURL,
} = require('../../../utils/defaults');

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {
        name: '',
        describe: '',
        link: '',
        partnersGroup_id: null,
        files: [],
        isActive: true,
      },
      ...defaultStates(),
    };

    this.getTypes = this.getTypes.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    this.setState({ loadingState: true });

    await this.getTypes();
    const { id } = this.props.match.params;

    if (id !== 'novo') {
      const response = await partnersService.admin.get(id);
      if (response.ok && response.data && response.data.partner) {
        this.setState({
          partner: response.data.partner,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/admin/parceiros/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async getTypes() {
    this.setState({ loadingState: true });

    const response = await partnersService.admin.getTypes();
    if (response.ok && response.data && response.data.types) {
      this.setState({
        types: response.data.types,
      });
    } else {
      const modalState = {
        title: 'Erro',
        text: response.message,
        toggle: true,
        onHide: this.modalOnHide,
      };
      this.setState({ modalState });
    }

    this.setState({ loadingState: false });
  }

  handleChange(id, event) {
    const { partner } = this.state;

    if (id === 'files') {
      partner[id] = [event.target.files && event.target.files[0]];
    } else if (id === 'removeFile') {
      partner.files = [];
      partner.file_id = null;
    } else if (id === 'partnersGroup_id') {
      partner[id] = event;
    } else {
      partner[id] = event.target.value;
    }

    this.setState({ partner });
  }

  async handleSubmit() {
    const { newRegister, partner } = this.state;
    this.setState({ loadingState: true });

    if (newRegister) {
      const response = await partnersService.admin.save(partner);

      if (response.ok) {
        const alertScreenState = {
          title: 'Parceiro criado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/parceiros',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      const response = await partnersService.admin.edit(partner);

      if (response.ok) {
        const alertScreenState = {
          title: 'Parceiro alterado com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/parceiros',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção do parceiro ${this.state.partner.name}`,
        text: `Tem certeza que deseja excluir esse parceiro?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        partner: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await partnersService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Parceiro excluído com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  body() {
    let { partner, types } = this.state;

    types = types ? types.map((type) => ({ value: type.id, label: type.name })) : [];

    return (
      <>
        <Row align="center">
          <Col>
            <Text title text={'Parceiro'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Ativo'}
              checked={partner.isActive}
              onClick={() => {
                partner.isActive = !partner.isActive;
                this.setState({ partner });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={partner.name}
              label={'Nome:'}
              placeholder={'Nome do parceiro'}
              onChange={(e) => this.handleChange('name', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <TextArea
              rows={4}
              label={'Descrição:'}
              placeholder={'Descrição do parceiro'}
              value={partner.describe}
              onChange={(e) => this.handleChange('describe', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={partner.link}
              label={'Link:'}
              placeholder={'Link do parceiro'}
              onChange={(e) => this.handleChange('link', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <DropDown
              placeholder={'Tipo de parceiro:'}
              label={'Tipo:'}
              value={partner.partnersGroup_id}
              options={types}
              onChange={(e) => this.handleChange('partnersGroup_id', e)}
            />
          </Col>
        </Row>
        <Row>
          {partner.files && partner.files.length ? (
            <Col>
              <Label text={'Logo:'} />
              <Image
                preview
                img={
                  partner.files[0].id
                    ? `${baseURL()}/api/file/${partner.files[0].id}/${partner.files[0].slug}`
                    : URL.createObjectURL(partner.files[0])
                }
                onClick={() => this.handleChange('removeFile')}
              />
            </Col>
          ) : (
            <Col>
              <Label text={'Logo:'} />
              <ButtonUpload onChange={(e) => this.handleChange('files', e)} image />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Alert severity="info" className={'mt-2'}>
              <span>É recomendado que a imagem, tenha o tamanho máximo de:</span>
              <br />
              <span>200px de altura por 170px de largura</span>
            </Alert>
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.partner.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

Partners.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Partners;
