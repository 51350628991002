import styled from 'styled-components';
import { bootstrapMax, bootstrapMin } from '../../utils/devices';
import { Container as ContainerComponent } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;

  @media ${bootstrapMin.xs} {
    padding: 0 20px;
  }
`;

export const ContainerLogin = styled.div`
  &::before {
    height: 100vh;
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const ContainerBodyLogin = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 35px 50px;
  z-index: 1;
  max-width: 1160px;
`;

export const ContainerFooterLogin = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;

  @media ${bootstrapMax.lg} {
    margin-bottom: 35px;
  }
`;

export const ContainerBody = styled(ContainerComponent)``;

export const ContainerHeader = styled(ContainerComponent)`
  min-height: 160px;

  @media ${bootstrapMax.sm} {
    min-height: 110px;
  }
`;

export const ContainerFooter = styled(ContainerComponent)``;
