import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { colors } from '../../utils/colors';

import arrow from '../../assets/icons/arrow-accordion@2x.png';

export const Arrow = styled.div`
  width: ${({ mini }) => (mini ? 9 : 16)}px;
  height: ${({ mini }) => (mini ? 24 : 10)}px;
  display: flex;
  align-items: center;

  div {
    width: ${({ mini }) => (mini ? 9 : 15)}px;
    height: ${({ mini }) => (mini ? 6 : 10)}px;
    background-image: url(${arrow});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
  }
`;

export const Plus = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: .3;
  }
  &:before {
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${colors.primaryOne};
    transform: rotate(90deg);
  }
  &:after {
    margin-left: -2px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${colors.primaryOne};
    transform: rotate(0deg);
  }
}`;

export const Accordion = MuiStyled(MuiAccordion)(({ mini, theme, notOpen }) => ({
  color: `${colors.primaryOne} !important`,
  '&:before': { display: 'none' },
  ...(notOpen ? { '& > .MuiCollapse-root': { height: '0px !important' } } : {}),
  ...(mini
    ? {}
    : {
        backgroundColor: colors.secondaryThree,
        borderRadius: '46px !important',
        padding: '0 77px !important',
        [theme.breakpoints.down('sm')]: {
          padding: '0 30px !important',
        },
      }),
}));

export const AccordionPlus = MuiStyled(MuiAccordion)(({}) => ({
  color: `${colors.primaryOne} !important`,
  padding: '0 !important',
  borderTop: `2px solid ${colors.secondaryTwo}`,
  borderLeft: 0,
  borderRight: 0,
  borderRadius: '0px !important',
  '&:before': { display: 'none' },
}));

export const AccordionSummary = MuiStyled(MuiAccordionSummary)(({ mini, theme, plus }) => ({
  padding: 'unset !important',
  ...(mini
    ? {
        '& .MuiAccordionSummary-root': {
          minHeight: 'unset !important',
        },
        '& .MuiAccordionSummary-content': {
          margin: '0 !important',
          flexGrow: 'unset !important',
          padding: '0 10px 0 0',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          margin: 'unset !important',
        },
      }
    : plus
    ? {
        '&': {
          minHeight: '107px !important',
          padding: '12px 0 0',
          [theme.breakpoints.down('md')]: {
            minHeight: '93px !important',
            padding: '0 0 12px',
          },
        },
        '&.Mui-expanded': {
          minHeight: '93px !important',
          padding: '0 0 12px',
          [theme.breakpoints.down('md')]: {
            minHeight: '57px !important',
          },
        },
        '& .MuiAccordionSummary-content': {
          paddingRight: 30,
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(315deg)',
        },
      }
    : {
        '& .MuiAccordionSummary-content': {
          minHeight: '78px !important',
          margin: '0 !important',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            minHeight: '60px !important',
          },
        },
      }),
}));

export const AccordionDetails = MuiStyled(MuiAccordionDetails)(({ mini, plus }) =>
  mini || plus ? { padding: 'unset !important' } : { padding: '0 0 20px !important' },
);
