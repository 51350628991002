import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input as InputComponent,
  InputTransparent,
  InputBrown,
  BigInputBrown,
  Flex,
  InputSearch,
  InputMargin,
  InputDisabled,
  InputSearchImg,
  InputBlack,
  InputBlackPassword,
  InputSearchBlack,
  InputCloseImg,
  InputSearchWhite,
  Search,
} from './style';
import { Label } from '../index';
import search_icon from './../../assets/icons/search_white@2x-min.png';
import search_icon_blue from './../../assets/icons/search_icon_blue.png';
import close_icon from './../../assets/icons/close_gray-min@2x.png';

class Input extends Component {
  render() {
    //props
    const {
      placeholder,
      label,
      value = '',
      onChange,
      type = 'text',
      onKeyPress,
      onSearch,
      onClickClose,
      maxLength,
    } = this.props;

    //types
    const {
      transparent,
      inputBrown,
      search,
      margin,
      disabled,
      password,
      inputBlack,
      passwordBlack,
      recoveryPassword,
      bigInput,
      searchWhite,
    } = this.props;

    if (disabled) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputComponent
            disabled={true}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (transparent) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputTransparent
            id={type === 'email' && 'email'}
            type={type}
            value={value}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (inputBrown) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputBrown
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (search) {
      return (
        <Flex>
          <InputSearchBlack
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={(e) => {
              e.key === 'Enter' && onSearch();
            }}
            //onBlur={onSearch}
            onChange={onChange}
            maxLength={maxLength}
          />
          <InputSearchImg src={search_icon} onClick={onSearch} />
          <InputCloseImg src={close_icon} onClick={onClickClose} />
        </Flex>
      );
    }

    if (searchWhite) {
      return (
        <Search>
          <InputSearchImg img={search_icon_blue} />
          <InputSearchWhite
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyPress={onKeyPress}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Search>
      );
    }

    if (margin) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputMargin
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (password) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputComponent
            placeholder={placeholder || 'Senha'}
            value={value}
            type={'password'}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (recoveryPassword) {
      return (
        <Flex>
          {label && <Label whiteLabel text={label} />}
          <InputTransparent
            placeholder={placeholder || 'Senha'}
            value={value}
            type={'password'}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (inputBlack) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputBlack
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (passwordBlack) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <InputBlackPassword
            placeholder={placeholder || 'Senha'}
            value={value}
            type={'password'}
            onChange={onChange}
            app
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    if (bigInput) {
      return (
        <Flex>
          {label && <Label text={label} />}
          <BigInputBrown
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
          />
        </Flex>
      );
    }

    return (
      <Flex>
        {label && <Label text={label} />}
        <InputComponent
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
      </Flex>
    );
  }
}

Input.propTypes = {
  //props
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string,
  onKeyPress: PropTypes.func,
  onClickClose: PropTypes.func,
  maxLength: PropTypes.number,

  //types

  inputBrown: PropTypes.bool,
  transparent: PropTypes.bool,
  search: PropTypes.bool,
  margin: PropTypes.bool,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  inputBlack: PropTypes.bool,
  passwordBlack: PropTypes.bool,
  recoveryPassword: PropTypes.bool,
  bigInput: PropTypes.bool,
  searchWhite: PropTypes.bool,
};

export default Input;
