import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Social as Container } from './styles';

class Social extends Component {
  render() {
    const { children, footer } = this.props;
    return <Container footer={footer}>{children}</Container>;
  }
}

Social.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.bool,
};

export default Social;
