import { Geolocation } from '@capacitor/geolocation';

const getCurrentPosition = async (state) => {
  let permission = await Geolocation.checkPermissions();

  if (permission.location.includes('prompt')) permission = await Geolocation.requestPermissions();

  if (permission.location === 'granted') {
    const coordinates = await Geolocation.getCurrentPosition();

    state.setState({
      lat: coordinates.coords.latitude,
      lng: coordinates.coords.longitude,
      zoom: 12,
    });
  }
};

export { getCurrentPosition };
