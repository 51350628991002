import cambos from '../assets/imgs/partners/cambos@2x.png';
import canal from '../assets/imgs/partners/canal_concept@2x.png';
import dalila from '../assets/imgs/partners/dalila@2x.png';
import rastra from '../assets/imgs/partners/rastra@2x.png';
import renner from '../assets/imgs/partners/renner@2x.png';
import retalhar from '../assets/imgs/partners/retalhar@2x.png';

export const groupsPartners = [
  {
    id: 'production',
    summary: 'PRODUTORES E ASSOCIAÇÕES',
    partners: [
      {
        icon: cambos,
        text:
          '<strong>Souza e Cambos</strong><br>' +
          'Há 20 anos no mercado de confecções, a empresa Souza e Cambos conta com um ' +
          'processo de produção de uniformes profissionais e outros modelos de vestuário, onde produzem ' +
          'do tecido, até o produto final.<br><br>' +
          'Seu processo de produção conta com os setores de engomagem, tecelagem, linha, corte, costura, ' +
          'lavanderia, acabamento e expedição. Possuem dois centros de distribuição situados na região do ' +
          'Brás, na cidade de São Paulo.',
        href: 'souzacambos.com.br',
      },
      {
        icon: canal,
        text:
          '<strong>Souza e Cambos</strong><br>' +
          'Há 20 anos no mercado de confecções, a empresa Souza e Cambos conta com um ' +
          'processo de produção de uniformes profissionais e outros modelos de vestuário, onde produzem ' +
          'do tecido, até o produto final.<br><br>' +
          'Seu processo de produção conta com os setores de engomagem, tecelagem, linha, corte, costura, ' +
          'lavanderia, acabamento e expedição. Possuem dois centros de distribuição situados na região do ' +
          'Brás, na cidade de São Paulo.',
        href: 'souzacambos.com.br',
      },
      {
        icon: dalila,
        text:
          '<strong>Souza e Cambos</strong><br>' +
          'Há 20 anos no mercado de confecções, a empresa Souza e Cambos conta com um ' +
          'processo de produção de uniformes profissionais e outros modelos de vestuário, onde produzem ' +
          'do tecido, até o produto final.<br><br>' +
          'Seu processo de produção conta com os setores de engomagem, tecelagem, linha, corte, costura, ' +
          'lavanderia, acabamento e expedição. Possuem dois centros de distribuição situados na região do ' +
          'Brás, na cidade de São Paulo.',
        href: 'souzacambos.com.br',
      },
      {
        icon: rastra,
        text: '<strong>Retalahar</strong><br>' + 'Lorem Ypsum',
        href: 'retalhar.com.br',
      },
      {
        icon: renner,
        text:
          '<strong>Souza e Cambos</strong><br>' +
          'Há 20 anos no mercado de confecções, a empresa Souza e Cambos conta com um ' +
          'processo de produção de uniformes profissionais e outros modelos de vestuário, onde produzem ' +
          'do tecido, até o produto final.<br><br>' +
          'Seu processo de produção conta com os setores de engomagem, tecelagem, linha, corte, costura, ' +
          'lavanderia, acabamento e expedição. Possuem dois centros de distribuição situados na região do ' +
          'Brás, na cidade de São Paulo.',
        href: 'souzacambos.com.br',
      },
      {
        icon: retalhar,
        text:
          '<strong>Souza e Cambos</strong><br>' +
          'Há 20 anos no mercado de confecções, a empresa Souza e Cambos conta com um ' +
          'processo de produção de uniformes profissionais e outros modelos de vestuário, onde produzem ' +
          'do tecido, até o produto final.<br><br>' +
          'Seu processo de produção conta com os setores de engomagem, tecelagem, linha, corte, costura, ' +
          'lavanderia, acabamento e expedição. Possuem dois centros de distribuição situados na região do ' +
          'Brás, na cidade de São Paulo.',
        href: 'souzacambos.com.br',
      },
    ],
  },
  {
    id: 'manufacture',
    summary: 'MANUFATURA REVERSA',
    partners: [],
  },
  {
    id: 'industry',
    summary: 'INDÚSTRIA',
    partners: [],
  },
  {
    id: 'business',
    summary: 'COMÉRCIO E VAREJO',
    partners: [],
  },
  {
    id: 'consumers',
    summary: 'CONSUMIDORES',
    partners: [],
  },
];

export const typePoints = [
  {
    value: 'discard',
    label: 'Ponto de Descarte',
  },
  {
    value: 'newProducts',
    label: 'Novos Produtos',
  },
  {
    value: 'discardNNewProducts',
    label: 'Ponto de Descarte e Novos Produtos',
  },
];

export const faqs = [
  {
    summary: '1 - Qual tipo de roupa eu posso levar aos Pontos de Descarte?',
    details:
      'Peças de roupa que contenham fibras de algodão. Consulte a etiqueta de composição para se certificar, antes de descartar a peça. Dê preferência ao descarte de peças em final de ciclo de uso, e que estejam limpas.',
  },
  {
    summary: '2 - Qual tipo de roupa eu não posso levar aos Pontos de Descarte?',
    details:
      'Roupas 100% sintéticas, biquinis, toalhas e lençóis. Quanto ao recebimento de sapatos e peças de moda íntima - como, calcinhas, cuecas e sutiãs, pedimos que a confirmação de recebimento seja feita no local, pois as regras para esses itens podem variar.',
  },
  {
    summary: '3 -Posso levar retalhos ou demais resíduos têxteis aos Pontos de Descarte?',
    details:
      'Não. O gerenciamento de retalhos e outros resíduos têxteis não é parte do escopo de manufatura da Plataforma Circular.',
  },
  {
    summary: '4 - Todas as peças descartadas terão suas fibras recicladas?',
    details:
      'Não necessariamente, pois a reciclagem depende da composição, construção do tecido e nível de desgaste da peça. Além da reciclagem, as peças poderão ter as seguintes destinações: upcycling autoral, doação ou revenda, e aproveitamento energético. Nossos processos são "aterro zero".',
  },
  {
    summary:
      '5 - O encaminhamento da roupa descartada segue as conformidades da Política Nacional de Resíduos Sólidos (PNRS)?',
    details:
      'Sim. Nossos processos seguem rigorosamente as indicações da PNRS. Utilizamos manifestos, certificados de destino e demais documentos fiscais obrigatórios. Além disso, contribuímos diretamente para outros tópicos da política, como "estimular o desenvolvimento de mercado, a produção e o consumo de produtos derivados de materiais reciclados e recicláveis" (Artigo 30, tópico V); assim como, por exemplo, "promover a inserção de práticas de sustentabilidade nas operações do setor varejista e ampliar seu papel na promoção do consumo sustentável" (Artigo 4.1.2, estratégia 1 - documento de revisão da PNRS, 2012).',
  },
  {
    summary: '6 - O descarte das peças me gera algum benefício?',
    details:
      'Sim! Benefícios socioambientais para você, sua família e gerações futuras. Ao ter sua peça reaproveitada, serão economizados recursos como a matéria-prima do algodão, água e energia. Além disso, serão evitadas emissões de CO2 e mais acúmulo de lixo têxtil em aterros e lixões, ou a queima em locais inadequados.\n',
  },
  {
    summary:
      '7 - O que fazer se eu chegar no ponto de coleta e, por algum motivo, o local não puder receber meu descarte, dentro dos critérios indicados?',
    details:
      'Estamos em contato constante com as empresas parceiras que possuem pontos de coleta cadastrados na plataforma, portanto se isso aconteceu com você, sentimos pelo transtorno! Pedimos que você escreva para comunicacao@cottonmove.com.br relatando o ocorrido, e te auxiliaremos a realizar o descarte.',
  },
  {
    summary:
      '8 - Possuo um estabelecimento institucional, educacional ou comercial ligado à indústria de Moda, e gostaria de cadastrá-lo como ponto de coleta na plataforma. É possível?',
    details:
      'Agradecemos o seu interesse em nos auxiliar nesse movimento tão importante! Pedimos que escreva para comunicacao@cottonmove.com.br, enviando endereço e descritivo de sua iniciativa, para que possamos avaliar a proposta.',
  },
];

export const typePlatform = {
  industry: {
    title: 'INDÚSTRIA',
    description:
      'Buscamos continuamente processos industriais têxteis mais limpos e responsáveis. ' +
      'Investimos em melhores soluções socioambientais e alcançamos certificações como Recycled Claim Standard (RCS) ' +
      'e Abvtex, por meio de um esforço coletivo para que todos estejam alinhados.',
  },
  store: {
    title: 'LOJAS',
    description:
      'As lojas físicas de nossas empresas parceiras têm um papel muito importante nesse ciclo. ' +
      'É lá que os pontos de coleta se encontram, e onde você pode adquirir novos produtos com conteúdo reciclado!',
  },
  user: {
    title: 'USUÁRIO',
    description:
      'Você é parte essencial para que possamos fechar esse ciclo! Descarte sua roupa de algodão em fim de ' +
      'ciclo de uso em um dos pontos de nossos parceiros. Esteja certo de que sua peça está limpa. E siga com a gente, ' +
      'acompanhando os posts na seção Fique por Dentro.',
  },
  point: {
    title: 'PONTO DE COLETA',
    description:
      'Acesse o mapa no botão "Descarte Aqui" e identifique o ponto de Coleta de peças ' +
      'mais próximo de você. Utilize também os filtros para localizar pontos que vendem novos produtos com fibras recicladas.',
  },
  manufacture: {
    title: 'MANUFATURA REVERSA',
    description:
      'Conectando parceiros, estruturamos esse sistema que possibilita que os produtos de descarte sejam ' +
      'retirados nos pontos de coleta. A partir dali eles serão identificados, qualificados e destinados para ' +
      'reaproveitamento das fibras, doação, e em último caso, co-processamento para a geração de energia.',
  },
  productor: {
    title: 'PRODUTOR',
    description:
      'Após a recuperação das fibras do algodão, é necessária a junção desta, com uma fibra nova. ' +
      'Escolhemos adicionar apenas fibras de algodão certificado de produtores parceiros. Todos possuem a ' +
      'certificação da Better Cotton Initiative, que garante práticas trabalhistas regularizadas, e uso controlado de agroquímicos.',
  },
};
