import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { device } from '../../utils/devices';

// Navbar CSS
export const Nav = styled.nav`
  height: calc(2rem + 30px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.primaryOne};
  align-items: center;
  position: fixed;
  z-index: 2;

  @media ${device.tablet} {
    width: 100%;
    display: none;
  }

  span {
    font-size: 30px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
      :nth-child(2) {
        font-size: 16px !important;
        margin-top: 0px !important;
      }
    }
  }
`;

// Links CSS
export const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  width: 85%;
  top: 0;
  justify-content: flex-end;
  margin-top: 0px;
  align-items: left;
  font-size: 18px;
  height: 60px;
  z-index: 3;

  a {
    text-decoration: none;
    text-transform: none;
    color: ${colors.white};
    margin-left: -15px;
  }

  li {
    padding: 18px 20px;
    &:hover {
      color: ${colors.white};
    }
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const Logo = styled.img`
  margin: 20px 90px 20px 7%;
  width: 160px;

  @media ${device.laptop} {
    margin: 20px 0 20px 0;
  }
`;
