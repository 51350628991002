import styled from 'styled-components';
import { bootstrapMax } from '../../../utils/devices';

export const Icon = styled.div`
  min-width: 28px;
  min-height: 28px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 15px 0 0;

  @media ${bootstrapMax.lg} {
    margin: 0 13px 0 0;
  }
`;
