import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text, Accordion } from '../../../components';
import { faqs } from '../../../enums';
import { Container } from './styles';
const { defaultStates } = require('../../../utils/defaults');

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
  }

  body() {
    return (
      <Container>
        <Row align="center" className={'mb-4 pb-3 mt-5 mt-md-0'}>
          <Col>
            <Text heading1 title={{ one: 'faq' }} />
          </Col>
        </Row>
        {faqs.map((faq, index) => {
          return (
            <Row key={index} className={''}>
              <Col>
                <Accordion
                  id={index.toString()}
                  plus
                  summary={<Text labelAcordeonFaq text={faq.summary} />}
                  details={<Text paragraph3 text={faq.details} />}
                />
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

export default Faq;
