import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Social, SocialMedia, Label, NavButton, Text, Button } from '../index';
import {
  Nav,
  StyledBurger,
  Ul,
  ScrollList,
  Logo,
  LogoMenu,
  Icon,
  Close,
  MenuFooter,
  MenuItem,
  MenuHeader,
  Header,
} from './styles';
import { NavLink } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { defaultStates } from '../../utils/defaults';

import hamburger from '../../assets/icons/menu/menu-icon.png';
import logo from '../../assets/logos/logo@2x.png';
import logoWhite from '../../assets/logos/logo-white@2x.png';
import home_icon from '../../assets/icons/menu/menu-home-icon@2x.png';
import economy from '../../assets/icons/menu/menu-economy-icon@2x.png';
import discard from '../../assets/icons/menu/menu-discard-icon@2x.png';
import aboutUs from '../../assets/icons/menu/menu-aboutUs-icon@2x.png';
import platform from '../../assets/icons/menu/menu-platform-icon@2x.png';
import traceability from '../../assets/icons/menu/menu-traceability-icon@2x.png';
import news from '../../assets/icons/menu/menu-news-icon@2x.png';
import contact from '../../assets/icons/menu/menu-contact-icon@2x.png';
import faq from '../../assets/icons/menu/menu-faq-icon@2x.png';
import close from '../../assets/icons/menu/menu-close-icon@2x.png';

class NavbarApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
      alerts: null,
      ...defaultStates(),
    };
  }

  componentDidMount() {}

  render() {
    const { back } = this.props;

    return (
      <>
        <Nav>
          <Header className={'header'}>
            <Row className={'align-items-center'}>
              <Col
                xs={{ span: 6, order: 2 }}
                sm={{ span: 6, order: 2 }}
                lg={{ span: 3, order: 1 }}
                className={
                  'd-flex justify-content-center justify-content-lg-start justify-content-xl-start'
                }>
                <Logo img={logo} href={'/'}>
                  <div />
                </Logo>
              </Col>

              <Col
                lg={{ span: 2, order: 2 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/quem-somos'}>
                  <Label header text={' quem somos'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 3 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/plataforma'}>
                  <Label header text={'sobre a plataforma'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 4 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavLink to={'/fique-por-dentro'}>
                  <Label header text={'fique por dentro'} />
                </NavLink>
              </Col>
              <Col
                lg={{ span: 2, order: 5 }}
                className={'d-none d-lg-flex d-xl-flex justify-content-center'}>
                <NavButton header to={'/descarte-aqui'} text={'descarte aqui'} />
              </Col>

              <Col
                xs={{ span: 3, order: 1 }}
                sm={{ span: 3, order: 1 }}
                lg={{ span: 1, offset: 0, order: 5 }}
                className={`d-${
                  back ? 'none' : 'flex'
                } d-sm-flex justify-content-start justify-content-lg-end justify-content-xl-end`}>
                <StyledBurger
                  open={this.state.navbar}
                  img={hamburger}
                  onClick={() => {
                    this.setState({ navbar: !this.state.navbar });
                  }}>
                  <div />
                </StyledBurger>
              </Col>

              <Col
                xs={{ span: 3, order: 1 }}
                sm={{ span: 3, order: 1 }}
                lg={{ span: 1, offset: 0, order: 5 }}
                className={`d-${
                  back ? 'flex' : 'none'
                } d-sm-none justify-content-start justify-content-lg-end justify-content-xl-end align-items-center`}>
                <Button backApp to={'/fique-por-dentro'} />
              </Col>
            </Row>
          </Header>
        </Nav>

        <Modal
          className={'d-none'}
          style={{ zIndex: 1 }}
          show={this.state.navbar}
          onHide={() => {
            this.setState({ navbar: !this.state.navbar });
          }}
        />

        <Ul open={this.state.navbar}>
          <MenuHeader key={0} className="d-flex align-items-center justify-content-between">
            <LogoMenu src={logoWhite} alt="cotton move logotipo" />
            <Close img={close} onClick={() => this.setState({ navbar: false })} />
          </MenuHeader>
          <ScrollList>
            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={1}
              to="/">
              <MenuItem className="d-flex align-items-center">
                <Icon img={home_icon} />
                <Label menu text={'Home'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={2}
              className="d-flex d-xl-none d-lg-none"
              to="/descarte-aqui">
              <MenuItem className="d-flex align-items-center">
                <Icon img={discard} />
                <Label menu text={'Descarte Aqui'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={3}
              className="d-flex d-xl-none d-lg-none"
              to="/quem-somos">
              <MenuItem className="d-flex align-items-center">
                <Icon img={aboutUs} />
                <Label menu text={'Quem Somos'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={4}
              className="d-flex d-xl-none d-lg-none"
              to="/plataforma">
              <MenuItem className="d-flex align-items-center">
                <Icon img={platform} />
                <Label menu text={'Sobre a Plataforma'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={5}
              to="/economia-circular">
              <MenuItem className="d-flex align-items-center">
                <Icon img={economy} />
                <Label menu text={'Economia Circular'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={6}
              to="/rastreabilidade">
              <MenuItem className="d-flex align-items-center">
                <Icon img={traceability} />
                <Label menu text={'Rastreabilidade'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={7}
              className="d-flex d-lg-none"
              to="/fique-por-dentro">
              <MenuItem className="d-flex align-items-center">
                <Icon img={news} />
                <Label menu text={'Fique por Dentro'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={8}
              to="/contato">
              <MenuItem className="d-flex">
                <Icon img={contact} />
                <Label menu text={'Contato'} />
              </MenuItem>
            </NavLink>

            <NavLink
              onClick={() => {
                this.setState({ navbar: !this.state.navbar });
              }}
              key={9}
              to="/faq">
              <MenuItem className="d-flex align-items-center">
                <Icon img={faq} />
                <Label menu text={'FAQ'} />
              </MenuItem>
            </NavLink>

            <MenuFooter
              key={90}
              className="d-flex d-xl-none d-lg-none align-items-start justify-content-start footer">
              <SocialMedia menu />
              <Social>
                <SocialMedia linkedin white key={91} />
                <SocialMedia instagram white key={92} />
              </Social>
              <NavLink
                onClick={() => {
                  this.setState({ navbar: !this.state.navbar });
                }}
                key={99}
                to="/termos-uso">
                <Text menu text={'Termos de uso'} />
              </NavLink>
            </MenuFooter>
          </ScrollList>
        </Ul>
      </>
    );
  }
}

NavbarApp.propTypes = { back: PropTypes.bool };

export default NavbarApp;
