import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text } from '../../../components';
import {
  Img,
  DivRow,
  DivCol,
  Div290,
  DivL0,
  DivL1,
  DivL2,
  DivL3,
  DivL4,
  Icon,
  DivText,
} from './styles';

//import logo from '../../../assets/imgs/traceability/rastra_cotton_move@2x.png';
import logo from '../../../assets/imgs/traceability/quipo.jpg';
import cotton from '../../../assets/imgs/traceability/cotton@2x.png';
import roller from '../../../assets/imgs/traceability/lineroll@2x.png';
import workers from '../../../assets/imgs/traceability/workers@2x.png';
import recycle from '../../../assets/imgs/traceability/recycle@2x.png';
import tag from '../../../assets/imgs/traceability/tag@2x.png';
import country from '../../../assets/imgs/traceability/country@2x.png';

const { defaultStates } = require('../../../utils/defaults');

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
  }

  body() {
    return (
      <>
        <Row align="center" className={'mb-4 mb-lg-5 mt-5 mt-lg-5'}>
          <Col>
            <Text heading1 title={{ one: 'sobre a', two: 'rastreabilidade' }} />
          </Col>
        </Row>

        <Row align="center">
          <Col>
            <Text
              paragraph2
              text={
                'Por meio da parceria com a  <a href="https://www.quipo.io/" target="_blank">Quipo Tech</a>, iremos oferecer aos nossos parceiros ' +
                'um sistema de rastreabilidade para processos circulares, onde fluxos de materiais serão mapeados ' +
                'nas etapas de pré e pós-consumo, e sucessivamente conforme os materiais seguirem nos ciclos.'
              }
            />
          </Col>
        </Row>
        <Row align="center" className={'mb-4'}>
          <Col className={'d-flex justify-content-center'}>
            <a href={'https://www.quipo.io/'} target={'_blank'} rel="noreferrer">
              <Img img={logo} />
            </a>
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Text
              paragraph2
              text={
                'Utilizaremos a tecnologia blockchain e ferramentas de inteligência artificial, para registrar ' +
                'dados relativos, por exemplo, a materiais coletados, materiais utilizados, atributos de ' +
                'sustentabilidade, certificações e atores envolvidos.<br><br>' +
                'E com a ajuda de QR Codes, iremos promover a transparência e credibilidade, informando empresas ' +
                'e consumidores sobre o ciclo de vida dos novos produtos. Aguardem para mais novidades.'
              }
            />
          </Col>
        </Row>

        <Row align="center" className={'mt-4 mt-lg-5'}>
          <Col>
            <Text heading2 title={{ one: 'algodão no', two: 'brasil' }} />
          </Col>
        </Row>
        <Row align="center" className={'pb-4 pb-lg-5'}>
          <Col>
            <DivCol>
              <div>
                <Div290>
                  <DivL1 />
                  <Icon img={cotton} />
                  <DivText className={'bold'}>
                    Matéria-prima natural e biodegradável obtida em solo nacional.
                  </DivText>
                </Div290>
              </div>
              <DivRow>
                <Div290>
                  <DivL0 />
                  <Icon img={roller} />
                  <DivText className={'bold'}>
                    Cadeia produtiva completa, entre cultivo, descaroçamento, fiação, tecelagem,
                    confecção e comercialização.
                  </DivText>
                </Div290>
                <Div290>
                  <DivL2 />
                  <Icon img={workers} />
                  <DivText className={'bold'}>
                    Uma das maiores fontes geradoras de emprego no país.
                  </DivText>
                </Div290>
              </DivRow>
              <div>
                <Div290>
                  <DivL3 />
                  <Icon img={recycle} />
                  <DivText className={'bold'}>Fibra com alto índice de reciclabilidade.</DivText>
                </Div290>
              </div>
              <DivRow>
                <Div290>
                  <DivL4 />
                  <Icon img={tag} />
                  <DivText className={'bold'}>
                    País é o maior produtor global de algodão certificado BCI/ ABR, responsável por
                    37% do total (dado Textile Exchange, 2021).
                  </DivText>
                </Div290>
                <Div290>
                  <DivL0 last />
                  <Icon img={country} mini />
                  <DivText className={'bold'}>
                    País é umas das referências globais em produção orgânica e agroecológica.
                  </DivText>
                </Div290>
              </DivRow>
            </DivCol>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

export default AboutUs;
