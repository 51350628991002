import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '../index';
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter,
  CustomModalBodyApp,
  CustomModalFooterApp,
  Img,
  Plus,
} from './styles';

class ModalComponent extends Component {
  render() {
    //props
    const {
      toggle,
      title,
      text,
      warning,
      cancel,
      confirm,
      onClickConfirm,
      onClickCancel,
      onHide,
      msg,
      url,
    } = this.props;

    //types
    const { versionOutdated, home, image, imageSize, danger } = this.props;

    if (versionOutdated) {
      return (
        <CustomModal show={toggle} size="lg" centered onHide={onHide}>
          <CustomModalBodyApp>
            <div style={{ paddingTop: '1em' }}>
              <Text text={title} modalTitleWhite />
              {text ? <Text subTitleModal text={text} /> : ''}
            </div>
          </CustomModalBodyApp>
          <CustomModalFooterApp>
            <div>
              <Button
                underline
                text={msg}
                onClick={() => {
                  window.open(url);
                }}
              />
            </div>
          </CustomModalFooterApp>
        </CustomModal>
      );
    }

    if (home) {
      return (
        <CustomModal show={toggle} size="lg" centered onHide={onHide}>
          <div className={'align-self-end'} style={{ height: 40 }}>
            <Plus onClick={onHide} />
          </div>
          <CustomModalBodyApp>
            <div>
              <Img src={image} position={'center'} size={imageSize} />
            </div>
            <Text modalTitle text={title} />
            {text ? <Text modalContent text={text} /> : ''}
          </CustomModalBodyApp>
        </CustomModal>
      );
    }

    return (
      <CustomModal show={toggle} size="lg" centered onHide={onHide}>
        <CustomModalHeader closeButton />
        <CustomModalBody>
          <Text text={title} modalTitle />
          {text ? <Text subTitleModal text={text} /> : ''}
          {warning ? <Text strong text={warning} /> : ''}
        </CustomModalBody>
        <CustomModalFooter>
          {cancel && (
            <div className={'m-3'} style={{ width: '40%' }}>
              <Button text={cancel} onClick={onClickCancel} />
            </div>
          )}
          {confirm && (
            <div className={'m-3'} style={{ width: '40%' }}>
              <Button danger={danger} text={confirm} onClick={onClickConfirm} />
            </div>
          )}
        </CustomModalFooter>
      </CustomModal>
    );
  }
}

ModalComponent.propTypes = {
  // props
  title: PropTypes.string,
  text: PropTypes.string,
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  warning: PropTypes.string,
  toggle: PropTypes.bool,
  onClickConfirm: PropTypes.func,
  onClickCancel: PropTypes.func,
  onHide: PropTypes.func,
  listItems: PropTypes.func,
  msg: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  imageSize: PropTypes.number,

  // types
  home: PropTypes.bool,
  versionOutdated: PropTypes.bool,
  danger: PropTypes.bool,
};

export default ModalComponent;
