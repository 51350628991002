import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { bootstrapMax } from '../../../utils/devices';

import arrow from '../../../assets/icons/arrow-accordion@2x.png';

export const Img = styled.div`
  width: 290px;
  height: 290px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media ${bootstrapMax.lg} {
    width: 260px;
    height: 260px;
  }
`;

export const Icon = styled.div`
  min-width: 57px;
  min-height: 55px;
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  margin: 0 25px 0 50px;

  @media ${bootstrapMax.lg} {
    min-width: 40px;
    min-height: 40px;
    margin: 0 13px 0 0;
  }
`;

export const Div302 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 302px;

  > div.icon {
    width: 302px;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: ${({ dimension }) => dimension[0]}px;
      height: ${({ dimension }) => dimension[1]}px;
      background-image: url(${({ img }) => img});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  @media ${bootstrapMax.lg} {
    margin: 0 25px;
  }

  @media ${bootstrapMax.md} {
    width: 100%;
    margin: 20px 0;

    > div.icon {
      div {
        width: ${({ dimension }) => dimension[2]}px;
        height: ${({ dimension }) => dimension[3]}px;
      }
    }
  }
`;

export const Objectives = styled.div`
  max-width: 1000px;

  > p {
    margin: 0 35px;

    @media ${bootstrapMax.sm} {
      margin: unset;
    }
  }
`;
