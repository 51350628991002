import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Social, SocialMedia, Text } from '../../../components';
import { Icon } from './styles';
import email from '../../../assets/icons/social_media/contato.png';
import localization from '../../../assets/icons/map/Pin.png';

const { defaultStates } = require('../../../utils/defaults');

class Contato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
  }

  body() {
    return (
      <>
        <Row align="left" className={'mt-5 mt-md-0'}>
          <Col className={'py-0 pb-3 pb-sm-0'}>
            <Text heading1 title={{ one: 'NOSSO', two: 'CONTATO' }} />
          </Col>
        </Row>
        <Row className={'mt-5 py-0 pb-lg-2'}>
          <Col className={'d-flex align-items-center'}>
            <Icon img={email} />
            <SocialMedia />
          </Col>
        </Row>
        <Row className={'mt-3 mt-lg-4'}>
          <Col className={'d-flex align-items-center'}>
            <Icon img={localization} />
            <Text contact text={'São Paulo, SP, Brasil'} />
          </Col>
        </Row>
        <Row className={'mt-2 mt-lg-4'}>
          <Col>
            <Social footer>
              <SocialMedia footer linkedin />
              <SocialMedia footer instagram />
            </Social>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

export default Contato;
