import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const CustomModal = styled(Modal)`
  text-align: center;
  & .modal-content {
    border-radius: 20px !important;
    @media ${bootstrapMax.lg} {
      font-size: 14px;
      text-align: left;
      line-height: 20px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
`;

export const CustomModalHeader = styled(Modal.Header)`
  border: none;
`;

export const CustomModalBody = styled(Modal.Body)`
  border: none;
  padding-top: 5px;
  max-height: 50vh;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
`;

export const CustomModalFooter = styled(Modal.Footer)`
  border: none;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CustomModalHeaderApp = styled(Modal.Header)`
  border: none;
  border-radius: 20px !important;
  background-color: ${colors.white};
  .close {
    color: ${colors.primaryOne};
  }
`;
export const Plus = styled.div`
  width: 35px;
  height: 36px;
  margin: 5px;
  &:hover {
    opacity: .3;
  }
  &:before {
    position: absolute;
    right: 25px;
    top: 10px;
    content: ' ';
    height: 27px;
    width: 1.5px;
    background-color: ${colors.primaryOne};
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    right: 25px;
    top: 10px;
    content: ' ';
    height: 27px;
    width: 1.5px;
    background-color: ${colors.primaryOne};
    transform: rotate(-45deg);
  }
}`;

export const CustomModalBodyApp = styled(Modal.Body)`
  border: none;
  padding: 0 55px 55px 55px;
  overflow-y: auto;
  background-color: ${colors.white};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;

  @media ${bootstrapMax.lg} {
    padding: 0 35px 35px 35px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.secondary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
`;

export const CustomModalFooterApp = styled(Modal.Footer)`
  background-color: ${colors.primarwhiteyDark};
  border: none;
  display: flex;
  justify-content: center;
`;

export const Img = styled.div`
  width: 102px;
  ${({ src, position, size }) =>
    src
      ? `
  background-image: url(${src});
  background-repeat: no-repeat;
  background-position: ${position};
  border: 1px solid ${colors.primaryTwo};
  border-radius: 50%;
  padding: 52px;
  background-size: ${size ? size : 70}px;
  background-position: center;
  margin-bottom: 20px !important;
  margin: auto;
}
`
      : ''}
`;
