import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Label = styled.label`
  font-size: 20px;
  height: 28px;
  text-align: left !important;
  margin: 15px 0 0 0;
`;

export const LabelHeader = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  color: ${colors.primaryOne};
  white-space: nowrap;
`;

export const LabelMenu = styled.span`
  text-transform: uppercase;
  color: ${colors.white};

  @media ${bootstrapMax.lg} {
    font-family: 'GT Walsheim Pro Bold', serif !important;
  }
`;

export const LabelMarginBottom = styled(Label)`
  margin-bottom: 20px;
`;

export const WhiteLabelComponent = styled(Label)`
  color: ${colors.white};
`;
