const { axiosDefault, handleErrors } = require('../utils/defaults');

const discardPointService = {
  admin: {
    get: async (id) => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/discardPoint/admin/${id ? id : ''}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    getList: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/discardPoint/admin/`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    save: async (discardPoint) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.post(`/api/discardPoint/admin/`, discardPoint);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    edit: async (discardPoint) => {
      try {
        const axios = axiosDefault('file');

        const { data } = await axios.put(
          `/api/discardPoint/admin/${discardPoint.id}`,
          discardPoint,
        );
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    delete: async (id) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.delete(`/api/discardPoint/admin/${id}`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },

    notification: async (ids) => {
      try {
        const axios = axiosDefault();

        const { data } = await axios.put(`/api/discardPoint/admin`, JSON.stringify(ids));
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
  user: {
    get: async () => {
      try {
        const axios = axiosDefault();
        const { data } = await axios.get(`/api/discardPoint/`);
        return data;
      } catch (error) {
        return handleErrors(error);
      }
    },
  },
};

export default discardPointService;
