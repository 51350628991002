import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const Card = styled.div`
  border: 1px solid ${colors.secondary};
  padding: 20px 30px;
  cursor: pointer;
`;

export const CardApp = styled.div`
  background-color: ${colors.almostBlack};
  padding: 10px;
  cursor: pointer;
`;

export const Tag = styled.div`
  min-width: 90px;
  width: fit-content;
  height: 25px;
  font-size: 16px;
  color: ${colors.white};
  background-color: ${colors.primary};
  text-align: center;
  padding: 2px 10px;
`;

export const TagApp = styled.div`
  min-width: 90px;
  width: fit-content;
  height: 25px;
  font-size: 16px;
  color: ${colors.white};
  background-color: ${colors.primary};
  text-align: center;
  padding: 2px 10px;
`;

export const AppIcon = styled.img`
  width: 24px;
`;
