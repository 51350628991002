import styled from 'styled-components';
import { bootstrapMax } from '../../../utils/devices';

export const Content = styled.div`
  img:not(.news-quote-left img) {
    max-width: 100%;
    height: 100%;

    @media ${bootstrapMax.xs} {
      max-width: 100vw;
      margin-left: -35px !important;
      margin-right: unset !important;
    }
  }
  .news-quote-text {
    font-size: 24px;
    line-height: 34px;
  }
  p {
    font-size: 20px;
    line-height: 30px;
  }
`;
