import './index.css';
import React, { Component } from 'react';
import CC, { Cookies } from 'react-cookie-consent';
import { buttonStyle, contentStyle, style } from './styles';

class CookieConsent extends Component {
  render() {
    return (
      <CC
        location="bottom"
        buttonText="OK"
        cookieName="cookieConsent"
        style={style}
        contentStyle={contentStyle}
        contentClasses={'mediaQuery'}
        buttonStyle={buttonStyle}
        buttonClasses={'bold'}
        overlay
        expires={180}>
        Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa Política de
        Privacidade e, ao continuar navegando, você concorda com estas condições.
      </CC>
    );
  }
}

export default CookieConsent;
