import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from '../index';
import { CustomFormControl, CustomDiv } from './styles';

class TextArea extends Component {
  render() {
    // props
    const { label, placeholder, value, onChange, rows, maxLength } = this.props;

    // types

    return (
      <CustomDiv>
        <Label text={label} />
        <CustomFormControl
          as="textarea"
          placeholder={placeholder}
          rows={rows || 6}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
      </CustomDiv>
    );
  }
}

TextArea.propTypes = {
  // props
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  // types
};

export default TextArea;
