import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text } from '../../../components';
import { Frame, Icon, IconFrame } from './styles';

const { defaultStates } = require('../../../utils/defaults');

import platform from '../../../assets/imgs/platform/economy@2x.png';
import transform from '../../../assets/imgs/platform/transform@2x.png';
import manufacture from '../../../assets/imgs/platform/manufacture@2x.png';
import discard from '../../../assets/imgs/platform/discard@2x.png';
import { colors } from '../../../utils/colors';

class Platform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
      topics: [
        {
          icon: transform,
          title: 'Transforme sua roupa',
          desc:
            'Conectamos atores no sistema produtivo de Moda, possibilitando que peças de roupa em final de ciclo ' +
            'de uso sejam encaminhadas para a reciclagem, em um processo que valoriza a matriz do algodão.',
        },
        {
          icon: discard,
          title: 'Descarte consciente',
          desc:
            'A partir da consulta georreferenciada ao mapa, você identificará pontos de coleta para descartar ' +
            'suas roupas feitas de algodão - sejam calças jeans ou peças de malha.',
        },
        {
          icon: manufacture,
          title: 'Manufatura reversa',
          desc:
            'As roupas serão recolhidas com o apoio de um sistema de manufatura reversa, e utilizadas para criar ' +
            'e produzir produtos baseados nos princípios de sustentabilidade.',
        },
      ],
    };

    this.body = this.body.bind(this);
  }

  topics = (topics) => {
    return (
      <Row className={'mt-lg-2'}>
        {topics.map(({ icon, title, desc }, index) => (
          <Col
            key={index}
            className={'d-flex flex-column px-4 pt-5 mx-xl-5 mx-2 my-3'}
            align={'left'}
            style={{ backgroundColor: colors.secondaryThree, borderRadius: 30, minWidth: 250 }}>
            <Icon img={icon} />
            <Text titlePlatformCard text={title} />
            <Text paragraph3 text={desc} />
          </Col>
        ))}
      </Row>
    );
  };

  body() {
    return (
      <>
        <Row align="center" className={'mb-lg-5 mt-5 mt-md-0'}>
          <Col className={'d-flex justify-content-center justify-content-lg-start pb-5'}>
            <Text heading1 title={{ one: 'sobre a', two: 'plataforma' }} />
          </Col>
        </Row>
        {this.topics(this.state.topics)}
        <Row align="center" className={'mt-5'}>
          <Col>
            <Text
              paragraph2
              text={
                'A plataforma também conta com uma área de Blog, onde posts informativos sobre assuntos ligados ' +
                'à Economia Circular, o plantio de algodão e inovações lançadas pelos parceiros envolvidos, ' +
                'serão atualizados. </br> Os posts também vão informar sobre gestão consciente e compartilhada dos ' +
                'resíduos, fomentando uma maior transparência no sistema têxtil de Moda.'
              }
            />
          </Col>
        </Row>
        <Row align="center">
          <Col align="center" className={'py-4'}>
            <Frame>
              <Text
                paragraph4
                text={
                  'Atuamos como um elo entre produtores, indústrias, comércio, varejistas e consumidores, ' +
                  'estruturando processos para a Economia Circular e novas relações de consumo.'
                }
              />
              <IconFrame img={platform} />
            </Frame>
          </Col>
        </Row>
        <Row align="center" className={'mt-4'}>
          <Col>
            <Text
              paragraph2
              text={
                'A plataforma visa oferecer um serviço inclusivo, que atenda a todas as classes. Geramos resultados ' +
                'positivos na saúde ambiental e qualidade vida, ao economizar recursos naturais e evitar mais acúmulo ' +
                'de lixo têxtil em aterros e lixões, ou a queima em locais inadequados.'
              }
            />
            <Text
              paragraph2
              text={
                'Acreditamos que a Moda pode ' +
                'tornar-se uma referência de boas práticas em sistemas de logística e manufatura reversa, como já ' +
                'acontece nos setores de agroquímicos e medicamentos.'
              }
            />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

export default Platform;
