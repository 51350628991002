import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import discardPointsService from '../../../services/discardPoint';
import { Screen, Text, Input, TextArea, Button, Checkbox, DropDown } from '../../../components';
import MapComponent from '../../../components/Map';
import SearchMap from '../../../components/SearchMap';
import { typePoints } from '../../../enums';

const {
  defaultStates,
  defaultAlertScreenOnClick,
  defaultModalOnHide,
} = require('../../../utils/defaults');

class DiscardPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discardPoint: {
        name: '',
        describe: '',
        address: '',
        latitude: null,
        longitude: null,
        type: null,
        isActive: false,
      },
      discardPoints: [],
      preventReload: false,
      ...defaultStates(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertScreenOnClick = this.alertScreenOnClick.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.body = this.body.bind(this);
    this.footer = this.footer.bind(this);
  }

  modalOnHide() {
    defaultModalOnHide(this);
  }

  alertScreenOnClick() {
    defaultAlertScreenOnClick(this);
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== 'novo') {
      const response = await discardPointsService.admin.get(id);
      if (response.ok && response.data && response.data.discardPoint) {
        this.setState({
          discardPoint: response.data.discardPoint,
          newRegister: false,
        });
      } else if (response.ok) {
        this.props.history.push('/admin/pontos-de-descarte/novo');
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };
        this.setState({ modalState });
      }
    }
  }

  async handleChange(id, event) {
    const { discardPoint } = this.state;
    discardPoint[id] = event.target ? event.target.value : event;
    this.setState({ discardPoint, preventReload: false });
  }

  handleSelect(event) {
    const { discardPoint } = this.state;
    discardPoint.latitude = event.geometry.location.lat();
    discardPoint.longitude = event.geometry.location.lng();
    discardPoint.address = event.formatted_address;

    this.setState({ discardPoint });
  }

  handleDrag(event) {
    const { discardPoint } = this.state;
    discardPoint.latitude = event.lat();
    discardPoint.longitude = event.lng();
    this.setState({ discardPoint });
  }

  async handleSubmit() {
    const { newRegister, discardPoint } = this.state;

    if (newRegister) {
      const response = await discardPointsService.admin.save(discardPoint);

      if (response.ok) {
        const alertScreenState = {
          title: 'Ponto criada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/pontos-de-descarte',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    } else {
      const response = await discardPointsService.admin.edit(discardPoint);

      if (response.ok) {
        const alertScreenState = {
          title: 'Ponto alterada com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
          pagePath: '/admin/pontos-de-descarte',
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }
    }

    this.setState({ loadingState: false });
  }

  async handleDelete() {
    this.setState({
      modalState: {
        title: `Remoção do ponto ${this.state.discardPoint.name}`,
        text: `Tem certeza que deseja excluir esse ponto?`,
        toggle: true,
        onHide: () => this.modalOnHide(),
        confirm: 'Sim',
        onClickConfirm: async () => await _handleDelete(),
        danger: true,
        cancel: 'Não',
        onClickCancel: () => this.modalOnHide(),
      },
    });

    const _handleDelete = async () => {
      const {
        discardPoint: { id },
      } = this.state;
      this.setState({ loadingState: true });

      const response = await discardPointsService.admin.delete(id);

      if (response.ok && response.data) {
        const alertScreenState = {
          title: 'Ponto excluído com sucesso!',
          open: true,
          onClick: this.alertScreenOnClick,
        };

        this.setState({ alertScreenState });
      } else {
        const modalState = {
          title: 'Erro',
          text: response.message,
          toggle: true,
          onHide: this.modalOnHide,
        };

        this.setState({ modalState });
      }

      this.setState({ loadingState: false });
    };
  }

  body() {
    const { discardPoint, preventReload } = this.state;
    const { id } = this.props.match.params;

    return (
      <>
        <Row align="center">
          <Col>
            <Text text={!discardPoint.id ? 'Novo Ponto' : `Ponto: ${discardPoint.name}`} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              label={'Ativo'}
              checked={discardPoint.isActive}
              onClick={() => {
                discardPoint.isActive = !discardPoint.isActive;
                this.setState({ discardPoint });
              }}
            />
          </Col>
          {discardPoint.isActive ? (
            <Col className={'d-flex justify-content-end'}>
              <Checkbox
                label={discardPoint.notified && !discardPoint.notify ? 'Notificado' : 'Notificar'}
                checked={discardPoint.notified}
                disable={discardPoint.notified && !discardPoint.notify}
                onClick={() => {
                  discardPoint.notify = !discardPoint.notify;
                  discardPoint.notified = !discardPoint.notified;
                  this.setState({ discardPoint });
                }}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <Input
              value={discardPoint.name}
              label={'Nome:'}
              placeholder={'Nome do ponto de coleta'}
              maxLength={100}
              onChange={(e) => this.handleChange('name', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <DropDown
              placeholder={'Tipo de ponto de coleta'}
              label={'Tipo:'}
              value={discardPoint.type}
              options={typePoints}
              onChange={(e) => this.handleChange('type', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <TextArea
              rows={2}
              label={'Descrição:'}
              placeholder={'Descrição do ponto de coleta'}
              value={discardPoint.describe}
              maxLength={200}
              onChange={(e) => this.handleChange('describe', e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            {((id !== 'novo' && discardPoint.createdAt) || id === 'novo') && (
              <>
                <SearchMap
                  label={'Endereço:'}
                  value={discardPoint.address}
                  onChange={(e) => this.handleChange('address', e)}
                  onSelect={(e) => this.handleSelect(e)}
                  onClick={() => this.setState({ preventReload: true })}
                />
                <MapComponent
                  draggable
                  onDragEnd={(e) => this.handleDrag(e)}
                  points={[discardPoint]}
                  preventReload={preventReload}
                  zoom={18}
                />
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }

  footer() {
    return (
      <>
        <Row align="center">
          {this.state.discardPoint.id ? (
            <Col>
              <Button danger text={'Excluir'} onClick={this.handleDelete} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <Button text={'Salvar'} onClick={this.handleSubmit} />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { modalState, alertScreenState, loadingState } = this.state;

    return (
      <>
        <Screen
          body={this.body}
          footer={this.footer}
          modalState={modalState}
          alertScreenState={alertScreenState}
          loadingState={loadingState}
        />
      </>
    );
  }
}

DiscardPoints.propTypes = {
  //props
  match: PropTypes.object,
  history: PropTypes.object,
};

export default DiscardPoints;
