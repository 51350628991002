import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { ArrowSlider, Carousel, Objective } from './styles';

class CarouselComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    this.carouselRef = createRef();
    this.resetTimeout = null;
  }

  render() {
    const { breakPoints, items, showArrows, withLink, enableAutoPlay, disableArrowsOnEnd } =
      this.props;

    const myArrow = ({ onClick, isEdge, type }) => (
      <ArrowSlider
        next={type === 'NEXT'}
        disabled={!disableArrowsOnEnd && isEdge}
        onClick={onClick}
        showArrows={showArrows}>
        <div />
      </ArrowSlider>
    );

    const onNextEnd = ({ index }) => {
      clearTimeout(this.resetTimeout);
      if (index + 1 === items.length) {
        this.resetTimeout = setTimeout(() => {
          try {
            this.carouselRef.current.goTo(0);
          } catch (e) {
            console.error(e);
          }
        }, 4000);
      }
    };

    const onNextStart = ({ index }) => {
      if (index + 1 === items.length) {
        try {
          this.carouselRef.current.goTo(0);
        } catch (e) {
          console.error(e);
        }
      }
    };

    const onPrevStart = ({ index }) => {
      if (!index) {
        try {
          this.carouselRef.current.goTo(items.length - 1);
        } catch (e) {
          console.error(e);
        }
      }
    };

    if (withLink) {
      return (
        <Carousel
          ref={this.carouselRef}
          showArrows={showArrows}
          breakPoints={breakPoints}
          enableAutoPlay={enableAutoPlay}
          autoPlaySpeed={4000}
          onNextEnd={onNextEnd}
          onNextStart={onNextStart}
          onPrevStart={onPrevStart}
          pagination={false}
          isRTL={false}
          renderArrow={myArrow}>
          {items.map((item, i) => (
            <a key={i} href={item.link} target="_blank" rel="noreferrer">
              <Objective key={i} img={item.img} />
            </a>
          ))}
        </Carousel>
      );
    }

    return (
      <Carousel
        showArrows={showArrows}
        breakPoints={breakPoints}
        pagination={false}
        isRTL={false}
        renderArrow={myArrow}>
        {items.map((item, i) => (item.type ? item : <Objective key={i} img={item} />))}
      </Carousel>
    );
  }
}

CarouselComponent.propTypes = {
  //props
  breakPoints: PropTypes.array,
  items: PropTypes.array,
  showArrows: PropTypes.bool,
  withLink: PropTypes.bool,
  enableAutoPlay: PropTypes.bool,
  disableArrowsOnEnd: PropTypes.bool,
};

export default CarouselComponent;
