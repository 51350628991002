import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  PreviewDoc,
  LogoImg,
  PinImg,
  Banner,
  PreviewImg,
  CustomImg,
  Circle,
} from './styles';
import close_blue_min from '../../assets/icons/close_blue-min2x.png';
import document from '../../assets/icons/document_blue-min.png';
import document2x from '../../assets/icons/document_blue-min2x.png';
import logoSrc from '../../assets/logos/logo_original-min.png';
import { Col, Row } from 'react-bootstrap';
import Text from '../Text';

import Pin from '../../assets/icons/map/Pin.png';
import Pin2x from '../../assets/icons/map/Pin@2x.png';
import PinGreen from '../../assets/icons/map/PinGreen.png';
import PinGreen2x from '../../assets/icons/map/PinGreen@2x.png';

class Image extends Component {
  render() {
    // props
    const { img, label, onClick, alt, selected } = this.props;

    // types
    const { doc, logo, pin, preview } = this.props;

    if (doc) {
      return (
        <PreviewDoc>
          <Row>
            <Col align="center">
              <CloseButton onClick={onClick} img={close_blue_min} />
              <img
                src={img.id ? img.urlS3 : document}
                srcSet={img.id ? img.urlS3 : document2x}
                alt=""
                onClick={() => {
                  onClick;
                }}
                className="mb-2"
              />
            </Col>
            <Col>
              <Text subTitle text={label} />
            </Col>
          </Row>
        </PreviewDoc>
      );
    }

    if (logo) {
      return <LogoImg src={logoSrc} />;
    }

    if (pin) {
      return (
        <div onClick={onClick} className={'d-flex align-items-center flex-column my-2'}>
          <Circle
            selected={selected}
            className={'d-flex justify-content-center align-items-center mb-3'}>
            <PinImg
              alt={alt}
              selected={selected}
              src={selected ? PinGreen : Pin}
              srcSset={selected ? PinGreen2x : Pin2x}
            />
          </Circle>
          <Text label text={label} />
        </div>
      );
    }

    if (preview) {
      return (
        <PreviewImg className="mb-1 mt-3">
          <CloseButton onClick={onClick} img={close_blue_min} />
          <CustomImg src={img} alt="" />
        </PreviewImg>
      );
    }

    return <Banner src={img} alt={alt} />;
  }
}

Image.propTypes = {
  // props
  img: PropTypes.string,
  imgSet: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,

  // types
  doc: PropTypes.bool,
  pin: PropTypes.bool,
  alt: PropTypes.any,
  selected: PropTypes.bool,
  logo: PropTypes.bool,
  preview: PropTypes.bool,
};

export default Image;
