import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertBadge } from './styles';

class Badge extends Component {
  render() {
    // props
    const { number } = this.props;

    //types

    if (!number) {
      return <></>;
    }

    return <AlertBadge>{number}</AlertBadge>;
  }
}

Badge.propTypes = {
  // props
  number: PropTypes.string,

  // types
  dark: PropTypes.bool,
};

export default Badge;
