import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { defaultStates } from '../../utils/defaults';
import { getCurrentPosition } from '../../utils/location';
import { LinkCard, TextCard } from './styles';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { colors } from '../../utils/colors';
import './styles.css';
const { compose, withStateHandlers, withHandlers, withState } = require('recompose');
const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox');

import Pin2x from '../../assets/icons/map/Pin@2x.png';
import PinGreen2x from '../../assets/icons/map/PinGreen@2x.png';

const defaultOrigin = { lat: -16.7553336, lng: -49.9550656, zoom: 4 };

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { ...defaultOrigin, ...defaultStates() };
  }

  getMyLocation = async () => {
    let location = null;
    let latitude = null;
    let longitude = null;

    if (this.state.app) {
      await getCurrentPosition(this);
    } else if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation;

      const setLocation = (lat, lng) => {
        this.setLocation(lat, lng);
      };

      if (location) {
        location.getCurrentPosition(function (position) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
          setLocation(latitude, longitude);
        });
      }
    }
  };

  setLocation(lat, lng, zoom = 12) {
    this.setState({ lat, lng, zoom });
  }

  async componentDidMount() {
    if (!this.props.draggable) {
      await this.getMyLocation();
    }
  }

  shouldComponentUpdate() {
    const { preventReload = true } = this.props;
    return preventReload;
  }

  render() {
    const { points, hasFilter, draggable, zoom, onDragEnd } = this.props;
    const { lat, lng } = this.state;

    const StyledMapWithAnInfoBox = compose(
      withStateHandlers(() => ({ idOpen: 0, center: { lat, lng } }), {
        onToggleOpen: () => (e) => {
          return {
            idOpen: e,
          };
        },
      }),
      withState('zoom', 'onZoomChange', zoom),
      withHandlers(() => {
        const refs = {
          map: undefined,
        };
        return {
          onMapMounted: () => (ref) => {
            refs.map = ref;
          },
          onZoomChanged:
            ({ onZoomChange }) =>
            () => {
              onZoomChange(refs.map.getZoom());
            },
          onMouseMove: () => () => {
            return {
              center: { lat: refs.map.getCenter().lat(), lng: refs.map.getCenter().lng() },
            };
          },
        };
      }),
      withScriptjs,
      withGoogleMap,
    )((props) => (
      <GoogleMap
        defaultZoom={zoom || this.state.zoom}
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
        defaultCenter={
          points.length === 1 && points[0].latitude && this.props.draggable
            ? { lat: points[0].latitude, lng: points[0].longitude }
            : props.center
        }
        options={{
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        zIndex={10}>
        {points.map((p, index) => {
          const isSelected = props.idOpen === p.id;

          return (
            <Marker
              key={p.id || index}
              draggable={draggable}
              onDragEnd={({ latLng }) => {
                onDragEnd(latLng);
              }}
              icon={getIcon(hasFilter, isSelected)}
              position={{ lat: p.latitude, lng: p.longitude }}
              onClick={() => props.onToggleOpen(props.idOpen === p.id ? 0 : p.id)}>
              {isSelected && (
                <InfoBox
                  options={{
                    closeBoxURL: ``,
                    enableEventPropagation: false,
                    boxStyle: { width: '300px', maxWidth: '90%' },
                  }}
                  maxWidth={300}
                  defaultPosition={
                    new window.google.maps.LatLng(props.center.lat, props.center.lng)
                  }>
                  <Col
                    style={{
                      backgroundColor: colors.primaryThree,
                      color: colors.white,
                      padding: 12,
                      borderRadius: 12,
                    }}
                    align={'center'}>
                    <div style={{ textDecoration: 'none !important' }}>
                      <TextCard bold>{p.name}</TextCard>
                      <TextCard>
                        {p.type === 'discard'
                          ? 'Ponto de Descarte'
                          : p.type === 'newProducts'
                          ? 'Novos Produtos'
                          : 'Ponto de Descarte e Novos Produtos'}
                      </TextCard>
                      <LinkCard
                        href={`https://www.google.com.br/maps?daddr=${p.latitude},${p.longitude}`}
                        target="_blank"
                        rel="noreferrer">
                        {p.address}
                      </LinkCard>
                    </div>
                  </Col>
                </InfoBox>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    ));

    const key =
      process.env.REACT_APP_WEB !== 'true'
        ? process.env.REACT_APP_GOOGLE_ANDROID
        : process.env.REACT_APP_GOOGLE;

    return (
      <StyledMapWithAnInfoBox
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places&region=BR`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `443px` }} className={'mobileMap'} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }
}

MapComponent.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  zoom: PropTypes.number,
  onClick: PropTypes.func,
  onDragEnd: PropTypes.func,
  checked: PropTypes.bool,
  hasFilter: PropTypes.bool,
  preventReload: PropTypes.bool,
  draggable: PropTypes.bool,
  points: PropTypes.array,
};

function getIcon(hasFilter, big) {
  const pin = hasFilter ? PinGreen2x : Pin2x;
  const size = big ? 60 : 40;
  return new window.google.maps.MarkerImage(
    pin,
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(size, size),
  );
}

export default MapComponent;
