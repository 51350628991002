import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as Acordion,
  Arrow,
  Plus,
  AccordionPlus,
} from './styles';

class Accordion extends Component {
  render() {
    const { id, summary, details, mini, plus, disabled } = this.props;

    if (plus) {
      return (
        <AccordionPlus disableGutters elevation={0}>
          <AccordionSummary expandIcon={<Plus />} plus aria-controls={id} id={id}>
            {summary}
          </AccordionSummary>
          <AccordionDetails plus>{details}</AccordionDetails>
        </AccordionPlus>
      );
    }

    return (
      <Acordion mini={mini} notOpen={disabled} disableGutters elevation={0}>
        <AccordionSummary
          mini={mini}
          expandIcon={
            <Arrow mini={mini}>
              <div />
            </Arrow>
          }
          aria-controls={id}
          id={id}>
          {summary}
        </AccordionSummary>
        <AccordionDetails mini={mini}>{details}</AccordionDetails>
      </Acordion>
    );
  }
}

Accordion.propTypes = {
  id: PropTypes.string,
  summary: PropTypes.any,
  details: PropTypes.any,

  mini: PropTypes.bool,
  plus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Accordion;
