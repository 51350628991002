import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, SquareDotted } from './styles';
import docIcon from './../../assets/icons/arrow_right_white-min.png';
import { Image, Input, Modal } from '..';
import { Col, Row } from 'react-bootstrap';
import document from '../../assets/icons/document_blue-min2x.png';
import plus from '../../assets/icons/plus_blue-min.png';
import TextArea from '../TextArea';
import photo_icon from '../../assets/icons/photo_icon@2x-min.png';

class ButtonUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      description: '',
      files: [],
    };
  }

  uploadFileModal(accepts) {
    const { fileName, description, files } = this.state;

    return (
      <Col>
        <Row align={'center'}>
          <Col>
            <Input
              inputBrown
              label={'Nome do arquivo:'}
              placeholder={'Nome do arquivo'}
              value={fileName}
              onChange={(e) => this.handleDataFile('fileName', e)}
            />
          </Col>
        </Row>
        <Row align={'center'}>
          <Col>
            <TextArea
              label={'Descrição:'}
              placeholder={'Descrição'}
              value={description}
              onChange={(e) => this.handleDataFile('description', e)}
            />
          </Col>
        </Row>
        {!files.length && (
          <Row align={'center'} className={'pt-3'}>
            <Col xs={12}>
              <SquareDotted onClick={() => this.uploadDoc.click()}>
                <input
                  ref={(ref) => (this.uploadDoc = ref)}
                  type="file"
                  accept={accepts}
                  onChange={(e) => this.handleAddFiles(e)}
                />
                Selecione o arquivo
              </SquareDotted>
            </Col>
          </Row>
        )}
        <Row align={'center'} className={'pt-5'}>
          {files &&
            files.map((img, index) => {
              return (
                <Col xs={12} key={index}>
                  <Image
                    doc
                    label={img.fileName ? img.fileName : img.name ? img.name : 'Arquivo sem nome'}
                    img={img.id ? img.urlS3 : document}
                    onClick={() => {
                      this.handleRemoveDocuments(index);
                    }}
                  />
                </Col>
              );
            })}
        </Row>
      </Col>
    );
  }

  handleDataFile(type, e) {
    const { files } = this.state;
    this.setState({ [type]: e.target.value });
    files.map((file) => {
      file[type] = e.target.value;
    });
  }

  handleAddFiles(e) {
    const { files, fileName, description } = this.state;

    const newFiles = e.target.files;
    const filesCount = Object.keys(newFiles).length;
    for (let index = 0; index < filesCount; index++) {
      const file = newFiles[Object.keys(newFiles)[index]];
      file.fileName = fileName;
      file.description = description;

      files.push(file);
    }
    this.setState({ files });
  }

  handleRemoveDocuments(index) {
    const { files } = this.state;
    files.splice(index, 1);
    this.setState({ files });
  }

  handleConfirm() {
    const { files } = this.state;
    if (files.length) {
      this.setState({ files: [], fileName: '', description: '' });
      this.props.onClickConfirm(files);
    }
  }

  render() {
    // props
    const { onChange, onClick } = this.props;

    //types
    const { image, outlook, modalTitle, modalToggle, modalOnHide, modal, multiple } = this.props;

    const accepts = image ? 'image/*' : outlook ? '*/*' : '.pdf';

    if (image) {
      return (
        <>
          <Button img onClick={(e) => this.uploadImg.click(e)} className="mt-3">
            <div>
              <div className="img-container">
                <img src={plus} alt="" />
                <img src={photo_icon} alt="" />
              </div>
              <p className="pt-2">Adicionar foto{multiple && 's'}</p>
            </div>
            <input
              ref={(ref) => (this.uploadImg = ref)}
              type="file"
              accept={accepts}
              onChange={onChange}
              multiple={multiple}
            />
          </Button>
        </>
      );
    }
    if (modal) {
      return (
        <>
          <Modal
            list
            listItems={() => this.uploadFileModal(accepts)}
            confirm={'Anexar Documento'}
            onClickConfirm={() => this.handleConfirm()}
            title={modalTitle}
            toggle={modalToggle}
            onHide={modalOnHide}
          />
          <Button onClick={onClick}>
            <div>
              <div className="img-container">
                <img src={plus} alt="" />
                <img src={document} alt="" />
              </div>
              <span className="mt-3">Adicionar documento</span>
            </div>
            <input type="file" accept={accepts} />
          </Button>
        </>
      );
    }
    return (
      <>
        <Button onClick={() => this.uploadDoc.click()}>
          <div>
            <div>
              <img src={docIcon} alt="" />
            </div>
            <div className="mt-3">Adicionar documento</div>
          </div>
          <input
            ref={(ref) => (this.uploadDoc = ref)}
            type="file"
            accept={accepts}
            onChange={onChange}
          />
        </Button>
      </>
    );
  }
}

ButtonUpload.propTypes = {
  // props
  onChange: PropTypes.func,
  modalToggle: PropTypes.bool,
  modalOnHide: PropTypes.func,
  onClick: PropTypes.func,
  onClickConfirm: PropTypes.func,
  modalTitle: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,

  // types
  image: PropTypes.bool,
  outlook: PropTypes.bool,
  modal: PropTypes.bool,
};

export default ButtonUpload;
