import React, { Component } from 'react';
import { Nav, Ul, Logo } from './styles';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logos/logo_original.png';
class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: false,
    };
  }

  render() {
    const hideMenuItems = window.location.pathname.includes('politica-de-privacidade');

    return (
      <>
        <Nav>
          <Logo src={logo} alt="logo" />
        </Nav>
        {!hideMenuItems ? (
          <Ul>
            <NavLink to="/acessos" activeStyle={{}}>
              <li>Acessos</li>
            </NavLink>
            <NavLink to="/atividades" activeStyle={{}}>
              <li>Atividades</li>
            </NavLink>
            <NavLink to="/informativos" activeStyle={{}}>
              <li>Informativos</li>
            </NavLink>
            <NavLink to="/quem-somos" activeStyle={{}}>
              <li>Quem somos</li>
            </NavLink>
            <NavLink to="/logout" activeStyle={{}}>
              <li>Sair</li>
            </NavLink>
          </Ul>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default NavbarComponent;
