import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Screen, Text, Image as Banner } from '../../../components';
import { Div904, Flux, Frame, Principle } from './styles';

import flux from '../../../assets/imgs/economy/flux@2x.png';
import patch from '../../../assets/imgs/economy/clothingPatchs@2x.png';
import monomateriality from '../../../assets/imgs/economy/monomateriality@2x.png';
import durability from '../../../assets/imgs/economy/durability@2x.png';
import timelessness from '../../../assets/imgs/economy/timelessness@2x.png';
import disassembly from '../../../assets/imgs/economy/disassembly@2x.png';

const { defaultStates } = require('../../../utils/defaults');

class Economy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultStates(),
    };

    this.body = this.body.bind(this);
  }

  body() {
    return (
      <>
        <Row align="center" className={'mt-5 mt-lg-5'}>
          <Col className={'d-lg-none d-inline'}>
            <Text heading1 title={{ one: 'economia', two: 'circular' }} />
          </Col>
          <Col className={'d-lg-inline d-none'}>
            <Text heading1 title={{ one: 'sobre a', two: 'economia circular' }} />
          </Col>
        </Row>
        <Row align="center" className={'mt-5'}>
          <Col className={'mt-lg-4'}>
            <Text
              paragraph2
              text={
                'Economia Circular é um modelo que propõe a substituição do sistema linear de produção, consumo e ' +
                'descarte, para um de ciclo fechado, onde a geração de lixo é reduzida ao máximo com estratégias de ' +
                'Design Circular.'
              }
            />
            <Text
              paragraph2
              text={
                'A partir do design de produtos inteligentes, feitos com materiais sustentáveis, a ideia é manter ' +
                'esses produtos e materiais em uso pelo maior tempo possível, por meio de boas práticas individuais, ' +
                'serviços e modelos de negócios.'
              }
            />
          </Col>
        </Row>

        <Row align="center" className={'mt-5'}>
          <Col className={'mt-lg-5'}>
            <Text heading2 title={{ one: 'fluxos de', two: 'materiais' }} />
          </Col>
        </Row>
        <Row align="center" className={'my-3'}>
          <Col>
            <Flux img={flux} />
          </Col>
        </Row>
        <Row align="center" className={'mt-5'}>
          <Col>
            <Text
              paragraph2
              text={
                'Ao final dos ciclos de uso, os materiais são reinseridos no sistema industrial, por meio da ' +
                'reciclagem, ou encaminhados para a compostagem, regenerando o solo.'
              }
            />
            <Text
              paragraph2
              text={
                'Nesse modelo, é importante que materiais do ciclo técnico (composição considerada não-biodegradável) ' +
                'e ciclo biológico (composição biodegradável), mantenham-se em fluxos separados, como indicam as ' +
                'iniciativas pioneiras no assunto, Cradle to Cradle Products Innovation Institute e Ellen Macarthur Foundation.'
              }
            />
            <Text
              paragraph2
              text={
                'E em complemento, considerar as oportunidades de inserção social e capacitação de pessoas para a ' +
                'inovação e geração de renda.'
              }
            />
          </Col>
        </Row>

        <Row align="center" className={'mt-5'}>
          <Col className={'mt-lg-3'}>
            <Text heading2 title={{ one: 'como', two: 'participamos' }} />
          </Col>
        </Row>
        <Row align="center" className={'my-4'}>
          <Col>
            <Banner img={patch} alt={'retalhos de tecido de algodão'} />
          </Col>
        </Row>
        <Row align="center" className={'mt-5'}>
          <Col>
            <Text
              paragraph2
              text={
                'Na Cotton Move, além de promovermos o reuso de fibras e tecidos que iriam para o lixo, em processos ' +
                'industriais eficientes, nós preservamos o ciclo do algodão.'
              }
            />
          </Col>
        </Row>

        <Row align="left" className={'mt-3 mt-lg-4'}>
          <Col>
            <Text heading3 title={{ one: 'nosso', two: 'compromisso:' }} />
          </Col>
        </Row>
        <Row align="center" className={'mt-3 mb-5 mt-lg-4'}>
          <Col>
            <Frame>
              <Text
                paragraph2
                text={
                  'Após a recuperação da fibra do algodão, é necessária a junção desta com uma nova fibra. É ' +
                  'prática comum na indústria têxtil a mistura do algodão reciclado com fibras sintéticas, ' +
                  'derivadas do petróleo. Mas essa mistura <b>impede a reciclagem futura</b> deste tecido.'
                }
              />
              <Text
                paragraph2
                text={
                  'Em nosso processo de reciclagem, obtemos novos produtos sem a adesão de fibras sintéticas. ' +
                  'Temos um <b>compromisso</b> com a circularidade, e escolhemos adicionar <b>apenas fibras de algodão ' +
                  'certificado</b> na elaboração de novos tecidos. Isso garante qualidade à fibra e torna tecidos ' +
                  'provenientes de pós-consumo mais limpos a cada reciclagem.'
                }
              />
              <Text
                paragraph2
                text={
                  'Atualmente oferecemos tecidos que contém a partir de <b>20%</b> de material reciclado. Através de ' +
                  'investimentos em pesquisa e evolução de processos, e com ajuda da <b>Plataforma Circular Cotton Move</b>, ' +
                  'seguiremos buscando uma maior reutilização dos materiais!'
                }
              />
            </Frame>
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Text
              paragraph2center
              text={
                'Além da elaboração de tecidos, criamos roupas de acordo com princípios de Design Circular, como:'
              }
            />
          </Col>
        </Row>
        <Row align="center">
          <Col>
            <Div904>
              <Principle img={monomateriality}>
                <div />
                <span>Monomaterialidade</span>
              </Principle>
              <Principle img={durability}>
                <div />
                <span>Durabilidade</span>
              </Principle>
              <Principle img={timelessness}>
                <div />
                <span>Atemporalidade</span>
              </Principle>
              <Principle img={disassembly}>
                <div />
                <span>Fácil desmontagem</span>
              </Principle>
            </Div904>
          </Col>
        </Row>

        <Row align="center" className={'mt-4 mt-lg-5 pb-3 pb-lg-0'}>
          <Col>
            <Div904>
              <Text
                paragraph2center
                text={
                  'Seguiremos evoluindo nossa jornada para entregar ao mercado, produtos com as melhores características de circularidade!'
                }
              />
            </Div904>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingState } = this.state;

    return <Screen body={this.body} loadingState={loadingState} />;
  }
}

export default Economy;
