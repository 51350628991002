import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardContainer, Button, ImgCard, Text } from './styles';

class Card extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, title, description, image, less, history } = this.props;

    return (
      <CardContainer less={less} onClick={() => history.push('/fique-por-dentro/' + id)}>
        <ImgCard img={image} />
        <Text>{title}</Text>
        <Text desc>{description}</Text>
        <Button header className={'bold'}>
          Leia Mais
        </Button>
      </CardContainer>
    );
  }
}

Card.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  less: PropTypes.bool,
  history: PropTypes.object,
};

export default Card;
